import React from "react";

import BackButton from "../BackButton";

import { MessageContainer, MessageText } from "./styles";

function NoPlansAvailableMessage() {
  return (
    <MessageContainer>
      <MessageText>There are no plans available.</MessageText>
      <BackButton />
    </MessageContainer>
  );
}

export default NoPlansAvailableMessage;
