import { CRMS, FSC_STEP_TYPE } from "../../utils/constants";
import { BULLHORN_CAMPAIGN_TYPES, FIRST_WIZARD_TAB_NUM } from "./constants";

function isValidUrl(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(str);
}

const getDateForJobTitle = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

const buildMarketingCampaignName = () => {
  return `Reach Campaign ${getDateForJobTitle()}`;
};

const isBullhornMarketingCampaignType = (type) => {
  return (
    type === BULLHORN_CAMPAIGN_TYPES.marketToCandidates ||
    type === BULLHORN_CAMPAIGN_TYPES.marketToClientLeads ||
    type === BULLHORN_CAMPAIGN_TYPES.marketToClientContacts
  );
};

const getWizardTabs = (crm, type) => {
  const isMarketingCampaign =
    crm !== CRMS.BULLHORN || isBullhornMarketingCampaignType(type);

  return {
    CAMPAIGN_OVERVIEW: FIRST_WIZARD_TAB_NUM,
    CAMPAIGN_TYPE: 2,
    [isMarketingCampaign ? "CAMPAIGN_DETAILS" : "JOB_DESCRIPTION"]: 3,
    SALES_STRATEGY: 4,
    SELECT_RECIPIENTS: 5,
    CONFIGURE_OPTIONS: 6,
    REVIEW_MESSAGES: 7,
  };
};

const hasSmsStepInSequence = (sequenceTemplate, sequenceTemplateOptions) => {
  return sequenceTemplate
    ? sequenceTemplateOptions
        ?.find((o) => o.value === sequenceTemplate)
        ?.steps?.find((s) => s.type === FSC_STEP_TYPE.SMS)
    : null;
};

const hasEmailStepInSequence = (sequenceTemplate, sequenceTemplateOptions) => {
  return sequenceTemplate
    ? sequenceTemplateOptions
        ?.find((o) => o.value === sequenceTemplate)
        ?.steps?.find(
          (s) =>
            s.type === FSC_STEP_TYPE.EMAIL ||
            s.type === FSC_STEP_TYPE.MARKETING_EMAIL
        )
    : null;
};

export {
  isValidUrl,
  buildMarketingCampaignName,
  isBullhornMarketingCampaignType,
  getWizardTabs,
  hasSmsStepInSequence,
  hasEmailStepInSequence,
};
