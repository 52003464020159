const theme = Object.freeze({
  colors: {
    gray: "#5A5A5A",
    gray_o40: "#5A5A5A66",
    gray2: "#656565",
    gray2_disabled: "#9B9B9B",
    blue: "#0872D3",
    blueLight: "#3A86FF",
    purple: "#4640DE",
    black: "#000000",
    black_o70: "#000000b3",
    white: "#ffffff",
    red: "#F95959",
    red_disabled: "#F95959BF",
    green: "#6C8A2C",

    saturated_purple: "#471168",

    btn_border_color: "#757678",

    background_color: "#ebebeb",

    candidate_table_head_color: "#F0F0EFC2",
    candidate_table_head_color2: "#E9E9E9C2",

    divider_color: "#CDCDCD",
    rateCard_border_color: "#CECECE",
  },
  fonts: {
    primary: "'AlbertSans', sans-serif",
    primaryExtraBold: "'AlbertSansExtraBold', sans-serif",
    primaryPoppins: "'Poppins', sans-serif",
  },
  navBarHeightPx: "60",
});

export default theme;
