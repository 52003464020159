import { styled } from "styled-components";

export const Button = styled.button`
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 10px;
  text-align: center;
  background-color: #f95959;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10.5px 21px 10.5px 22px;
  display: flex;
  align-items: center;
  margin: auto auto 28px auto;

  &::before {
    content: url("/images/wizard-hat.svg");
    margin-right: 10px;
    width: 18px;
    height: 18px;
    display: inline-block;
  }
`;
