import { styled } from "styled-components";

export const Item = styled.li`
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;

  ${({ $isViewed }) =>
    `
      ${
        $isViewed &&
        `
            &::before {
              content: '';
              position: absolute;
              width: 13px;
              height: 13px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              background-image: url("/images/generated-contact-icon.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
        `
      }

      ${
        !$isViewed &&
        `
          &::before {
            content: none;
          }
        `
      }
    `}
`;

export const Title = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  display: inline-block;
  width: 100%;
  padding: ${({ $isActive }) =>
    $isActive ? "10px 0 9px 0;" : "10px 0 10px 0"};
  color: ${({ $isActive, $isViewed }) =>
    $isActive ? "#000000" : $isViewed ? "#f95959" : "#9f9f9f"};
  font-weight: ${({ $isActive }) => ($isActive ? "700" : "400")};
  border-bottom: ${({ $isActive }) =>
    $isActive ? "1px solid #cbcbcb" : "none"};
`;
