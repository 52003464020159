import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import CloseBtn from "../components/CloseBtn";
import SetSmsProvider from "../components/SetIntegrations/SetSmsProvider";
import SetEmailProvider from "../components/SetIntegrations/SetEmailProvider";
import SetCRM from "../components/SetIntegrations/SetCRM";
import SetAiModel from "../components/SetIntegrations/SetAiModel";
import Loader from "../components/Loader";

import { loadUserData } from "../services/user";
import {
  getIntegrations,
  disconnectIntegration,
} from "../services/integrations";
import { sendMessageToExtension } from "../utils/postToExtension";

import styled from "styled-components";
import { useGetActiveTab, useGetIntegrationOrder } from "./hooks";
import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
} from "../api/localStorage";
import { isCompanyAdvIntegrationsSettings } from "../utils/utils";

const tabTitles = {
  emailService: "Email System",
  crm: "CRM",
  smsService: "SMS Gateway",
  aiModel: "AI Model Provider",
};

const tabComponents = {
  emailService: (commonProps, specificProps) =>
    SetEmailProviderComponent(commonProps, specificProps),
  crm: (commonProps, specificProps) =>
    SetCRMComponent(commonProps, specificProps),
  smsService: (commonProps, specificProps) =>
    SetSmsProviderComponent(commonProps, specificProps),
  aiModel: (commonProps, specificProps) =>
    SetAiModelComponent(commonProps, specificProps),
};

const CreateIntegrations = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromSettings = String(searchParams.get("fromSettings")) === "true";

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [integrations, setIntegrations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [connectedServices, setConnectedServices] = useState([]);

  const company = getObjectFromLocalStorage("companyInfo").company;
  const isAllowedAdvIntegrationsSettings = isCompanyAdvIntegrationsSettings({
    company,
  });

  const integrationOrder = useGetIntegrationOrder();
  const orderedTabs = getOrderedTabs(
    integrationOrder,
    {
      onConnected: handleServiceConnected,
      onDisconnect: handleDisconnectIntegration,
      isWizard: true,
      goToTab: switchTab,
    },
    {
      emailService:
        !isAllowedAdvIntegrationsSettings && !company?.allowSimpleConnect
          ? null
          : {
              connectionFields: integrations.email,
            },
      crm: {
        connectionFields: integrations.crm,
      },
      smsService: !isAllowedAdvIntegrationsSettings
        ? null
        : {
            connectionFields: integrations.sms,
            isConnected: connectedServices.includes("smsService"),
          },
      aiModel: !isAllowedAdvIntegrationsSettings
        ? null
        : {
            connectionFields: integrations.aiModel,
            onConnect: (data) =>
              setIntegrations({ ...integrations, aiModel: data }),
            isConnected: connectedServices.includes("aiModel"),
          },
    }
  );

  const currentTabs = {
    ...orderedTabs,
    marketingEmailService: {
      title: "Marketing Email Service",
      disabledContent: true,
    },
  };

  const curTabFromLS = fromSettings
    ? getDataFromLocalStorage("outreachConfigWizardTab")
    : null;
  const { activeTab, setActiveTab } = useGetActiveTab(
    curTabFromLS &&
      currentTabs[curTabFromLS] &&
      !currentTabs[curTabFromLS].disabledContent
      ? curTabFromLS
      : Object.keys(currentTabs)[0]
  );

  function handleServiceConnected(service) {
    setConnectedServices([...connectedServices, service]);
  }

  async function handleDisconnectIntegration(type) {
    const res = await disconnectIntegration({ type });
    if (res.success) {
      const isOffice365 = type === "office365";
      if (
        integrations.email.method === "oauth" &&
        (isOffice365 || type === "gsuite")
      ) {
        sendMessageToExtension({
          message: "show-success-message",
          data: {
            message: `Please revoke the "SalesStream.ai Reach" app's access to information in your ${
              isOffice365 ? "Office 365 " : "Google"
            } Account settings.`,
            title: "",
          },
        });
        setTimeout(() => {
          window.open(
            isOffice365
              ? "https://account.live.com/consent/Manage"
              : "https://myaccount.google.com/connections",
            "_blank"
          );
        }, 2900);
      }

      await fetchIntegrations();
    } else {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.message,
        },
      });
    }
    return res.success;
  }

  function switchTab(event, key) {
    event.preventDefault();

    if (key === "finish") {
      sendMessageToExtension({
        message: "open-campaigns",
      });
      return;
    }
    setActiveTab(key);
  }

  useEffect(() => {
    if (!isLoadingUserData) {
      fetchIntegrations();
    }
  }, [activeTab, isLoadingUserData]);

  const fetchIntegrations = async () => {
    const integrations = await getIntegrations();

    if (!integrations.integration) {
      setIsLoading(false);
      return;
    }

    if (
      integrations.integration.crm &&
      Object.keys(integrations.integration.crm).length > 0
    ) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "crm",
      ]);
    }

    if (integrations.integration.email?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "emailService",
      ]);
    }

    if (integrations.integration.sms?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "smsService",
      ]);
    }

    if (integrations.integration.aiModel?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "aiModel",
      ]);
    }

    setIntegrations(integrations.integration);
    setIsLoading(false);
  };

  useEffect(() => {
    loadUserData(
      null,
      () => {
        setIsLoadingUserData(false);
      },
      true
    );
  }, []);

  if (isLoadingUserData) {
    return <Loader parentSize={true} />;
  }

  return (
    <main className="app">
      <CloseBtn />
      <div className="layout">
        <SidePanel>
          <div>
            <Title>You're almost there!</Title>
            <Description>
              You're just a few clicks away from building your first campaign.
            </Description>
            <TabList>
              {Object.keys(currentTabs).map((key) => (
                <div key={key}>
                  <TabItem
                    disabled={currentTabs[key].disabledContent}
                    $active={activeTab === key}
                  >
                    <div
                      data-tooltip-id={
                        currentTabs[key].disabledContent
                          ? `${key}-tooltip`
                          : null
                      }
                      data-tooltip-content={
                        currentTabs[key].disabledContent ? "Coming soon" : null
                      }
                    >
                      {currentTabs[key].title}
                    </div>
                  </TabItem>
                  {currentTabs[key].disabledContent ? (
                    <Tooltip
                      id={`${key}-tooltip`}
                      place="right"
                      className="custom-tooltip"
                    />
                  ) : null}
                </div>
              ))}
            </TabList>
          </div>
          <Logo src="/images/logos/logo-purple.png" alt="Logo" />
        </SidePanel>

        <Content>
          {isLoading ? (
            <Loader parentSize={true} bgColor="white" />
          ) : (
            currentTabs[activeTab].component()
          )}
        </Content>
      </div>
    </main>
  );
};

export default CreateIntegrations;

function getOrderedTabs(order, commonProps, specificProps) {
  const tabs = {};

  const filtered = order.filter((name) => specificProps[name]);

  filtered.forEach((name, index) => {
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prevTab = prevIndex >= 0 ? filtered[prevIndex] : null;
    const nextTab = nextIndex < filtered.length ? filtered[nextIndex] : null;

    const stepConfig = {
      curTab: name,
      prevTab,
      nextTab,
    };

    tabs[name] = {
      title: tabTitles[name],
      component: () =>
        tabComponents[name](
          {
            ...commonProps,
            stepConfig,
          },
          specificProps[name]
        ),
    };
  });

  return tabs;
}

function SetEmailProviderComponent(commonProps, specificProps) {
  return <SetEmailProvider {...commonProps} {...specificProps} />;
}

function SetCRMComponent(commonProps, specificProps) {
  return <SetCRM {...commonProps} {...specificProps} />;
}

function SetSmsProviderComponent(commonProps, specificProps) {
  return <SetSmsProvider {...commonProps} {...specificProps} />;
}

function SetAiModelComponent(commonProps, specificProps) {
  return <SetAiModel {...commonProps} {...specificProps} />;
}

const TabList = styled.ul`
  display: flex;
  list-style-type: none;
  margin-top: 50px;
  padding: 0;
  flex-direction: column;
`;

const TabItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 10px 0;
  padding-left: ${({ $active }) => ($active ? "7px" : "10px")};
  font-size: 14px;
  font-weight: ${({ $active }) => ($active ? "800" : "400")};
  border-left: ${({ theme, $active }) =>
    $active ? `3px solid ${theme.colors.saturated_purple}` : "none"};
  color: ${({ theme, disabled, $active }) =>
    disabled
      ? "#bbb"
      : $active
      ? `${theme.colors.saturated_purple}`
      : `${theme.colors.gray}`};

  div {
    margin-right: 10px;
  }
`;

const Title = styled.div`
  font-size: 2em;
  font-weight: 800;
  line-height: 0.9em;
  color: #808080;
  margin-bottom: 20px;
  font-family: "AlbertSansExtraBold", sans-serif;
  letter-spacing: 0rem;
`;

const Description = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 103px;
`;

const SidePanel = styled.div`
  width: 30%;
  background-color: ${({ theme }) => theme.colors.background_color};
  padding: 60px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 70%;
  background-color: white;
  padding: 60px 30px 30px 60px;
  display: flex;
  align-items: center;
`;
