import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border-top-left-radius: 10px;
  min-width: 265px;
  max-width: 265px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 1px solid #dbdbdb;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #d0d0d0;
  padding: 20px 0 36px 44px;
`;

export const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000;
`;

export const FilteredCandidatesCount = styled.span`
  font-family: "AlbertSans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #5a5a5a;
  margin-left: 4px;
`;

export const SearchContainer = styled.div`
  position: relative;
  padding: 13px 13.5px 10px 20px;
`;

export const SearchInput = styled.input`
  &,
  &:focus-visible {
    height: 35px;
    width: 100%;
    padding-left: 32.5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.background_color};
    box-shadow: none;
    margin-bottom: 0;
  }

  &::placeholder {
    opacity: 0.4;
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  top: 25px;
  left: 33px;
  width: 13px;
  height: 13px;
`;

export const ScrollActiveButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  line-height: normal;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0 0 15px 0;
  position: relative;
  top: -30px;
  z-index: 1;
`;

export const ButtonText = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 8px;
  font-weight: 400;
  color: #a4a4a4;
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -15px;
    top: 1px;
    width: 8px;
    height: 8px;
    background-image: url("/images/arrow-down.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
