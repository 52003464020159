import React from "react";

export default function ResumeArrowIcon({ fill }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6061 8.65009C11.6061 8.51864 11.5625 8.38803 11.4532 8.25657C10.622 7.42598 9.76937 6.57406 8.93813 5.74348C8.43494 5.24068 7.66948 6.00554 8.17267 6.50833L9.79071 8.1251H0.780438C0.648876 8.1251 0.518172 8.16863 0.452386 8.23436C0.320824 8.32143 0.233685 8.4751 0.255896 8.67142C0.321677 11.6651 1.83037 14.4625 4.43353 15.9922C9.15704 18.8116 15.5206 16.2329 16.9645 10.879C18.4082 5.48155 14.2307 0.258036 8.74085 0.148771C8.04118 0.126577 8.04118 1.21922 8.74085 1.24142C11.7591 1.30715 14.5141 3.07672 15.6299 5.89632C16.7234 8.62794 16.0451 11.8189 13.9897 13.8949C11.912 15.971 8.71854 16.6266 5.98478 15.5339C3.33805 14.4848 1.58844 11.9717 1.34853 9.19655H9.725L8.10696 10.8133C7.60378 11.3161 8.39145 12.081 8.87242 11.5782L11.4533 8.9993C11.5627 8.91223 11.6061 8.78071 11.6061 8.65009Z"
        fill={fill || "white"}
      />
    </svg>
  );
}
