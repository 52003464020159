import { styled } from "styled-components";

export const SubItem = styled.li`
  cursor: pointer;
  position: relative;
  padding: 7px 0 7px 27px;
  display: flex;
  flex-direction: column;
  color: ${({ $isDisabled }) => ($isDisabled ? "#bbb" : "#000")};
`;

export const SubTitle = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  display: inline-block;
  width: 100%;

  ${({ $isActive }) =>
    $isActive &&
    `
      font-weight: 700;
    `}
`;
