import React, { useState } from "react";
import { styled } from "styled-components";
import ConnectBtn from "../../../ConnectBtn";
import CustomSelect from "../../../CustomSelect";
import Toggle from "../Scheduling/Toggle";

const MIN_USES = 1;

const InviteFormPopup = ({
  onClose,
  onCreateInvite,
  onCreateCompany,
  companies,
}) => {
  const [companyId, setCompanyId] = useState(null);
  const [code, setCode] = useState("");
  const [maxUses, setMaxUses] = useState(MIN_USES);
  const [initialCreditAmount, setInitialCreditAmount] = useState(0);
  const [allowPromptPlayground, setAllowPromptPlayground] = useState(true);
  const [showBranding, setShowBranding] = useState(true);
  const [errorMessages, setErrorMessages] = useState({});

  const handleSubmit = (event) => {
    event?.preventDefault();

    const errors = {};

    if (!code) {
      errors.code = "Missed code";
    }

    if (!maxUses) {
      errors.maxUses = "Missed max uses";
    } else if (maxUses < MIN_USES) {
      errors.maxUses = "Invalid max uses";
    }

    if (Object.keys(errors).length) {
      setErrorMessages(errors);
    } else {
      const params = { companyId, code, maxUses };
      if (!companyId) {
        params.initialCreditAmount = initialCreditAmount;
        params.allowPromptPlayground = allowPromptPlayground;
        params.showBranding = showBranding;
      }
      onCreateInvite(params);
    }
  };

  const handleClickNewCompany = (event) => {
    event?.preventDefault();
    onCreateCompany();
  };

  return (
    <InviteContainer>
      <Content>
        <CloseIcon className="close-icon" onClick={onClose}>
          <svg
            width="11"
            height="11"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          >
            <line x1="11" y1="0" x2="0" y2="11" />
            <line x1="0" y1="0" x2="11" y2="11" />
          </svg>
        </CloseIcon>

        <Form onSubmit={handleSubmit}>
          <Inputs>
            <InputBlock>
              <Label>Company</Label>
              <Relative>
                <Select $disabled={!companies?.length} $noSelected={!companyId}>
                  <CustomSelect
                    options={companies || []}
                    title="Select Company..."
                    width="100%"
                    selected={companyId}
                    onSelect={(value) => {
                      setCompanyId(value);
                      if (!value) {
                        setInitialCreditAmount(0);
                        setAllowPromptPlayground(true);
                        setShowBranding(true);
                      }
                    }}
                    isClearable
                  />
                  <NewCompanyBtn onClick={handleClickNewCompany}>
                    New Company
                  </NewCompanyBtn>
                </Select>
              </Relative>
            </InputBlock>

            <InputBlock>
              <Label htmlFor="code">Code</Label>
              <Relative>
                <Input
                  id="code"
                  placeholder="Enter Code..."
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value.trim());
                    setErrorMessages((m) => ({ ...m, code: "" }));
                  }}
                  autoComplete="off"
                />
                {!!errorMessages.code && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.code && (
                <ErrorMessage>{errorMessages.code}</ErrorMessage>
              )}
            </InputBlock>

            <InputBlock>
              <Label htmlFor="maxUses">Max Uses</Label>
              <Relative>
                <Input
                  id="maxUses"
                  placeholder="Enter Max Uses..."
                  value={maxUses}
                  onChange={(e) => {
                    setMaxUses(+e.target.value || MIN_USES);
                    setErrorMessages((m) => ({ ...m, maxUses: "" }));
                  }}
                  autoComplete="off"
                  type="number"
                  min={MIN_USES}
                />
                {!!errorMessages.maxUses && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.maxUses && (
                <ErrorMessage>{errorMessages.maxUses}</ErrorMessage>
              )}
            </InputBlock>

            {!companyId && (
              <>
                <InputBlock>
                  <Label htmlFor="initialCreditAmount">
                    Initial Credit Amount
                  </Label>
                  <Relative>
                    <Input
                      id="initialCreditAmount"
                      placeholder="Enter Initial Credit Amount..."
                      value={initialCreditAmount}
                      onChange={(e) => {
                        setInitialCreditAmount(+e.target.value || 0);
                      }}
                      autoComplete="off"
                      type="number"
                      min={0}
                    />
                  </Relative>
                </InputBlock>

                <InputBlock>
                  <Toggle
                    id="allowPromptPlaygroundIC"
                    label="Allow Prompt Playground"
                    value={allowPromptPlayground}
                    onChange={setAllowPromptPlayground}
                    width="90%"
                  />
                </InputBlock>

                <InputBlock>
                  <Toggle
                    id="showBrandingIC"
                    label="Show Reach Branding"
                    value={showBranding}
                    onChange={setShowBranding}
                    width="90%"
                  />
                </InputBlock>
              </>
            )}
          </Inputs>

          <Actions>
            <ConnectBtn type="submit" label="Create Invite" />
          </Actions>
        </Form>
      </Content>
    </InviteContainer>
  );
};

export default InviteFormPopup;

const InviteContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

const Content = styled.div`
  position: relative;
  width: 70%;
  max-width: 500px;
  margin: 0 auto;
  height: fit-content;
  max-height: 70vh;
  margin-top: 14vh;
  background: #fff;
  border-radius: 4px;
  padding: 20px 20px 20px 10px;
  border: 1px solid #999;
`;

const CloseIcon = styled.span`
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -13px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(70vh - 80px);
  overflow: auto;
`;

const Inputs = styled.div`
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  box-shadow: 0 0 10px #dfdfdf;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
  background: #fff;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-left: 10px;
  padding-top: 20px;
  position: sticky;
  bottom: 0;
  background: #fff;
`;

const Select = styled.div`
  width: 90%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  > div:first-child {
    ${({ $disabled }) =>
      $disabled ? "opacity: 0.75; pointer-events: none;" : ""}
    margin-bottom: 0;
    height: 40px;
    > div:first-child {
      height: 40px;
      > div:first-child {
        font-size: 13px;
        ${({ $noSelected }) => ($noSelected ? "color: #757678;" : "")}
      }
    }
  }
  + img {
    right: 178px;
  }
`;

const NewCompanyBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ disabled }) => (disabled ? "#aca9a7" : "#5a5a5a")};
  background-color: transparent;
  height: 40px;
  width: 158px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: ${({ disabled }) =>
    disabled ? "1px solid #aca9a7" : "1px solid #5a5a5a"};
`;
