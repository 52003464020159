import React from "react";
import styled from "styled-components";
import { SecondaryButton as Button } from "../../styles";

function SaveDraftButton({ onClick, disabled }) {
  return (
    <CustomButton onClick={onClick} disabled={disabled}>
      Save as Draft
    </CustomButton>
  );
}

export default SaveDraftButton;

const CustomButton = styled(Button)`
  margin-left: auto;
`;
