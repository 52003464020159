import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import BillingsTable from "./BillingsTable";
import Plans from "../../Subscription/Plans";
import ConfirmPopup from "../../ConfirmPopup";
import { useGetPlansData } from "../../Subscription/hooks";
import { getObjectFromLocalStorage } from "../../../api/localStorage";
import UpdateBillingDetails from "../../Subscription/UpdateBillingDetails";
import Loader from "../../Loader";
import { changeSubscriptionPlan } from "../../../services/subscription";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { getCompany } from "../../../services/company";
import { useLocation, useNavigate } from "react-router-dom";
import { DEFAULT_SUBSCRIPTION_PLAN_PERIOD } from "../../../utils/constants";

const Subscription = () => {
  const navigate = useNavigate();

  const [companyInfo, setCompanyInfo] = useState(
    getObjectFromLocalStorage("companyInfo")
  );

  const lastSelectedPlanId =
    companyInfo.company.nextPeriodSubscription?.planId ||
    companyInfo.company.subscriptionPlanId;
  const lastSelectedPeriod =
    companyInfo.company.nextPeriodSubscription?.planPeriod ||
    companyInfo.company.subscriptionPlanPeriod;

  const location = useLocation();

  const { plans, isLoading: isPlansLoading, errorMessage } = useGetPlansData();
  const [selectedPlan, setSelectedPlan] = useState(lastSelectedPlanId);
  const [isLoading, setIsLoading] = useState(false);

  const [isOpenedChangePopup, setIsOpenedChangePopup] = useState(false);
  const [isBillingDetailsUpdate, setIsBillingDetailsUpdate] = useState(false);
  const [isOpenedSuccessPopup, setIsOpenedSuccessPopup] = useState(false);
  const isPlansAvailable = plans && plans.length > 0;

  const isFromCampaignCreation = location.state?.from === "campaign-creation";

  const handleChangePlan = async (isAfterUpdate) => {
    const isSelectedPlanFree = checkIsFreePlan(selectedPlan);

    if (
      !isAfterUpdate &&
      !companyInfo.company.hasPaymentMethod &&
      !isSelectedPlanFree
    ) {
      setIsBillingDetailsUpdate(true);
      setIsOpenedChangePopup(false);
      return;
    }

    setIsLoading(true);
    setIsOpenedChangePopup(false);

    const result = await changeSubscriptionPlan({ newPlanId: selectedPlan });

    if (result.success) {
      await fetchCompany();
      setIsOpenedSuccessPopup(true);
      setIsLoading(false);
    } else {
      setSelectedPlan(lastSelectedPlanId);
      setIsLoading(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.isConflict
            ? result.message
            : "Something wrong while trying to update plan",
        },
      });
    }
  };

  const fetchCompany = async () => {
    const companyResult = await getCompany();

    if (companyResult.success) {
      setCompanyInfo({ company: companyResult.company });
    }
  };

  const checkIsFreePlan = (id) => {
    const plan = plans.find((plan) => plan.id === id);

    return !plan || plan.monthlyPrice === 0;
  };

  const handleSelectPlan = (planId) => {
    const isSelectedPlanFree = checkIsFreePlan(planId);
    const isCurrentPlanFree = checkIsFreePlan(lastSelectedPlanId);

    if (isSelectedPlanFree === isCurrentPlanFree) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            "In this version it's not yet possible to switch to another paid plan",
        },
      });
      return;
    }

    setSelectedPlan(planId);
    setIsOpenedChangePopup(true);
  };

  const handleBillingDetailsUpdate = async () => {
    setIsBillingDetailsUpdate(false);
    setIsLoading(true);
    await fetchCompany();

    if (selectedPlan) {
      await handleChangePlan(true);
    }

    setIsLoading(false);
  };

  const handleReturnToPreviousPage = () => {
    if (isFromCampaignCreation) {
      sendMessageToExtension({
        message: "attempt-create-campaign",
      });
    } else {
      navigate("/campaigns");
    }
  };

  useEffect(() => {
    if (!companyInfo) {
      fetchCompany();
    }
  }, [companyInfo]);

  const PlansSwitcher = () =>
    isPlansAvailable ? (
      <PlansContainer>
        <Plans
          plans={plans}
          currentPlanId={lastSelectedPlanId}
          subscriptionPeriod={
            lastSelectedPeriod || DEFAULT_SUBSCRIPTION_PLAN_PERIOD
          }
          onSelect={handleSelectPlan}
        />
      </PlansContainer>
    ) : (
      <MessageContainer>
        <MessageText>
          {errorMessage || "There are no plans available."}
        </MessageText>
      </MessageContainer>
    );

  const backToPreviousText = isFromCampaignCreation
    ? "continue creating your campaign"
    : "return to the dashboard";

  return (
    <Section>
      {isOpenedChangePopup && (
        <ConfirmPopup
          title="Are you sure you want to change your subscription plan?"
          description={`Please note that your plan will be updated after your current subscription period ending on ${new Date(
            companyInfo.company.subscriptionPeriodEnd || Date.now()
          ).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}`}
          onConfirm={() => handleChangePlan()}
          onCancel={() => {
            setIsOpenedChangePopup(false);
            setSelectedPlan(undefined);
          }}
          confirmLabel="Got It"
          cancelLabel="Nevermind"
        />
      )}

      {isOpenedSuccessPopup && plans && (
        <ConfirmPopup
          title="You did it!"
          description={`Thank you for upgrading to the ${
            plans.find(({ id }) => id === selectedPlan)?.planName || ""
          } plan!  We know you’ll love it! Would you like to ${backToPreviousText}?`}
          onConfirm={() => handleReturnToPreviousPage()}
          onCancel={() => {
            setIsOpenedSuccessPopup(false);
          }}
          confirmLabel="Yes, take me there"
          cancelLabel="Maybe later"
          iconSrc="/images/thank-you.png"
        />
      )}

      <TitleBlock>
        <div>
          <Title>Select your subscription level</Title>

          <PanelDescription className="side_panel_description">
            Please select a subscription level. Afraid of commitment? We've got
            you! Sign up for our Free account and see what Reach can do for your
            business without paying a cent!
            {/*<HelpLink>Need help choosing your subscription?</HelpLink>*/}
          </PanelDescription>
        </div>

        <UpdateButton
          onClick={() => {
            setIsBillingDetailsUpdate(true);
          }}
          disabled={isPlansLoading}
        >
          Update Billing Details
        </UpdateButton>
      </TitleBlock>

      {isLoading || isPlansLoading ? (
        <Loader parentSize={true} height="calc(100% - 60px)" />
      ) : (
        <>
          {isBillingDetailsUpdate ? (
            <UpdateBillingDetails
              selectedPlanName={
                plans.find(({ id }) => selectedPlan === id)?.name || ""
              }
              onReturn={() => {
                setIsBillingDetailsUpdate(false);
                setSelectedPlan(undefined);
              }}
              onSuccess={handleBillingDetailsUpdate}
            />
          ) : (
            <PlansSwitcher />
          )}

          <SubTitle>Billing History</SubTitle>
          <BillingsTable />
        </>
      )}
    </Section>
  );
};

export default Subscription;

const Section = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 63px;
`;

const TitleBlock = styled.div`
  display: flex;
`;

const UpdateButton = styled.button`
  font-size: 12px;
  font-weight: 700;
  width: 170px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.btn_border_color};
  color: ${({ theme }) => theme.colors.gray};
`;

const PanelDescription = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 24px;
`;

const Title = styled.h3`
  font-size: 30px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray};
  line-height: 27.3px;
  letter-spacing: -0.02em;
  margin-bottom: 5px;
`;

const SubTitle = styled.h4`
  font-family: "AlbertSansExtraBold";
  font-size: 18px;
  width: 70%;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 24px;
  margin-bottom: 5px;
`;

const PlansContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`;

const MessageContainer = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 24px;
`;

const MessageText = styled.p`
  line-height: 27.84px;
  color: #5a5a5a;
`;
