import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CAMPAIGN_TYPES } from "../constants";
import StepButton from "../../StepButton";
import { Tooltip } from "react-tooltip";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
} from "../../../utils/constants";
import { isBullhornMarketingCampaignType } from "../utils";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";
import {
  ButtonGroup,
  ErrorMessage,
  OFFSET_LEFT,
  RadioBlock,
  RadioButtonGroup,
  RadioInput,
  Section,
} from "../styles";
import DefineNewType from "./DefineNewType";
import SaveDraftButton from "./SaveDraftButton";

const LABELS = {};

function CampaignType({
  setCampaignType,
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
}) {
  const campaignTypeList = CAMPAIGN_TYPES[campaign.crmType];
  const parentRef = useRef(null);
  const [isCustomTypeInputOpened, setIsCustomTypeInputOpened] = useState(false);
  const [selectedType, setType] = useState(campaign.type);
  const [customType, setCustomType] = useState("");
  const [customTypeErrorMessage, setCustomTypeErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.campaignType,
    parentRef,
  };

  const handleChange = (selType) => {
    setErrorMessage("");
    setCustomTypeErrorMessage("");

    setType(selType);
    setIsCustomTypeInputOpened(false);
    setCustomType("");

    if (campaign.crmType === CRMS.BULLHORN) {
      setCampaignType(selType);
    }
  };

  const handleClickDefineNewType = () => {
    setIsCustomTypeInputOpened(true);

    setErrorMessage("");
    setCustomTypeErrorMessage("");

    setType("");
  };

  const handleCancelDefineNewType = () => {
    setIsCustomTypeInputOpened(false);
    setCustomTypeErrorMessage("");
  };

  const handleCreateNewType = (newType) => {
    setIsCustomTypeInputOpened(false);
    setCustomTypeErrorMessage("");

    const newTypeLc = newType.toLowerCase();
    const existingType = campaignTypeList.find(
      (g) => g.toLowerCase() === newTypeLc
    );
    if (existingType) {
      handleChange(existingType);
      return;
    }

    setCustomType(newType);
  };

  const handleClickNext = (event) => {
    event.preventDefault();
    if (selectedType === "" && !isCustomTypeInputOpened && !customType) {
      setErrorMessage("Please choose a campaign type");
      return;
    }
    if (selectedType === "" && (isCustomTypeInputOpened || customType)) {
      if (!customType && isCustomTypeInputOpened) {
        setCustomTypeErrorMessage("This field is required");
        return;
      }
      if (customType) {
        setCustomTypeErrorMessage("");
        setCampaign((campaign) => ({ ...campaign, type: customType }));
        handleIncreaseStep();
        return;
      }
    }

    setCampaign((campaign) => ({
      ...campaign,
      type: selectedType,
    }));
    handleIncreaseStep();
  };

  const applyCampaignChanges = (asDraft) => {
    if (selectedType === "" && customType !== "") {
      setCampaign((campaign) => ({
        ...campaign,
        type: customType,
        asDraft,
      }));
      return;
    }

    setCampaign((campaign) => ({
      ...campaign,
      type: selectedType,
      asDraft,
    }));
  };

  const handleClickBack = (event) => {
    event.preventDefault();
    applyCampaignChanges();
    handleDecreaseStep();
  };

  const handleClickSaveDraft = (event) => {
    event.preventDefault();
    applyCampaignChanges(true);
  };

  useEffect(() => {
    if (!campaignTypeList.includes(campaign.type) && campaign.type !== "") {
      setIsCustomTypeInputOpened(false);
      setCustomType(campaign.type);
      return;
    }

    setIsCustomTypeInputOpened(false);
  }, [campaign, campaignTypeList]);

  return (
    <>
      <Section ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <SelectBlock>
          <Label>What is the purpose of your campaign?</Label>
        </SelectBlock>
        <RadioButtonGroup>
          {campaignTypeList.map((type, index) => {
            const isDisabled =
              !!campaign.localBullhornData?.isMarketingCampaign &&
              !isBullhornMarketingCampaignType(type);
            const isChecked = type === selectedType;
            return (
              <RadioBlock key={index}>
                <RadioInput
                  $disabled={isDisabled}
                  $checked={isChecked}
                  onClick={isDisabled ? undefined : () => handleChange(type)}
                  data-tooltip-id={isDisabled ? `no-job-tooltip` : null}
                  data-tooltip-content={
                    isDisabled
                      ? "You must navigate to a Job before starting a Recruitment campaign"
                      : null
                  }
                >
                  <div />
                  <span>{LABELS[type] || type}</span>
                </RadioInput>
                {isDisabled ? (
                  <Tooltip
                    id={`no-job-tooltip`}
                    className="custom-tooltip"
                    place="right"
                  />
                ) : null}
              </RadioBlock>
            );
          })}
          {!!customType && (
            <RadioBlock key={customType}>
              <RadioInput $checked={true}>
                <div />
                <span>{customType}</span>
              </RadioInput>
            </RadioBlock>
          )}
        </RadioButtonGroup>

        {campaign.crmType !== CRMS.BULLHORN ? (
          <DefineNewType
            label="Campaign Type"
            isOpened={isCustomTypeInputOpened}
            onOpen={handleClickDefineNewType}
            onClose={handleCancelDefineNewType}
            onCreate={handleCreateNewType}
            onInput={() => setCustomTypeErrorMessage("")}
            errorMessage={customTypeErrorMessage}
          />
        ) : null}

        {errorMessage !== "" && (
          <ErrorMessage $marginLeft={OFFSET_LEFT}>{errorMessage}</ErrorMessage>
        )}
      </Section>
      <ButtonGroup>
        <StepButton onClick={handleClickBack} isNext={false} />
        <StepButton onClick={handleClickNext} isNext={true} />
        <SaveDraftButton onClick={handleClickSaveDraft} />
      </ButtonGroup>
    </>
  );
}

export default CampaignType;

const SelectBlock = styled.div`
  padding: 39px ${OFFSET_LEFT} 17px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
`;

const Label = styled.label`
  margin: 0;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
`;
