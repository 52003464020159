import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import SearchIcon from "./icons/SearchIcon";
import theme from "../../../theme";

const Search = ({ value, onChange }) => {
  const [isOpenedSearch, setIsOpenedSearch] = useState(false);
  const [searchString, setSearchString] = useState(value || "");

  const useCloseByClickOutside = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpenedSearch(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);

  useCloseByClickOutside(wrapperRef);

  const handleChange = (newValue) => {
    setSearchString(newValue);
    onChange(newValue);
  };

  const handleClear = (event) => {
    handleChange("");
    try {
      wrapperRef?.current?.querySelector("input")?.focus();
    } catch {
      //
    }
  };

  const handleOpen = () => {
    setIsOpenedSearch(!isOpenedSearch);
    try {
      wrapperRef?.current?.querySelector("input")?.focus();
    } catch {
      //
    }
  };

  return (
    <Container ref={wrapperRef} $isOpened={isOpenedSearch}>
      <SearchBtn $isOpened={isOpenedSearch} onClick={handleOpen}>
        <SearchIcon fill={searchString ? theme.colors.red : undefined} />
      </SearchBtn>
      <SearchInputCont $isOpened={isOpenedSearch}>
        <SearchInput
          type="text"
          onChange={(e) => handleChange(e.target.value)}
          value={searchString}
          placeholder="Search"
          autoFocus={isOpenedSearch}
        />
        <SearchInputIcon />
        {!!searchString && (
          <ClearIcon
            $isOpened={isOpenedSearch}
            title="Clear"
            onClick={handleClear}
          />
        )}
      </SearchInputCont>
    </Container>
  );
};

export default Search;

const Container = styled.div`
  position: relative;
  width: ${({ $isOpened }) => ($isOpened ? "256px" : "fit-content")};
`;

const SearchInputCont = styled.div`
  position: absolute;
  top: -9px;
  left: 0;
  transition: all 300ms cubic-bezier(0.2, 0, 0, 1);
  ${({ $isOpened }) =>
    $isOpened
      ? "width: 256px;"
      : `
          width: 0;
          input, img {
            padding: 0;
            width: 0;
            border: 0;
            box-shadow: none;
            opacity: 0;
          }
  `}
`;

const SearchInput = styled.input`
  &,
  &:focus-visible {
    margin: 0;
    height: 38px;
    width: 256px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4px;
    border: 1px solid #cdcdcd;
    box-shadow: 0px 0px 20px 0px #0000000d inset;
    background-color: white;
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    opacity: 1;
    transition: all 150ms cubic-bezier(0.2, 0, 0, 1);

    &::placeholder {
      color: #000000;
      opacity: 0.3;
    }
  }
`;

const SearchInputIcon = styled.img.attrs({ src: "/images/search-icon.svg" })`
  position: absolute;
  top: 12px;
  left: 10px;
  width: 13px;
  height: 13px;
  opacity: 0.5;
`;

const SearchBtn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 18px;
  height: 18px;
  cursor: pointer;
  ${({ $isOpened }) => ($isOpened ? "opacity: 0;" : "opacity: 1;")}
  transition: all 150ms cubic-bezier(0.2, 0, 0, 1);

  svg {
    width: 18px;
    height: 18px;
  }
`;

const ClearIcon = styled.img.attrs({
  src: "/close-icon.png",
  alt: "close",
  width: 12,
})`
  position: absolute;
  top: 12px;
  right: 10px;
  width: 12px;
  height: 12px;
  opacity: ${({ $isOpened }) => ($isOpened ? 1 : 0)};
  cursor: pointer;
  transition: opacity 300ms ease-in;
`;
