import React from "react";

import Register from "../components/Register";
import AuthLayout from "../components/AuthLayout";

const RegisterPage = () => {
  return (
    <AuthLayout
      switchAuthPageText="Already have an account?"
      switchAuthPageUrl="/login"
      switchAuthPageBtn="Sign In"
    >
      <Register />
    </AuthLayout>
  );
};

export default RegisterPage;
