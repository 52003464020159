import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

function ContactUsSelect({
  options,
  title,
  onSelect,
  selected,
  width,
  hasError,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option.value);
  };

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSelectedOption(
      options.find((option) => option.value === selected) || null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Container $width={width}>
      <SelectHeader onClick={handleSelectClick}>
        <SelectedOption $isSelected={!!selectedOption}>
          {selectedOption ? selectedOption.label : title}
        </SelectedOption>
        {!hasError && <ArrowIcon />}
      </SelectHeader>
      {isOpen && (
        <OptionsContainer>
          {options.map((option) => (
            <Option
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </Option>
          ))}
        </OptionsContainer>
      )}
    </Container>
  );
}

ContactUsSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool,
        PropTypes.string,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  onSelect: PropTypes.func.isRequired,
};

export default ContactUsSelect;

const Container = styled.div`
  position: relative;
  font-size: 12px;
  color: #000000;
  height: 38px;
  width: ${({ $width }) => ($width ? $width : "90%")};
  background-color: white;
`;

const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 40px 9px 16px;
  border-radius: 3px;
  border: 1px solid #d6ddeb;
  cursor: pointer;
  height: 38px;
`;

const SelectedOption = styled.div`
  flex: 1;
  color: #000;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 12px;
  font-weight: 400;
  ${(props) => (props.$isSelected ? "" : "opacity: 0.6;")};
`;

const ArrowIcon = styled.img.attrs({
  src: "/images/arrow-down-new.svg",
  alt: "arrow-down",
})`
  position: absolute;
  top: calc((100% - 10px) / 2);
  right: 23px;
  width: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 39px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  border-radius: 3px;
  border: 1px solid #d6ddeb;
`;

const Option = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 12px;
  font-weight: 400;
  &:hover {
    opacity: 0.85;
    background: #eee;
  }
`;
