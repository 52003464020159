import styled from "styled-components";

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 34px;
  padding: 25px 10px 0 0;
  justify-content: center;
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const ToggleButton = styled.label`
  display: inline-block;
  position: relative;
  height: 18px;
  padding: 0px 17px;
  background-color: #fff;
  border-radius: 13px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: ${({ $checked }) => ($checked ? "-6px" : "calc(100% - 15px)")};
    width: 16px;
    height: 16px;
    background-color: #4f54d6;
    border-radius: 50%;
    transition: left 0.3s;
    border: 3px solid #fff;
  }
`;

export const Label = styled.label`
  cursor: pointer;
  padding: 0 10px 0 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 12.66px;
  user-select: none;
  color: #fff;
`;

export const RightLabelContainer = styled.div`
  position: relative;

  width: max-content;
  display: flex;
`;

export const Discount = styled.span`
  font-family: "ArchitectsDaughter";
  position: absolute;
  top: -33px;
  right: -51px;
  font-size: 15px;
  color: #fff;
`;

export const LeftLabel = styled(Label)``;

export const RightLabel = styled(Label)`
  font-size: 14px;
  font-weight: 800;
  position: relative;

  &::before {
    content: "";
    background-image: url("/images/curvedarrow.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    top: -28px;
    right: 15px;
  }
`;
