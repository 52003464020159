import { styled } from "styled-components";

export const Item = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 6px 0 6px 27px;

  ${({ $isActive, $isMulti }) =>
    $isActive &&
    `
    ${
      $isMulti
        ? `
          margin-bottom: 10px;
          box-shadow: 0 -3px #f7f7f7;
    `
        : ""
    }
    
    background-color: #f7f7f7;
    border-radius: 10px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      border: 2px solid #f95959;
      border-radius: 10px;
      top: 7px;
      left: 16px;
      height: 12px;
    }
  `}
`;

export const Title = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
  width: 100%;

  ${({ $isActive }) =>
    $isActive &&
    `
      font-weight: 700;
    `}
`;
