import React from "react";
import { createPortal } from "react-dom";

import {
  ConfirmButton,
  Container,
  Header,
  Description,
  OverlayContainer,
} from "./styles";

function OnboardingContent({ styles, handleClick, text, header }) {
  return (
    <>
      <Overlay />
      <Container style={styles}>
        {!!header && <Header>{header}</Header>}
        <Description>{text}</Description>
        <ConfirmButton onClick={handleClick}>Got It!</ConfirmButton>
      </Container>
    </>
  );
}

export default OnboardingContent;

const Overlay = () => {
  return createPortal(<OverlayContainer />, document.body);
};
