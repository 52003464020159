import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getPreviewTempMessage } from "../../../services/campaigns";
import Loader from "../../Loader";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import StepButton from "../../StepButton";
import AdvancedSelect from "../../AdvancedSelect";
import { openPreviewMessageLinkInNewTab } from "../../../utils/utils";
import { ButtonGroup, OFFSET_LEFT_BTN_GR, Section } from "../styles";
import CreateCampaignButton from "./CreateCampaignButton";
import { FSC_STEP_TYPE } from "../../../utils/constants";
import { TONE_OPTIONS } from "../constants";
import SaveDraftButton from "./SaveDraftButton";

const FIELDS = {
  tone: {
    bgImage: "/images/tone_icon.svg",
    bgSize: "28px 26px",
    bgPosition: "10px center",
    label: "Tone",
    options: TONE_OPTIONS,
  },
  additionalInstructions: {
    bgImage: "/images/addl_instructions_icon.svg",
    bgSize: "19px 19px",
    bgPosition: "14px center",
    label: "Additional Instructions",
    placeholder: "Make the messages around 50 words long…",
  },
  sampleEmail: {
    bgImage: "/images/sample_email_icon.svg",
    bgSize: "24px 18px",
    bgPosition: "14px center",
    label: "Sample Email",
    placeholder: "Enter a sample email here",
  },
};

function PreviewMessage({
  tempCampaignId,
  preparedFields,
  campaign,
  setCampaign,
  handleDecreaseStep,
  onCloseWizard,
  isEstimatedTokenLoading,
  mayExceedCreditsData,
  createCampaign,
  isShowCredits,
}) {
  const contact = preparedFields.contacts[0];

  const currentSequence = preparedFields.sequenceTemplate
    ? campaign.sequenceTemplates
        ?.find((o) => o.value === preparedFields.sequenceTemplate)
        ?.steps?.map((step) => {
          if (
            [
              FSC_STEP_TYPE.SMS,
              FSC_STEP_TYPE.EMAIL,
              FSC_STEP_TYPE.MARKETING_EMAIL,
            ].includes(step.type)
          ) {
            const isSms = step.type === FSC_STEP_TYPE.SMS;
            return {
              isSms,
              value: step.orderNum,
              label: `${step.type} (step #${step.orderNum})`,
            };
          }
          return null;
        })
        .filter(Boolean) || []
    : Array.from({ length: preparedFields.attempts }, (_, index) => {
        const isSms = index + 1 === preparedFields.smsTiming;
        return {
          isSms,
          value: index + 1,
          label: isSms
            ? "SMS"
            : `Email #${
                index + 1 < (preparedFields.smsTiming || 0)
                  ? index + 1
                  : index + 1 - (preparedFields.smsTiming ? 1 : 0)
              }`,
        };
      });
  const hasMessages = !!currentSequence.length;

  const [currentMessageAttempt, setCurrentMessageAttempt] = useState(
    currentSequence[0]?.value || null
  );
  const [currentMessage, setCurrentMessage] = useState(null);

  const [isPreparing, setIsPreparing] = useState(false);
  const [isLoading, setIsLoading] = useState(hasMessages);
  const [isLoadFailed, setIsLoadFailed] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [isRightPanelCollapsed, setIsRightPanelCollapsed] = useState(false);

  const [editFields, setEditFields] = useState({
    curKey: null,
    values: Object.keys(FIELDS).reduce(
      (a, c) => ({ ...a, [c]: campaign[c] }),
      {}
    ),
  });

  const hasChanges =
    editFields.values.tone !== campaign.tone ||
    editFields.values.sampleEmail !== campaign.sampleEmail ||
    editFields.values.additionalInstructions !==
      campaign.additionalInstructions;

  const fetchPreviewMessage = async (isRegenerate, messageAttempt) => {
    setIsLoading(true);
    if (isRegenerate) {
      setIsRegenerating(true);
    }

    const attempt = messageAttempt || currentMessageAttempt;
    setCurrentMessageAttempt(attempt);

    const data = await getPreviewTempMessage({
      tempCampaignId,
      campaign: {
        ...preparedFields,
        ...editFields.values,
        contacts: [contact],
      },
      isRegenerate,
      attempt,
    });

    if (data.success !== undefined && !data.success) {
      let message = "Unfortunately, we had trouble generating the preview";

      if (data.isMoneyInsufficient) {
        message = "You do not have enough credits to perform this operation";
      }

      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message,
        },
      });

      setIsLoadFailed(true);
      setIsLoading(false);
      setIsRegenerating(false);
      return;
    }

    setCampaign((campaign) => ({ ...campaign, ...editFields.values }));

    setCurrentMessageAttempt(data.result.message.attempt);
    setCurrentMessage(data.result.message);

    setIsLoadFailed(false);
    setIsLoading(false);
    setIsRegenerating(false);
  };

  const handleSelectMessage = (messageAttempt) => {
    fetchPreviewMessage(false, messageAttempt);
  };

  const handleClickRegenerate = (e) => {
    e.preventDefault();

    fetchPreviewMessage(true);
  };

  const handleClickSaveDraft = (event) => {
    event.preventDefault();

    if (hasChanges) {
      setCampaign((campaign) => ({
        ...campaign,
        ...editFields.values,
        asDraft: true,
      }));
      return;
    }

    onCloseWizard();
  };

  const handleFinish = (event) => {
    createCampaign(event, hasChanges ? editFields.values : null);
  };

  useEffect(() => {
    if (hasMessages) {
      fetchPreviewMessage(false);
    }
  }, []);

  const isAnyLoading =
    isLoading || isRegenerating || isEstimatedTokenLoading || isPreparing;

  return (
    <>
      <LeftPanel $hasMessages={hasMessages} $isShowCredits={isShowCredits}>
        <Section>
          <Title
            $isRightPanelCollapsed={isRightPanelCollapsed}
            $isShowCredits={isShowCredits}
          >
            Preview Message
            {currentSequence.length > 1 && (
              <AdvancedSelect
                label="Message"
                options={currentSequence}
                value={currentMessageAttempt}
                onSelect={handleSelectMessage}
                disabled={isLoading}
                width={preparedFields.sequenceTemplate ? "160px" : "120px"}
                paddingRight="10px"
                arrowWidth="13"
              />
            )}
          </Title>
          <Content>
            {!hasMessages ? (
              <ErrorMessage>No messages</ErrorMessage>
            ) : isLoading ? (
              <Loader
                parentSize={true}
                text="Please give us a minute.  We are generating your message…"
              />
            ) : isLoadFailed ? (
              <ErrorMessage>Could not load message</ErrorMessage>
            ) : (
              <>
                {currentMessage.type !== "sms" && (
                  <>
                    <ContentTitle>Subject</ContentTitle>
                    <ContentSubject>{currentMessage.subject}</ContentSubject>
                  </>
                )}
                <ContentBody>
                  <ContentBodyText>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentMessage.text,
                      }}
                      onClick={openPreviewMessageLinkInNewTab}
                    />
                  </ContentBodyText>
                </ContentBody>
              </>
            )}
          </Content>
        </Section>

        <ButtonGroup $padding={`25px 37px 0 ${OFFSET_LEFT_BTN_GR}`}>
          <StepButton
            onClick={handleDecreaseStep}
            disabled={isAnyLoading}
            isNext={false}
          />
          <CreateCampaignButton
            {...{
              campaign,
              isDisabled: isAnyLoading,
              mayExceedCreditsData,
              createCampaign: handleFinish,
              isPreparing,
              setIsPreparing,
            }}
          />
          <SaveDraftButton
            onClick={handleClickSaveDraft}
            disabled={isAnyLoading}
          />
        </ButtonGroup>
      </LeftPanel>

      {hasMessages && (
        <RightPanel
          $isCollapsed={isRightPanelCollapsed}
          $isShowCredits={isShowCredits}
        >
          <RightPanelTop $isShowCredits={isShowCredits}>
            <RightPanelTitle
              $isCollapsed={isRightPanelCollapsed}
              $isShowCredits={isShowCredits}
            >
              Edit message options
            </RightPanelTitle>
            <EditFieldCollapseIcon
              alt="collapse-icon"
              src="/images/arrow-line-down.svg"
              width={11}
              $rotate180={!isRightPanelCollapsed}
              onClick={() => setIsRightPanelCollapsed(!isRightPanelCollapsed)}
            />
          </RightPanelTop>
          <RightPanelContent
            $isCollapsed={isRightPanelCollapsed}
            $isShowCredits={isShowCredits}
          >
            <EditFields>
              {Object.entries(FIELDS).map(([key, field]) => {
                const disabled = isLoading;
                const isSelectable = !!field.options;
                const isCollapsed = editFields.curKey !== key;
                return (
                  <EditFieldContainer key={key} $disabled={disabled}>
                    <EditFieldTitle
                      $isCollapsed={isCollapsed}
                      $isSelectable={isSelectable}
                      $bgImage={field.bgImage}
                      $bgSize={field.bgSize}
                      $bgPosition={field.bgPosition}
                      onClick={() => {
                        setEditFields({
                          ...editFields,
                          curKey: isCollapsed ? key : null,
                        });
                      }}
                    >
                      {`Edit  ${field.label}`}
                      <EditFieldCollapseIcon
                        alt="collapse-icon"
                        src={
                          isSelectable
                            ? "/images/arrow-line-down.svg"
                            : isCollapsed
                            ? "/images/plus.svg"
                            : "/images/minus.svg"
                        }
                        width={11}
                        $rotate180={isSelectable && !isCollapsed}
                      />
                    </EditFieldTitle>
                    {!isCollapsed &&
                      (isSelectable ? (
                        <AdvancedSelect
                          isOpen
                          options={field.options}
                          label={field.label}
                          value={editFields.values[key]}
                          onSelect={(value) => {
                            setEditFields({
                              curKey: null,
                              values: { ...editFields.values, [key]: value },
                            });
                          }}
                        />
                      ) : (
                        <TextArea
                          autoFocus
                          placeholder={field.placeholder || ""}
                          value={editFields.values[key]}
                          onChange={(e) => {
                            setEditFields({
                              ...editFields,
                              values: {
                                ...editFields.values,
                                [key]: e.target.value,
                              },
                            });
                          }}
                          onBlur={(e) => {
                            setEditFields({
                              ...editFields,
                              values: {
                                ...editFields.values,
                                [key]: e.target.value.trim(),
                              },
                            });
                          }}
                          onMouseOut={(e) => {
                            try {
                              if (e.target === document.activeElement) {
                                setEditFields({
                                  ...editFields,
                                  values: {
                                    ...editFields.values,
                                    [key]: e.target.value.trim(),
                                  },
                                });
                              }
                            } catch {}
                          }}
                          autoComplete="off"
                        />
                      ))}
                  </EditFieldContainer>
                );
              })}
            </EditFields>
            <ContentBtn
              disabled={isLoading}
              onClick={handleClickRegenerate}
              $hasChanges={hasChanges}
            >
              Regenerate Message
            </ContentBtn>
          </RightPanelContent>
        </RightPanel>
      )}
    </>
  );
}

export default PreviewMessage;

const LeftPanel = styled.div`
  width: ${({ $hasMessages }) =>
    $hasMessages ? "calc(100% - 363px)" : "100%"};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${({ $isShowCredits }) => `
    @media (max-width: ${$isShowCredits ? 1107 : 867}px) {
      width: 100%;
    }
  `}
`;

const RightPanel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 363px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 17px;
  background: linear-gradient(180deg, #f0f0f04d 10.22%, #d1d1d14d 100%);
  background-color: #fff;
  border-top-right-radius: 10px;
  border-left: 1px solid #d7d7d7;
  padding: 24px 16px 24px 25px;

  ${({ $isShowCredits, $isCollapsed }) => `
    @media (max-width: ${$isShowCredits ? 1107 : 867}px) {
      max-width: 100%;
      ${
        $isCollapsed
          ? `
              height: 60px;
              width: 172px;
              border-bottom-left-radius: 10px;
              border-bottom: 1px solid #d7d7d7;
        `
          : `
              min-width: 212px;
        `
      }
    }
  `}
`;

const Title = styled.div`
  padding: 22px 37px 0 calc(${OFFSET_LEFT_BTN_GR} + 1px);
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  ${({ $isShowCredits, $isRightPanelCollapsed }) => `
    @media (max-width: ${$isShowCredits ? 1107 : 867}px) {
      ${$isRightPanelCollapsed ? "padding-right: calc(172px + 20px);" : ""}
    }
  `}
`;

const Content = styled.div`
  padding: 12px 37px 0 ${OFFSET_LEFT_BTN_GR};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const ContentSubject = styled.div`
  margin: 0 0 12px;
  padding: 13px 11px 12px;
  width: 100%;
  border: 1px solid #95959580;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

const ContentBody = styled(ContentSubject)`
  margin: 0;
  min-height: 100px;
  flex-grow: 1;
  overflow: hidden;
`;

const ContentBodyText = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  &,
  * {
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
    font-size: 12px;
    font-weight: 400;
    line-height: 20.78px;
  }
  word-wrap: break-word;
  p {
    padding-bottom: 10px;
  }
`;

const ContentTitle = styled.div`
  margin: 0 0 10px 5px;
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 12px;
  font-weight: 600;
  line-height: 19.2px;
`;

const ContentBtn = styled.button`
  padding: 0 19px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray};
  background-color: transparent;
  height: 40px;
  min-height: 40px;
  min-width: 103px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 6px;
  border: 1px solid #757678;
  position: relative;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
  }
  &:not([disabled]) {
    &:hover {
      opacity: 0.85;
    }
  }

  ${({ $hasChanges, theme }) =>
    $hasChanges
      ? `
          &:after {
            content: "*";
            position: absolute;
            top: 8px;
            right: 12px;
            color: ${theme.colors.red};
            font-size: 13px;
          }    
    `
      : ""}
`;

const ErrorMessage = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #de6363;
  font-size: 11px;
  overflow: auto;
  flex-grow: 1;
`;

const RightPanelTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: #4e4e4e;
  font-size: 14px;

  img {
    cursor: pointer;
    &:hover {
      opacity: 0.65;
    }

    ${({ $isShowCredits }) => `
      @media (min-width: ${$isShowCredits ? 1108 : 868}px) {
        display: none !important;
      }
    `}
  }
`;

const RightPanelTitle = styled.div`
  padding-left: 1px;
  max-width: 122px;
  color: ${({ theme }) => theme.colors.black_o70};
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 12px;
  font-weight: 600;
  line-height: 13px;

  ${({ $isShowCredits, $isCollapsed }) => `
    @media (max-width: ${$isShowCredits ? 1107 : 867}px) {
      ${$isCollapsed ? "margin-top: -7px;" : ""}
    }
  `}
`;

const RightPanelContent = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 32px;

  ${({ $isShowCredits, $isCollapsed }) => `
    @media (max-width: ${$isShowCredits ? 1107 : 867}px) {
      ${$isCollapsed ? "visibility: hidden;" : ""}
    }
  `}
`;

const EditFields = styled.div`
  padding-right: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 11px;
  border-radius: 5px;
`;

const EditFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid #8585854d;
  border-radius: 5px;
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  ${({ $disabled }) => ($disabled ? "pointer-events: none;" : "")}
`;

const EditFieldTitle = styled.div`
  padding: 16px 19px 14px 62px;
  width: 100%;
  border-radius: ${({ $isSelectable, $isCollapsed }) =>
    `5px 5px ${$isSelectable || $isCollapsed ? "5px" : 0} ${
      $isSelectable || $isCollapsed ? "5px" : 0
    }`};
  background-color: #d9d9d94d;
  color: ${({ theme }) => theme.colors.black_o70};
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-image: url(${({ $bgImage }) => $bgImage});
  background-size: ${({ $bgSize }) => $bgSize};
  background-repeat: no-repeat;
  background-position: ${({ $bgPosition }) => $bgPosition};

  cursor: pointer;

  ${({ $isCollapsed }) =>
    $isCollapsed
      ? `
          &:hover {
            &, * {
              opacity: 0.85 !important;
            }
          }
    `
      : ""}

  ${({ $isSelectable, $isCollapsed }) =>
    $isSelectable && !$isCollapsed
      ? ` 
          background-color: #f1f1f1;
          z-index: 1;
          + div {
            width: 100% !important;
            > .multi-select {
              margin-top: -38px;
              width: 100% !important;
              z-index: 0;
            }
            .dropdown-content {
              left: -2px !important;
              width: calc(100% + 4px) !important;
            }
            * {
              box-shadow: none !important;
            }
          }
    `
      : ""}
`;

const TextArea = styled.textarea`
  &,
  &:focus-visible {
    border: none;
    border-top: 1px solid #95959580;
    outline: none;
    resize: none;
    height: calc(100vh - 523px);
    max-height: calc(100vh - 523px);
    min-height: 85px;
    padding: 11px 24px 24px;
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
    font-size: 12px;
    font-weight: 400;
    line-height: 20.78px;
    color: #000000;
    box-shadow: none;
    border-radius: 0 0 5px 5px;
    margin: 0;
    width: 100%;

    &::placeholder {
      font-style: normal;
      color: #00000054;
    }
  }
`;

const EditFieldCollapseIcon = styled.img`
  ${({ $rotate180 }) => ($rotate180 ? "transform: rotate(180deg);" : "")}
`;
