const apiUrl = process.env.REACT_APP_API_URL;

const pollingFrequencyForUpdateEngagements =
  process.env.REACT_APP_POLLING_FREQUENCY_UPDATE_ENGAGEMENTS;

const isNeedToClearCache = true;

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;

const gsuiteOauthClientId = process.env.REACT_APP_GSUITE_CLIENT_ID;
const o365OauthClientId = process.env.REACT_APP_O365_CLIENT_ID;
const o365OauthTenantId = process.env.REACT_APP_O365_TENANT_ID;

const gtmId = process.env.REACT_APP_GTM_ID;

export {
  apiUrl,
  pollingFrequencyForUpdateEngagements,
  isNeedToClearCache,
  stripeApiKey,
  gsuiteOauthClientId,
  o365OauthClientId,
  o365OauthTenantId,
  gtmId,
};
