import React, { useEffect, useRef, useState } from "react";
import InfiniteScrollComponent from "react-infinite-scroll-component";

const InfiniteScroll = (props) => {
  const infiniteScrollRef = useRef(null);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (!infiniteScrollRef.current) {
      return;
    }

    const infScrollElement = infiniteScrollRef.current._infScroll;
    const infScrollLayout = infScrollElement.parentNode;

    const tableLayout = infScrollLayout.parentNode;
    const infiniteScroll = infScrollElement.firstChild;

    const tableBottom = tableLayout.getBoundingClientRect().bottom;
    const scrollBottom = infiniteScroll.getBoundingClientRect().bottom;

    if (props?.hasMore && tableBottom > scrollBottom) {
      props.next?.();
    }
  }, [height]);

  useEffect(() => {
    const resize = () => {
      setHeight(window.innerHeight);
      props?.onResize?.();
    };

    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <InfiniteScrollComponent ref={infiniteScrollRef} {...props}>
      {props.children}
    </InfiniteScrollComponent>
  );
};

export default InfiniteScroll;
