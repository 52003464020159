import React, { useState } from "react";
import styled from "styled-components";

const CardContent = ({ text, paddingLeft }) => {
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [styles, setStyles] = useState({});

  const showTooltip = (e) => {
    e.preventDefault();

    const cardRect = e.target.parentNode.getBoundingClientRect();

    const offsetHeight = e.target.offsetHeight;
    const scrollHeight = e.target.scrollHeight;

    if (offsetHeight >= scrollHeight) {
      return;
    }

    setStyles({
      top: `${cardRect.bottom - 20}px`,
      left: `${cardRect.left}px`,
      width: `${cardRect.width}px`,
    });
    setIsShowTooltip(true);
  };

  const hideTooltip = (e) => {
    e.preventDefault();

    setIsShowTooltip(false);
  };

  return (
    <>
      {isShowTooltip && (
        <TooltipDescription style={styles}>{text}</TooltipDescription>
      )}
      <CampaignCardContent
        $paddingLeft={paddingLeft}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {text}
      </CampaignCardContent>
    </>
  );
};

export default CardContent;

const CampaignCardContent = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 14px;
  font-weight: 300;
  line-height: 15.68px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-bottom: 1px;
  ${({ $paddingLeft }) =>
    $paddingLeft ? `padding-left:${$paddingLeft}` : " "};

  &:hover {
    z-index: 999;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({
      theme: {
        colors: { white, rateCard_border_color },
      },
    }) =>
      `18px 0 ${white}, 19px 0 ${rateCard_border_color}, -18px 0 ${white}, -19px 0 ${rateCard_border_color}`};
  }
`;

const TooltipDescription = styled.div`
  position: fixed;
  padding: 13px;
  font-size: 11px;
  font-weight: 400;
  line-height: 16.28px;
  color: #00000080;
  background-color: #fcf6e4;
  border-radius: 11px;
`;
