import React from "react";

export default function StepNumCurIcon({ num }) {
  const numbers = {
    1: "M12.1606 11.9752V10.2152H15.3066V17.9922H13.3266V11.9752H12.1606Z",
    2: "M11.2869 16.4852C11.5362 16.2872 11.6499 16.1955 11.6279 16.2102C12.3466 15.6162 12.9112 15.1285 13.3219 14.7472C13.7399 14.3659 14.0919 13.9662 14.3779 13.5482C14.6639 13.1302 14.8069 12.7232 14.8069 12.3272C14.8069 12.0265 14.7372 11.7919 14.5979 11.6232C14.4586 11.4545 14.2496 11.3702 13.9709 11.3702C13.6922 11.3702 13.4722 11.4765 13.3109 11.6892C13.1569 11.8945 13.0799 12.1879 13.0799 12.5692H11.2649C11.2796 11.9459 11.4116 11.4252 11.6609 11.0072C11.9176 10.5892 12.2512 10.2812 12.6619 10.0832C13.0799 9.88519 13.5419 9.78619 14.0479 9.78619C14.9206 9.78619 15.5769 10.0099 16.0169 10.4572C16.4642 10.9045 16.6879 11.4875 16.6879 12.2062C16.6879 12.9909 16.4202 13.7205 15.8849 14.3952C15.3496 15.0625 14.6676 15.7152 13.8389 16.3532H16.8089V17.8822H11.2869V16.4852Z",
    3: "M13.9919 9.87419C14.5346 9.87419 15.0003 9.96952 15.3889 10.1602C15.7776 10.3435 16.0709 10.6002 16.2689 10.9302C16.4743 11.2529 16.5769 11.6195 16.5769 12.0302C16.5769 12.5362 16.4449 12.9432 16.1809 13.2512C15.9243 13.5592 15.6016 13.7535 15.2129 13.8342V13.8892C16.2176 14.2119 16.7199 14.8389 16.7199 15.7702C16.7199 16.2175 16.6173 16.6172 16.4119 16.9692C16.2066 17.3139 15.9059 17.5852 15.5099 17.7832C15.1213 17.9739 14.6519 18.0692 14.1019 18.0692C13.2073 18.0692 12.4959 17.8492 11.9679 17.4092C11.4473 16.9692 11.1649 16.2982 11.1209 15.3962H12.9579C12.9726 15.7409 13.0679 16.0122 13.2439 16.2102C13.4199 16.4082 13.6766 16.5072 14.0139 16.5072C14.2853 16.5072 14.4979 16.4265 14.6519 16.2652C14.8133 16.1039 14.8939 15.8949 14.8939 15.6382C14.8939 15.0002 14.4319 14.6812 13.5079 14.6812H13.1559V13.1522H13.4969C14.3256 13.1669 14.7399 12.8699 14.7399 12.2612C14.7399 11.9972 14.6666 11.7955 14.5199 11.6562C14.3806 11.5095 14.1899 11.4362 13.9479 11.4362C13.6839 11.4362 13.4749 11.5279 13.3209 11.7112C13.1669 11.8872 13.0789 12.1255 13.0569 12.4262H11.2199C11.2566 11.6049 11.5133 10.9742 11.9899 10.5342C12.4666 10.0942 13.1339 9.87419 13.9919 9.87419Z",
    4: "M10.7887 16.5622V14.9892L14.3417 10.1162H16.3657V14.9232H17.3007V16.5622H16.3657V17.9922H14.4627V16.5622H10.7887ZM14.6167 12.2942L12.7027 14.9232H14.6167V12.2942Z",
    5: "M16.6698 11.6782H13.1718V13.2512C13.3258 13.0752 13.5385 12.9395 13.8098 12.8442C14.0885 12.7415 14.3892 12.6902 14.7118 12.6902C15.2325 12.6902 15.6688 12.8075 16.0208 13.0422C16.3728 13.2695 16.6332 13.5775 16.8018 13.9662C16.9705 14.3549 17.0548 14.7839 17.0548 15.2532C17.0548 16.1039 16.8165 16.7785 16.3398 17.2772C15.8632 17.7685 15.1922 18.0142 14.3268 18.0142C13.7402 18.0142 13.2305 17.9115 12.7978 17.7062C12.3652 17.4935 12.0315 17.2002 11.7968 16.8262C11.5622 16.4522 11.4375 16.0195 11.4228 15.5282H13.2598C13.2965 15.7775 13.3955 15.9829 13.5568 16.1442C13.7255 16.3055 13.9528 16.3862 14.2388 16.3862C14.5688 16.3862 14.8145 16.2799 14.9758 16.0672C15.1445 15.8545 15.2288 15.5722 15.2288 15.2202C15.2288 14.8829 15.1408 14.6225 14.9648 14.4392C14.7888 14.2559 14.5432 14.1642 14.2278 14.1642C13.9858 14.1642 13.7842 14.2192 13.6228 14.3292C13.4615 14.4319 13.3515 14.5675 13.2928 14.7362H11.4668V10.0282H16.6698V11.6782Z",
    6: "M14.9464 12.3602C14.8951 12.0449 14.7924 11.8065 14.6384 11.6452C14.4917 11.4839 14.2791 11.4032 14.0004 11.4032C13.5751 11.4032 13.2707 11.6012 13.0874 11.9972C12.9114 12.3859 12.8307 12.9872 12.8454 13.8012C12.9701 13.5005 13.1827 13.2659 13.4834 13.0972C13.7914 12.9212 14.1471 12.8332 14.5504 12.8332C15.2837 12.8332 15.8631 13.0569 16.2884 13.5042C16.7137 13.9442 16.9264 14.5785 16.9264 15.4072C16.9264 15.9279 16.8201 16.3899 16.6074 16.7932C16.3947 17.1965 16.0794 17.5119 15.6614 17.7392C15.2507 17.9592 14.7557 18.0692 14.1764 18.0692C13.0397 18.0692 12.2551 17.7172 11.8224 17.0132C11.3897 16.3092 11.1734 15.3339 11.1734 14.0872C11.1734 12.6499 11.4044 11.5939 11.8664 10.9192C12.3284 10.2372 13.0801 9.89619 14.1214 9.89619C14.9427 9.89619 15.5661 10.1309 15.9914 10.6002C16.4167 11.0622 16.6551 11.6489 16.7064 12.3602H14.9464ZM12.9884 15.3192C12.9884 15.6565 13.0837 15.9315 13.2744 16.1442C13.4651 16.3495 13.7437 16.4522 14.1104 16.4522C14.4404 16.4522 14.6971 16.3605 14.8804 16.1772C15.0711 15.9939 15.1664 15.7262 15.1664 15.3742C15.1664 15.0222 15.0711 14.7545 14.8804 14.5712C14.6897 14.3805 14.4294 14.2852 14.0994 14.2852C13.7841 14.2852 13.5201 14.3769 13.3074 14.5602C13.0947 14.7362 12.9884 14.9892 12.9884 15.3192Z",
    7: "M16.6083 11.5352L13.8253 17.9922H11.8783L14.6943 11.7552H11.3943V10.1382H16.6083V11.5352Z",
  };

  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="13.9922" r="14" fill="white" />
      <circle cx="14" cy="13.9922" r="11" fill="#F95959" />
      <path d={numbers[num]} fill="white" />
    </svg>
  );
}
