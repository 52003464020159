import { useEffect, useRef, useCallback, useState } from "react";
import { getObjectFromLocalStorage } from "../../../../api/localStorage";
import { sendMessageToExtension } from "../../../../utils/postToExtension";

const INTERVAL_IN_MS = 1000;

export const useExtensionInstalled = (postMessageEventHandler) => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  useEffect(() => {
    window.addEventListener("message", postMessageEventHandler);

    try {
      sendMessageToExtension({
        message: "is-extension-installed",
      });
    } catch (error) {}

    return () => {
      window.removeEventListener("message", postMessageEventHandler);
    };
  }, [postMessageEventHandler]);

  return {
    isExtensionInstalled,
    setIsExtensionInstalled,
  };
};

export const useGetStorageData = (isExtensionInstalled) => {
  useEffect(() => {
    if (isExtensionInstalled) {
      sendMessageToExtension({
        message: "get-storage-data",
      });
    }
  }, [isExtensionInstalled]);
};

export const useUserCheck = (isExtensionInstalled) => {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const intervalForCheckingUserInLocalStorageRef = useRef(null);

  const checkUserInLocalStorage = useCallback(() => {
    const userInfo = getObjectFromLocalStorage("user");

    if (userInfo) {
      setIsUserSignedIn(true);
    } else {
      setIsUserSignedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isExtensionInstalled) {
      intervalForCheckingUserInLocalStorageRef.current = setInterval(
        checkUserInLocalStorage,
        INTERVAL_IN_MS
      );
    }

    return () => {
      clearInterval(intervalForCheckingUserInLocalStorageRef.current);
    };
  }, [checkUserInLocalStorage, isUserSignedIn, isExtensionInstalled]);

  return { isUserSignedIn };
};
