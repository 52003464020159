import React from "react";

import { Item, Title } from "./styles";

function TabItem({ candidate, isActive, isViewed, handleClick }) {
  const title =
    candidate.full_name ||
    [candidate.first_name, candidate.last_name].filter(Boolean).join(" ") ||
    candidate.email ||
    "-";

  return (
    <Item $isActive={isActive} $isViewed={isViewed}>
      <Title $isActive={isActive} $isViewed={isViewed} onClick={handleClick}>
        {title}
      </Title>
    </Item>
  );
}

export default TabItem;
