import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AccountProvider } from "../context";
import { styled } from "styled-components";

import CampaignsTable from "../components/Campaigns/CampaignsTable";
import RateCard from "../components/Campaigns/RateCard";
import CampaignDescription from "../components/Campaigns/CampaignDescription";
import Loader from "../components/Loader";
import NoAnyCampaignsBlock from "../components/Campaigns/NoAnyCampaignsBlock";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

import { getCampaignsStatistic, getCampaign } from "../services/campaigns";
import { sendMessageToExtension } from "../utils/postToExtension";
import {
  getObjectFromLocalStorage,
  removeDataFromLocalStorage,
  saveObjectToLocalStorage,
} from "../api/localStorage";
import { loadUserData } from "../services/user";
import {
  isNeedToShowPostSignUpOnboarding,
  updateFirstTimeCampaignOnboardingUserList,
} from "../utils/utils";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";

const Campaigns = () => {
  const userInfo = getUserFromLocalStorage();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const campaignIdFromUrlParams = searchParams.get("campaignId");

  const [campaignStatisticList, setCampaignStatisticList] = useState({});
  const [selectedCampaignsFilters, setSelectedCampaignsFilters] =
    useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(
    location.state?.campaignToSelect || null
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [isStatisticLoading, setIsStatisticLoading] = useState(true);

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const [isCrmPage, setIsCrmPage] = useState(null);
  const [campaignBeingPrepared, setCampaignBeingPrepared] = useState(null);

  const [isShowStats, setIsShowStats] = useState(true);

  const selectCampaign = (campaign, campaignsFilters) => {
    setSelectedCampaign(campaign);
    setSelectedCampaignsFilters(campaignsFilters || null);
  };

  const onCloseOverview = async () => {
    if (!campaignStatisticList?.allCampaignsCount) {
      setIsStatisticLoading(true);
      await fetchCampaignsStatistic();
    }

    setSelectedCampaign(null);
    setCampaignBeingPrepared(null);
    removeDataFromLocalStorage("campaignToMinimize");
  };

  const attemptToCreateCampaign = () => {
    sendMessageToExtension({
      message: "attempt-create-campaign",
    });
  };

  const fetchCampaignsStatistic = async () => {
    const data = await getCampaignsStatistic();
    if (data.success !== undefined && !data.success) {
      setErrorMessage(data.message);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: data.message,
        },
      });
      setIsStatisticLoading(false);
      return;
    }
    setErrorMessage(null);
    setCampaignStatisticList(data.result);
    setIsStatisticLoading(false);
  };

  const postMessageEventHandler = (event) => {
    if (typeof event.data.isCrm !== "boolean") {
      return;
    }

    window.removeEventListener("message", postMessageEventHandler);

    setIsCrmPage(event.data.isCrm);

    if (
      event.data.isCrm &&
      selectedCampaign?.isNeedToGenerateMessagePreviews &&
      !campaignBeingPrepared
    ) {
      saveObjectToLocalStorage("campaignToMinimize", selectedCampaign);
      setCampaignBeingPrepared(selectedCampaign);
    }
  };

  useEffect(() => {
    loadUserData(null, (res) => {
      setIsLoadingUserData(false);

      if (!isNeedToShowPostSignUpOnboarding(res)) {
        fetchCampaignsStatistic();

        if (userInfo?.hasCreatedCampaign) {
          updateFirstTimeCampaignOnboardingUserList({
            userId: userInfo?.id,
            initializeWithDefaultNames: true,
          });
        }

        window.addEventListener("message", postMessageEventHandler);
        sendMessageToExtension({
          message: "minimize-campaign-prep",
          data: { checkIsCrm: true },
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!campaignIdFromUrlParams) {
      return;
    }

    const campaignToSelect = getObjectFromLocalStorage("campaignToSelect");
    if (campaignToSelect?.id === campaignIdFromUrlParams) {
      removeDataFromLocalStorage("campaignToSelect");
      setSelectedCampaign(campaignToSelect);
    }
  }, [campaignIdFromUrlParams]);

  const fetchCampaign = useCallback(
    async (campaignId) => {
      const data = await getCampaign(campaignId);

      if (data.success !== undefined && !data.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: "Something went wrong while trying to fetch campaign",
          },
        });
        return;
      }

      setSelectedCampaign(data.result.campaign);
      setCampaignBeingPrepared(null);
    },
    [setCampaignBeingPrepared]
  );

  const fetchCampaignData = useCallback(async () => {
    const campaignIdReadyForLaunch = searchParams.get("campaignId");

    if (campaignIdReadyForLaunch) {
      await fetchCampaign(campaignIdReadyForLaunch);
    }
  }, [fetchCampaign]);

  useEffect(() => {
    fetchCampaignData();
  }, [fetchCampaignData]);

  if (isLoadingUserData) {
    return (
      <AccountProvider>
        <CheckExtensionVersion />
      </AccountProvider>
    );
  }

  const handleSetCampaignBeingPrepared = (campaign) => {
    if (isCrmPage !== false) {
      if (campaign) {
        saveObjectToLocalStorage("campaignToMinimize", campaign);
      } else {
        removeDataFromLocalStorage("campaignToMinimize");
      }
      setCampaignBeingPrepared(campaign);
    }
  };

  return (
    <>
      {selectedCampaign !== null ? (
        <CampaignDescription
          campaignId={selectedCampaign?._id || selectedCampaign?.id}
          onClose={onCloseOverview}
          campaignBeingPrepared={campaignBeingPrepared}
          setCampaignBeingPrepared={handleSetCampaignBeingPrepared}
        />
      ) : (
        <Layout>
          <Content id="table_layout">
            {isStatisticLoading ? (
              <LoaderWrapper>
                <Loader parentSize={true} />
              </LoaderWrapper>
            ) : !campaignStatisticList?.allCampaignsCount ? (
              <>
                <NoAnyCampaignsBlock
                  attemptToCreateCampaign={attemptToCreateCampaign}
                />
              </>
            ) : (
              <>
                {!errorMessage && (
                  <StatsContainer
                    $padding={
                      !isShowStats
                        ? "19px 70px 18.5px 42px"
                        : !campaignStatisticList?.mostCampaign?.name
                        ? "19px 70px 16px 65px"
                        : "19px 70px 32px 42px"
                    }
                  >
                    {!isShowStats ? (
                      <KeyMetrics>Key Metrics</KeyMetrics>
                    ) : !campaignStatisticList?.mostCampaign?.name ? (
                      <NoAnyStatsBlock>
                        You do not have any campaign statistics available at
                        this point. This is where we show you the campaign
                        metrics when we have more data.
                      </NoAnyStatsBlock>
                    ) : (
                      <CardContainer>
                        <RateCard
                          isHighlight
                          title="Most successful campaign"
                          text={campaignStatisticList?.mostCampaign?.name || ""}
                          isLoading={isStatisticLoading}
                        />
                        <RateCard
                          title="Open rate"
                          currentRate={campaignStatisticList?.openRate || 0}
                          changeRate={
                            campaignStatisticList?.changeOpenRate || 0
                          }
                          isLoading={isStatisticLoading}
                        />
                        <RateCard
                          title="Engagement rate"
                          currentRate={
                            campaignStatisticList?.engagementRate || 0
                          }
                          changeRate={
                            campaignStatisticList?.changeEngagementRate || 0
                          }
                          isLoading={isStatisticLoading}
                        />
                      </CardContainer>
                    )}
                    <ToggleViewStatsBtn
                      onClick={() => setIsShowStats(!isShowStats)}
                    >
                      {isShowStats ? "Hide" : "Show Metrics"}
                    </ToggleViewStatsBtn>
                  </StatsContainer>
                )}
                <CampaignsTable
                  selectCampaign={selectCampaign}
                  campaignsFilters={selectedCampaignsFilters}
                  attemptToCreateCampaign={attemptToCreateCampaign}
                />
              </>
            )}
          </Content>
        </Layout>
      )}
      {/* </GlobalLayout> */}
      {/* </AccountProvider> */}
    </>
  );
};

export default Campaigns;

const Layout = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  height: ${({ theme }) => `calc(100vh - ${theme.navBarHeightPx}px)`};
  background-color: #fff;
  padding: 0;
`;

const Content = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  height: 100%;
`;

const StatsContainer = styled.div`
  ${({ $padding }) => `padding: ${$padding};`}
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.background_color};
  box-shadow: 0px 0px 20px 0px #0000001a inset;
  transition: padding-top 0.3s cubic-bezier(0.2, 0, 0, 1),
    padding-bottom 0.3s cubic-bezier(0.2, 0, 0, 1);
`;

const ToggleViewStatsBtn = styled.div`
  padding: 3px;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 14px;
  font-weight: 700;
  line-height: 12.74px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #00000033;
  cursor: pointer;

  &:hover {
    color: #00000044;
  }
`;

const KeyMetrics = styled.div`
  padding-top: 3px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  font-weight: 400;
  line-height: 14.56px;
  letter-spacing: -0.02em;
  color: #00000066;
`;

const NoAnyStatsBlock = styled.div`
  padding: 15px 42px 13px 54px;
  width: 54.5%;
  max-width: 668px;
  font-size: 11px;
  font-weight: 400;
  line-height: 16.28px;
  color: #00000080;
  background-color: #fcf6e4;
  background-image: url(images/hint.svg);
  background-repeat: no-repeat;
  background-position: 9px 11px;
  background-size: 27px auto;
  border-radius: 11px;
`;

const CardContainer = styled.div`
  display: flex;
  column-gap: 19px;
  row-gap: 17px;
`;
