import React, { useState } from "react";

import CustomSelect from "../../../CustomSelect";
import getUserFromLocalStorage from "../../../../utils/getUserFromLocalStorage";
import Loader from "../../../Loader";
import { openPreviewMessageLinkInNewTab } from "../../../../utils/utils";
import { CONTENT_TYPES, QUILL_SETTINGS } from "../constants";

import {
  Header,
  Content,
  ActionButtons,
  Button,
  Container,
  ContentBody,
  ContentBodyText,
  ContentTitle,
  EditForm,
  EditingInput,
  Email,
  ErrorMessage,
  Name,
  PromptContent,
  PromptContentLabel,
  PromptContentValue,
  PromptItem,
  PromptRole,
  PromptRoleLabel,
  PromptRoleValue,
  SubjectInputBlock,
  TitleContainer,
  InputTextarea,
  CurrentSequenceContainer,
} from "./styles";

function RightSidePanel({
  isLoading,
  campaign,
  currentSequence,
  currentMessageAttempt,
  filteredCandidates,
  activeCandidateIndex,
  handleSelectMessage,
  activeContent,
  isEditing,
  isLoadFailed,
  setIsEditing,
  editMessage,
  regenerateMessage,
  toggleActiveContent,
  currentMessageType,
  currentPrompt,
  isRegenerating,
  currentMessageSubject,
  currentMessageText,
  updatedMessageText,
  setUpdatedMessageText,
  textareaRef,
  handleInput,
  updatedMessageSubject,
}) {
  const name =
    filteredCandidates[activeCandidateIndex].full_name ||
    [
      filteredCandidates[activeCandidateIndex].first_name,
      filteredCandidates[activeCandidateIndex].last_name,
    ]
      .filter(Boolean)
      .join(" ") ||
    filteredCandidates[activeCandidateIndex].email ||
    "-";

  const isAdmin = () => {
    const userInfo = getUserFromLocalStorage();
    if (!userInfo) {
      return false;
    }
    return userInfo.role === "admin";
  };

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Name>{name}</Name>
          <Email>{filteredCandidates[activeCandidateIndex].email}</Email>
        </TitleContainer>
        {currentSequence?.length > 1 && (
          <CurrentSequenceContainer>
            <CustomSelect
              title=""
              options={currentSequence}
              selected={currentMessageAttempt}
              onSelect={handleSelectMessage}
              minWidth="158px"
              disabled={
                isLoading ||
                (!!campaign.isNeedToGenerateMessagePreviews &&
                  !filteredCandidates[activeCandidateIndex]
                    .isNeedToPrepareAllMessages)
              }
            />
          </CurrentSequenceContainer>
        )}
      </Header>
      <Content>
        <ActionButtons>
          {activeContent === CONTENT_TYPES.EMAIL && (
            <>
              {!isEditing ? (
                <Button
                  $marginLeft="0"
                  $isDisabled={isLoading || isLoadFailed}
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Button $marginLeft="0" onClick={editMessage}>
                    Save
                  </Button>
                  <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                </>
              )}
              <Button
                $isDisabled={
                  isLoading ||
                  isLoadFailed ||
                  (!!campaign.isNeedToGenerateMessagePreviews &&
                    !filteredCandidates[activeCandidateIndex]
                      ?.isNeedToPrepareAllMessages)
                }
                onClick={regenerateMessage}
              >
                Regenerate This Message
              </Button>
            </>
          )}
          {isAdmin() && (
            <Button
              onClick={toggleActiveContent}
              $isDisabled={isLoading || isLoadFailed}
            >
              {activeContent === CONTENT_TYPES.EMAIL
                ? "View Prompt"
                : `View ${currentMessageType === "sms" ? "SMS" : "Email"}`}
            </Button>
          )}
        </ActionButtons>
        {activeContent === CONTENT_TYPES.PROMPT ? (
          <>
            <ContentTitle>Prompt:</ContentTitle>
            <ContentBody
              $height="calc(100vh - 262px)"
              $overflow="auto"
              $margin="5px 0 0 0"
            >
              <ContentBodyText>
                {buildPromptSequence(currentPrompt)}
              </ContentBodyText>
            </ContentBody>
          </>
        ) : isLoading ? (
          <Loader
            parentSize={true}
            padding="0 30px 0 0"
            height="calc(100vh - 220px)"
            text={`Just a moment.  We're ${
              isEditing
                ? "saving"
                : !isRegenerating &&
                  filteredCandidates[activeCandidateIndex]?.hasMessage
                ? "loading"
                : "generating"
            } the message...`}
          />
        ) : isLoadFailed ? (
          <ErrorMessage>Could not load message</ErrorMessage>
        ) : isEditing ? (
          <EditForm>
            {currentMessageType !== "sms" && (
              <>
                <ContentTitle $margin="0 0">Subject:</ContentTitle>
                <ContentBodyText>
                  <SubjectInputBlock
                    $padding="14px 14px 0 11px"
                    $margin="8px 0 0 0"
                    $height="fit-content"
                  >
                    <EditingInput
                      ref={textareaRef}
                      type="text"
                      value={updatedMessageSubject}
                      onInput={handleInput}
                    />
                  </SubjectInputBlock>
                </ContentBodyText>
              </>
            )}
            <InputTextarea
              id="email-text"
              value={updatedMessageText}
              onChange={setUpdatedMessageText}
              {...QUILL_SETTINGS}
              $margin={`${
                currentMessageType !== "sms" ? "10px 0 0 0" : "24px 0 0 0"
              }`}
            />
          </EditForm>
        ) : (
          <>
            {currentMessageType !== "sms" && (
              <>
                <ContentTitle $margin="0 0">Subject:</ContentTitle>
                <ContentBody $padding="14px 14px 0 11px" $margin="8px 0 0 0">
                  <ContentBodyText $margin="0 0">
                    <p>{currentMessageSubject}</p>
                  </ContentBodyText>
                </ContentBody>
              </>
            )}
            <ContentBody
              $height="calc(100vh - 320px)"
              $overflow="auto"
              $margin={`${
                currentMessageType !== "sms" ? "10px 0 0 0" : "24px 0 0 0"
              }`}
            >
              <ContentBodyText>
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentMessageText,
                  }}
                  onClick={openPreviewMessageLinkInNewTab}
                ></div>
              </ContentBodyText>
            </ContentBody>
          </>
        )}
      </Content>
    </Container>
  );
}

export default RightSidePanel;

const buildPromptSequence = (promptSequence) => {
  if (!promptSequence || !promptSequence?.length) {
    return "No Prompt";
  }

  return promptSequence.map((prompt, index) => {
    return (
      <PromptItem key={index}>
        <PromptRole>
          <PromptRoleLabel>Role:</PromptRoleLabel>
          <PromptRoleValue>{prompt.role}</PromptRoleValue>
        </PromptRole>
        <PromptContent>
          <PromptContentLabel>Content:</PromptContentLabel>
          <PromptContentValue>
            {prompt.content.replace(/\\n/g, "\n")}
          </PromptContentValue>
        </PromptContent>
      </PromptItem>
    );
  });
};
