import React from "react";

import { ENVs } from "../../../utils/constants";
import ConfigurationWizardButton from "./ConfigurationButton";
import TabList from "./Tabs";

import { Container, Title } from "./styles";
import { EnvName } from "../../styles";

function Sidebar({ isAdmin }) {
  const currentEnv = () => {
    const envName = Object.keys(ENVs).find(
      (key) => ENVs[key] === window.location.origin
    );
    return envName;
  };

  return (
    <Container>
      <Title>All Settings</Title>
      <TabList />
      <ConfigurationWizardButton />
      {isAdmin && <EnvName>env: {currentEnv()}</EnvName>}
    </Container>
  );
}

export default Sidebar;
