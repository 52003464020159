import styled from "styled-components";

export const Container = styled.button`
  font-size: 14px;
  line-height: 16px;
  padding: 11px 24px;
  font-weight: 500;
  background-color: #0b57d0;
  border-color: #0b57d0;
  color: #fff;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  user-select: none;
  transition: all 0.15s ease-out;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    background-color: #0b57d0;
    border-color: #0b57d0;
  }
`;
