import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border-top-left-radius: 10px;
  min-width: 265px;
  max-width: 265px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 1px solid #dbdbdb;
`;

export const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  border-bottom: 1px solid #d0d0d0;
  padding: 25px 0 10px 25px;
`;
