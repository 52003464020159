import React from "react";
import styled from "styled-components";

function Button({ title, styles, onClick, isLastUsed }) {
  const {
    backgroundColor,
    backgroundColorActive,
    backgroundColorHover,
    logoUrl,
    isRoundedLogo,
  } = styles || {};

  return (
    <Container
      onClick={onClick}
      $backgroundColor={backgroundColor}
      $backgroundColorActive={backgroundColorActive}
      $backgroundColorHover={backgroundColorHover}
      $isLastUsed={isLastUsed}
    >
      {isLastUsed ? <LastUsedLabel>Last used</LastUsedLabel> : null}
      <Logo $logoUrl={logoUrl} $isRounded={isRoundedLogo} />
      {title}
    </Container>
  );
}

export default Button;

const LAST_USED_LABEL_TOP = "9px";

const Container = styled.button`
  margin: 0;
  padding: 0 26px 0 34px;
  border: 0;
  cursor: pointer;
  outline: none;
  position: relative;
  flex: 1 0 auto;
  height: 34px;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 9.88px;
  font-weight: 600;
  letter-spacing: -0.02em;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 9px;

  &:hover {
    background-color: ${({ $backgroundColorHover }) => $backgroundColorHover};
  }

  &:active {
    background-color: ${({ $backgroundColorActive }) => $backgroundColorActive};
  }

  ${({ $isLastUsed }) =>
    $isLastUsed
      ? ` 
          margin-top: ${LAST_USED_LABEL_TOP};
        `
      : ""};

  @media (min-width: 2000px) {
    font-size: 11.88px;
  }
`;

const LastUsedLabel = styled.span`
  position: absolute;
  right: -6px;
  top: -${LAST_USED_LABEL_TOP};
  color: #fff;
  background: #727272;
  border-radius: 18px;
  padding: 2px 9px 3px;
  font-size: 10px;
  font-weight: 600;
  cursor: default;
`;

const Logo = styled.div`
  background-image: ${({ $logoUrl }) => `url(${$logoUrl});`};
  width: ${({ $isRounded }) => ($isRounded ? "22px" : "14px")};
  height: ${({ $isRounded }) => ($isRounded ? "22px" : "14px")};
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  ${({ $isRounded }) => ($isRounded ? "border-radius: 50%;" : "")}
`;
