import React from "react";
import { Tooltip } from "react-tooltip";
import {
  Container,
  Credits,
  Description,
  MoreInfoLabel,
  EstValueText,
  EstValueSvg,
  EstTitle,
  Loader,
  InfoTooltip,
  CreditsAllocatedValue,
  CreditsAllocatedText,
  CreditsUsedBar,
  CreditsUsedText,
  SubscriptionPlan,
  UpgradePlanContainer,
  UpgradePlanBtn,
  CreditsLabel,
} from "./styles";
import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
} from "../../../../api/localStorage";
import { useNavigate } from "react-router-dom";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import getUserFromLocalStorage from "../../../../utils/getUserFromLocalStorage";
import { USER_ROLES } from "../../../../utils/constants";

function EstimatedTokenCostCard(props) {
  const navigate = useNavigate();

  const { isLoading, estimatedToken, isCollapsed } = props;
  const creditsRemaining = Math.max(props.creditsRemaining, 0);

  const userInfo = getUserFromLocalStorage();
  const companyInfo = getObjectFromLocalStorage("companyInfo");

  const subscriptionPlanName = companyInfo?.company?.subscriptionPlanName;
  const subscriptionPeriodEnd = new Date(
    companyInfo.company?.subscriptionPeriodEnd || Date.now()
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const creditsPerMonth = Math.round(
    companyInfo?.company?.creditsPerMonth / 1000
  );
  const creditsUsedPart = !creditsPerMonth
    ? 1
    : 1 - creditsRemaining / creditsPerMonth;

  const handleClickUpgradePlan = (event) => {
    event.preventDefault();

    const extensionId = getDataFromLocalStorage("extensionId") || "";
    const extensionVersion = getDataFromLocalStorage("extensionVersion") || "";

    navigate(
      `/settings?extensionId=${extensionId}&extensionVersion=${extensionVersion}&isSubscription=true&from=campaign-creation`,
      { state: { from: "campaign-creation" } }
    );

    sendMessageToExtension({
      message: "change-iframe-window-type",
      data: {
        iframeWindowType: "drawer",
      },
    });
  };

  const estVal = estimatedToken.toLocaleString("en");

  return (
    <Container $isCollapsed={isCollapsed}>
      <Credits $isCollapsed={isCollapsed}>
        {isLoading && (
          <Loader $isCollapsed={isCollapsed}>
            <div className="dot-flashing" />
          </Loader>
        )}
        {isCollapsed && estVal.length > 2 ? (
          <EstValueSvg
            viewBox={`0 0 ${20 * estVal.length} 33`}
            $isLoading={isLoading}
          >
            <text x="50%" y="50%">
              {estVal}
            </text>
          </EstValueSvg>
        ) : (
          <EstValueText $isCollapsed={isCollapsed} $isLoading={isLoading}>
            {estVal}
          </EstValueText>
        )}
      </Credits>

      {isCollapsed ? (
        <CreditsLabel>{`credit${estVal === "1" ? "" : "s"}`}</CreditsLabel>
      ) : (
        <>
          <EstTitle>Estimated Credit Usage</EstTitle>
          <Description>
            The credit estimate is based on
            <br />
            your campaign parameters.
          </Description>
          <MoreInfoLabel id="outreach-credits-info" $disabled={isLoading}>
            More Information
          </MoreInfoLabel>
          <InfoTooltip>
            <Tooltip
              anchorSelect="#outreach-credits-info"
              clickable
              place="bottom"
            >
              <CreditsAllocatedValue>
                {creditsPerMonth.toLocaleString("en")}
              </CreditsAllocatedValue>
              <CreditsAllocatedText>Credits Allocated</CreditsAllocatedText>
              <CreditsUsedBar $value={creditsUsedPart * 100}>
                <div />
              </CreditsUsedBar>
              <CreditsUsedText $withSubscription={!!subscriptionPlanName}>
                {subscriptionPlanName ? (
                  <>
                    You are on the{" "}
                    <SubscriptionPlan>
                      {subscriptionPlanName} plan
                    </SubscriptionPlan>{" "}
                    until {subscriptionPeriodEnd}, and have already used
                  </>
                ) : (
                  "You have already used"
                )}{" "}
                {creditsUsedPart
                  .toLocaleString("en-US", {
                    style: "percent",
                    minimumFractionDigits: 2,
                  })
                  .replace(".00%", "%")}{" "}
                of the credits!
              </CreditsUsedText>
              {!!subscriptionPlanName &&
                (userInfo?.role === USER_ROLES.ADMIN ||
                  userInfo?.role === USER_ROLES.COMPANY_ADMIN) && (
                  <UpgradePlanContainer>
                    <UpgradePlanBtn onClick={handleClickUpgradePlan}>
                      Upgrade Now
                    </UpgradePlanBtn>
                  </UpgradePlanContainer>
                )}
            </Tooltip>
          </InfoTooltip>
        </>
      )}
    </Container>
  );
}

export default EstimatedTokenCostCard;
