import React from "react";

import {
  CHROME_STORE_EXTENSION_ADDRESS,
  SOURCE_QUERY_PARAM,
  TEMP_LOCAL_STORAGE_KEY_NAME_COOKIE_NAME,
  TEMP_LOCAL_STORAGE_KEY_NAME_LIST_COOKIE_NAME,
} from "../../../../utils/constants";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import {
  removeLoginDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../../../api/localStorage";
import Button from "./Button";
import {
  useExtensionInstalled,
  useGetStorageData,
  useUserCheck,
} from "./hooks";
import { getCookie, removeCookie } from "../../../../utils/utils";
import { getAccessToken } from "../../../../api/axios";
import { apiUrl } from "../../../../utils/config";

const LinkButton = () => {
  const postMessageEventHandler = (event) => {
    if (event?.data?.isExtensionInstalled !== undefined) {
      setIsExtensionInstalled(event.data.isExtensionInstalled);
    }

    if (event?.data?.iframeStorageData) {
      removeLoginDataFromLocalStorage();
      for (const key in event.data.iframeStorageData) {
        saveDataToLocalStorage(
          key,
          event.data.iframeStorageData[key] /* , true */
        );
      }
    }
  };

  syncLocalStorageWithCookies();

  const { isExtensionInstalled, setIsExtensionInstalled } =
    useExtensionInstalled(postMessageEventHandler);
  useGetStorageData(isExtensionInstalled);
  const { isUserSignedIn } = useUserCheck(isExtensionInstalled);

  const handleClickInstallExtension = () => {
    window.open(CHROME_STORE_EXTENSION_ADDRESS, "_blank");
  };

  /**
   * When we redirect from HubSpot integration page(iframe), we should transfer local storage data
   * to /install-hubspot-app page.
   */
  function syncLocalStorageWithCookies() {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get(SOURCE_QUERY_PARAM);

    if (!source) {
      return;
    }

    try {
      const localStorageKeyNameList = JSON.parse(
        getCookie(TEMP_LOCAL_STORAGE_KEY_NAME_LIST_COOKIE_NAME)
      );

      if (!localStorageKeyNameList || !localStorageKeyNameList.length) {
        return;
      }

      localStorageKeyNameList.forEach((keyName) => {
        const localStorageData = getCookie(
          `${TEMP_LOCAL_STORAGE_KEY_NAME_COOKIE_NAME}${keyName}`
        );

        if (typeof localStorageData === "string") {
          const parsedLocalStorageData = JSON.parse(
            localStorageData.replace(/!!@!!/g, ";")
          ); // return ';', because some string data must contain ';'.

          saveDataToLocalStorage(keyName, parsedLocalStorageData, true);
          removeCookie(`${TEMP_LOCAL_STORAGE_KEY_NAME_COOKIE_NAME}${keyName}`);
        }
      });

      removeCookie(TEMP_LOCAL_STORAGE_KEY_NAME_LIST_COOKIE_NAME);

      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    } catch (error) {
      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    }
  }

  const handleClickSignIn = () => {
    sendMessageToExtension({
      message: "need-log-in",
    });
  };

  const handleClickInstallApp = async () => {
    // We pass accessToken to get a user data BE.
    window.location.href = `${apiUrl}/install-hubspot-app-step-2?accessToken=${getAccessToken()}`;
  };

  const CurrentButton = () => {
    if (!isExtensionInstalled) {
      return (
        <Button
          onClick={handleClickInstallExtension}
          text="Install Extension"
        />
      );
    }

    if (isExtensionInstalled && !isUserSignedIn) {
      return <Button onClick={handleClickSignIn} text="Sign In" />;
    }

    if (isExtensionInstalled && isUserSignedIn) {
      return <Button onClick={handleClickInstallApp} text="Install App" />;
    }

    return <Button text="..." />;
  };

  return <CurrentButton />;
};

export default LinkButton;
