import React, { useEffect, useState } from "react";
import { AccountProvider } from "../context";
import {
  Content,
  ContentContainer,
  EnvName,
  Layout,
  SideBar,
  SidebarItemTitle,
  SideBarTitle,
  TabItem,
  TabList,
} from "./styles";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

import { Usage } from "../components/Settings/TabContent";
import Loader from "../components/Loader";
import UserActivity from "../components/Settings/TabContent/Reports/UserActivity";
import CampaignsSummary from "../components/Settings/TabContent/Reports/CampaignsSummary";
import CampaignDetails from "../components/Settings/TabContent/Reports/CampaignDetails";
import UndeliverableEmails from "../components/Settings/TabContent/Reports/UndeliverableEmails";

import { loadUserData } from "../services/user";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import { ENVs } from "../utils/constants";

const GENERAL_TAB_KEY = "general";
const USAGE_TAB_KEY = "usage";
const UNDELIVERABLE_EMAILS_TAB_KEY = "undeliverableEmails";

const MULTI_LEVEL_TABS_LIST = [GENERAL_TAB_KEY];
const SINGLE_LEVEL_TABS_LIST = [USAGE_TAB_KEY, UNDELIVERABLE_EMAILS_TAB_KEY];

const Reports = () => {
  const [isLoading, setIsLoading] = useState(true);

  const SIDEBAR_TABS = {
    [GENERAL_TAB_KEY]: {
      title: "General Reports",
      tabs: {
        userActivity: {
          title: "User Activity",
          component: () => <UserActivity />,
        },
        campaignsSummary: {
          title: "Campaigns Summary",
          component: () => <CampaignsSummary />,
        },
        campaignDetails: {
          title: "Campaign Details",
          component: () => <CampaignDetails />,
        },
      },
    },
    [USAGE_TAB_KEY]: {
      title: "Usage",
      component: () => <Usage />,
    },
    [UNDELIVERABLE_EMAILS_TAB_KEY]: {
      title: "Undeliverable Emails",
      component: () => <UndeliverableEmails />,
    },
  };

  const [styledTab, setStyledTab] = useState(GENERAL_TAB_KEY);
  const [activeTab, setActiveTab] = useState(
    Object.keys(SIDEBAR_TABS[GENERAL_TAB_KEY].tabs)[0]
  );

  const userInfo = getUserFromLocalStorage();
  const isAdmin = userInfo ? userInfo.role === "admin" : false;

  const handleTabClick = (key) => {
    if (MULTI_LEVEL_TABS_LIST.includes(key)) {
      setStyledTab(key);
      setActiveTab(Object.keys(SIDEBAR_TABS[key].tabs)[0]);
      return;
    }
    if (SINGLE_LEVEL_TABS_LIST.includes(key)) {
      setStyledTab(key);
      setActiveTab(key);
      return;
    }
    const topKey = MULTI_LEVEL_TABS_LIST.find((tk) =>
      Object.keys(SIDEBAR_TABS[tk]?.tabs || {}).includes(key)
    );
    setStyledTab(topKey);
    setActiveTab(key);
  };

  useEffect(() => {
    loadUserData(userInfo, () => {
      setIsLoading(false);
    });
  }, []);

  const currentEnv = () => {
    const envName = Object.keys(ENVs).find(
      (key) => ENVs[key] === window.location.origin
    );
    return envName;
  };

  if (isLoading) {
    return (
      <AccountProvider>
        <CheckExtensionVersion />
      </AccountProvider>
    );
  }

  return (
    <Layout>
      <ContentContainer>
        <SideBar>
          <SideBarTitle>All Reports</SideBarTitle>
          <TabList>
            {Object.keys(SIDEBAR_TABS).map((key) => {
              const isActive = styledTab === key;
              const isMulti = MULTI_LEVEL_TABS_LIST.includes(key) && isActive;
              return (
                <React.Fragment key={key}>
                  <TabItem $isActive={isActive} $isMulti={isMulti}>
                    <SidebarItemTitle
                      $isActive={isActive}
                      onClick={() => handleTabClick(key)}
                    >
                      {SIDEBAR_TABS[key].title}
                    </SidebarItemTitle>
                    {isMulti && (
                      <TabList $isSubList={true}>
                        {Object.keys(SIDEBAR_TABS[key].tabs).map((subKey) => (
                          <React.Fragment key={subKey}>
                            <TabItem
                              $isSubItem={true}
                              $isActive={activeTab === subKey}
                            >
                              <SidebarItemTitle
                                $isSubItem={true}
                                $isActive={activeTab === subKey}
                                onClick={() => handleTabClick(subKey)}
                              >
                                {SIDEBAR_TABS[key].tabs[subKey].title}
                              </SidebarItemTitle>
                            </TabItem>
                          </React.Fragment>
                        ))}
                      </TabList>
                    )}
                  </TabItem>
                </React.Fragment>
              );
            })}
          </TabList>
          {isAdmin && <EnvName>env: {currentEnv()}</EnvName>}
        </SideBar>
        <Content $activeTab={activeTab}>
          {isLoading ? (
            <Loader parentSize={true} />
          ) : SINGLE_LEVEL_TABS_LIST.includes(activeTab) ? (
            SIDEBAR_TABS[activeTab].component()
          ) : (
            MULTI_LEVEL_TABS_LIST.map((key) => (
              <React.Fragment key={key}>
                {SIDEBAR_TABS[key]?.tabs[activeTab]?.component()}
              </React.Fragment>
            ))
          )}
        </Content>
      </ContentContainer>
    </Layout>
  );
};

export default Reports;
