import { styled } from "styled-components";

export const List = styled.ul`
  display: flex;
  list-style-type: none;
  flex-direction: column;
  padding: 0 0 0 20px;
  height: ${({ $isScrollListActive }) =>
    $isScrollListActive ? "100%" : "560px"};
  overflow-y: ${({ $isScrollListActive }) =>
    $isScrollListActive ? "scroll" : "hidden"};
  position: relative;

  ${({ $isScrollListActive }) =>
    `
      ${
        !$isScrollListActive &&
        `
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 66px;
                width: 100%;
                z-index: 1;
                background: linear-gradient(
                  360deg,
                  #ffffff 64.7%,
                  rgba(255, 255, 255, 0.7) 100%);
            }
        `
      }

      ${
        $isScrollListActive &&
        `
          &::before {
            content: none;
          }
        `
      }
    `}
`;
