import React from "react";

export default function SearchIcon({ fill }) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.08976 14.9077C9.73397 14.9088 11.331 14.3565 12.6228 13.3381L17.4956 18.2109L18.7414 16.9652L13.8686 12.0923C15.2453 10.3345 15.7493 8.04604 15.2361 5.87269C14.724 3.6992 13.2509 1.87696 11.2336 0.919323C9.2162 -0.0371081 6.87276 -0.0244691 4.86578 0.953768C2.8588 1.93201 1.4053 3.76909 0.915019 5.94829C0.425884 8.1264 0.95291 10.409 2.34909 12.1518C3.74413 13.8947 5.8569 14.9085 8.08991 14.9074L8.08976 14.9077ZM8.08976 1.97457C9.57092 1.97457 10.9912 2.56244 12.0385 3.61073C13.0868 4.65786 13.6746 6.07815 13.6746 7.55943C13.6746 9.04071 13.0868 10.4609 12.0385 11.5081C10.9913 12.5564 9.57104 13.1443 8.08976 13.1443C6.60848 13.1443 5.18831 12.5564 4.14106 11.5081C3.09277 10.461 2.50491 9.04071 2.50491 7.55943C2.50491 6.07815 3.09277 4.65798 4.14106 3.61073C5.1882 2.56244 6.60848 1.97457 8.08976 1.97457Z"
        fill={fill || "black"}
      />
    </svg>
  );
}
