import React from "react";
import PropTypes from "prop-types";
import { MultiSelect } from "react-multi-select-component";
import { styled } from "styled-components";

function AdvancedSelect(props) {
  const {
    options,
    value,
    onSelect,
    disabled,
    label,
    placeholder,
    margin,
    paddingRight,
    width,
    arrowWidth,
    isOpen,
  } = props;

  const handleSelect = (arr) => {
    const newValue = (
      arr.length > 1 ? arr.find((o) => o.value !== value) : arr[0]
    )?.value;
    onSelect(newValue !== undefined ? newValue : value);
  };

  return (
    <Select
      $margin={margin}
      $paddingRight={paddingRight}
      $width={width}
      $placeholder={placeholder}
    >
      <MultiSelect
        isOpen={isOpen}
        valueRenderer={(s) => s.map((o) => o.label).join(", ")}
        options={options || []}
        value={options?.filter((o) => o.value === value) || []}
        onChange={handleSelect}
        disabled={disabled}
        labelledBy={label}
        hasSelectAll={false}
        closeOnChangedValue={true}
        ClearIcon={<span />}
        ClearSelectedIcon={<span />}
        ArrowRenderer={() => (
          <img
            src="/images/arrow-down.svg"
            width={arrowWidth || "12"}
            alt="arrow-down"
          />
        )}
      />
    </Select>
  );
}

AdvancedSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool,
        PropTypes.string,
      ]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
};

export default AdvancedSelect;

const DROPDOWN_OFFSET_LEFT = "3px";

const Select = styled.div`
  ${({ $margin }) => ($margin ? `margin : ${$margin};` : "")};
  width: ${({ $width }) => $width || "242px"};

  > .multi-select {
    width: 100%;
    &,
    * {
      font-family: "Poppins", sans-serif !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 19.2px !important;
    }

    --rmsc-main: #cdcdcd;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #cdcdcd;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 10px;
    --rmsc-radius: 4px;
    --rmsc-h: 36px;

    .dropdown-container {
      border-color: var(--rmsc-border) !important;
      box-shadow: none !important;

      &[aria-expanded="true"] {
        .dropdown-heading {
          .dropdown-heading-value {
            *:not(.gray) {
              font-weight: 700 !important;
            }
          }
        }
      }

      &[aria-disabled="true"] {
        opacity: 0.85 !important;

        .dropdown-heading {
          cursor: default !important;

          img {
            opacity: 0.65 !important;
          }
        }
      }
    }

    .dropdown-heading {
      padding: 0 ${({ $paddingRight }) => $paddingRight || "17px"} 0
        var(--rmsc-p);
      box-shadow: 0px 0px 20px 0px #0000000d inset;
      cursor: pointer;

      button.clear-selected-button {
        margin: 0 7px;
      }
    }

    .dropdown-content {
      left: -${DROPDOWN_OFFSET_LEFT};
      width: calc(100% + 32px);
      padding-top: 3px;

      .panel-content {
        border: 1px solid #d7d5d5fa;
        box-shadow: 0px 0px 20px 0px #0000000d inset, 0px 4px 100px 0px #0000001a;
      }
    }

    .search {
      padding: 9px 11px 12px var(--rmsc-p);
      border: 0;

      input {
        margin: 0 !important;
        height: 33px !important;
        border: 1px solid #d1d1d180 !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        background: #f4f4f4 !important;
        &::placeholder {
          color: #00000054;
        }
      }
    }

    .options {
      padding: 0 0 16px;
    }

    .select-item {
      padding: 10px 22px;
    }

    ${({ $placeholder }) =>
      $placeholder && $placeholder !== "Select..."
        ? `
        .gray {
          color: transparent;
          position: relative;
          &::before {
            position:absolute;
            content: '${$placeholder}';
            color: var(--rmsc-gray);
          }
        }
    `
        : ""}
  }
`;
