import React, { useContext } from "react";

import Loader from "../../../components/Loader";
import { MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE } from "../../../utils/constants";
import { SettingsContext } from "../context";

import { Container } from "./styles";

function Content({ isLoading }) {
  const { sidebarTabs, activeTab } = useContext(SettingsContext);

  return (
    <Container $activeTab={activeTab}>
      {isLoading ? (
        <Loader parentSize={true} />
      ) : !MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE.includes(activeTab) &&
        sidebarTabs[activeTab] ? (
        sidebarTabs[activeTab].component()
      ) : (
        MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE.map((key) => (
          <React.Fragment key={key}>
            {sidebarTabs[key]?.tabs[activeTab]?.component()}
          </React.Fragment>
        ))
      )}
    </Container>
  );
}

export default Content;
