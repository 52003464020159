import React from "react";

import Button from "./Button";

function GsuiteButton({ onClick, isLastUsed, isLogin }) {
  return (
    <Button
      title={`Sign ${isLogin ? "in" : "up"} with Google`}
      onClick={onClick}
      styles={{
        backgroundColor: "#5284ED",
        backgroundColorActive: "#2c6bb2",
        backgroundColorHover: "#357ae8",
        logoUrl: "images/google-icon.png",
        isRoundedLogo: true,
      }}
      isLastUsed={isLastUsed}
    />
  );
}

export default GsuiteButton;
