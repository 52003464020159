import React from "react";
import { styled } from "styled-components";

function ConfirmPopup({
  title,
  description,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  noOverlay,
  iconSrc,
}) {
  return (
    <>
      <ConfirmPopupContainer $iconSrc={iconSrc}>
        <PopupTitle>{title}</PopupTitle>
        <PopupDescription>{description}</PopupDescription>
        <PopupActionButtons>
          <ConfirmButton onClick={(e) => onConfirm(e)}>
            {confirmLabel || "Confirm"}
          </ConfirmButton>
          <CancelButton onClick={(e) => onCancel(e)}>
            {cancelLabel || "Cancel"}
          </CancelButton>
        </PopupActionButtons>
      </ConfirmPopupContainer>
      {!noOverlay && <Overlay />}
    </>
  );
}

export default ConfirmPopup;

const ConfirmPopupContainer = styled.div`
  position: fixed;
  top: calc((100vh - 164px) / 2);
  left: calc((100vw - 487px) / 2);
  width: 487px;
  height: 164px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 0px 30px 0px #0000001a;
  ${({ $iconSrc }) =>
    !$iconSrc
      ? ""
      : `
    background-image: url(${$iconSrc});
    background-repeat: no-repeat;
    background-size: 38px auto;
    background-position: 20px 18px;
    > div {
      padding-left: 50px;
    }
  `}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const PopupTitle = styled.div`
  font-size: 16px;
  font-weight: 800;
  font-family: "AlbertSansExtraBold", sans-serif;
`;

const PopupDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #475467;
`;

const PopupActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

const ConfirmButton = styled.button`
  background-color: #471168;
  height: 40px;
  padding: 0 21px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  font-family: "AlbertSansExtraBold", sans-serif;
  border-radius: 6px;
  border: none;
  cursor: pointer;
`;

const CancelButton = styled.div`
  color: #4640de;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
`;
