import { useEffect, useState } from "react";

export function useGetIntegrationOrder() {
  return ["crm", "emailService", "smsService", "aiModel"];
}

export function useGetActiveTab(tabName) {
  const [activeTab, setActiveTab] = useState(tabName);

  useEffect(() => {
    setActiveTab(tabName);
  }, [tabName]);

  return {
    activeTab,
    setActiveTab,
  };
}
