import React, { useState } from "react";
import styled from "styled-components";
import StartNewCampaignIcon from "./icons/StartNewCampaignIcon";
import ResumeIcon from "./icons/ResumeIcon";
import ResumeArrowIcon from "./icons/ResumeArrowIcon";
import theme from "../../../theme";

function ResumeCampaignStep({ date, handleResume, handleStartOver }) {
  const commaIdxInDate = date.lastIndexOf(",");

  const [isSelectedResumeOption, setIsSelectedResumeOption] = useState(true);

  return (
    <Form>
      <Section>
        <Title>We have a previous campaign draft saved!</Title>
        <SelectBlock>
          You previously started creating a campaign. Choose if you want to
          continue where you left off or start a new campaign?
        </SelectBlock>

        <LastSavedBlock>
          <img src="/images/clock-rotate180.svg" alt="clock" width={15} />
          <div>
            Last Saved <span>{date.substring(0, commaIdxInDate)}</span> at{" "}
            <span>{date.substring(commaIdxInDate + 2)}</span>
          </div>
        </LastSavedBlock>

        <OptionsBlock>
          <Option
            $isResumeOption={true}
            $isSelected={isSelectedResumeOption}
            onClick={() => setIsSelectedResumeOption(true)}
          >
            <ResumeIcon
              fill={isSelectedResumeOption ? "#fff" : "#6F6F6F"}
              opacity={isSelectedResumeOption ? 1 : 0.7}
            />
            <OptionContent>
              <OptionTitle>Resume Editing</OptionTitle>
              <OptionDescription>
                Continue from where you left off. All your edits were autosaved.
              </OptionDescription>
              <div className="button" onClick={handleResume}>
                <ResumeArrowIcon
                  fill={isSelectedResumeOption ? theme.colors.blue : "#000"}
                />
                Resume Campaign Creation
              </div>
            </OptionContent>
          </Option>
          <Option
            $isSelected={!isSelectedResumeOption}
            onClick={() => setIsSelectedResumeOption(false)}
          >
            <StartNewCampaignIcon
              fill={!isSelectedResumeOption ? "#fff" : "#6F6F6F"}
              opacity={!isSelectedResumeOption ? 1 : 0.7}
            />
            <OptionContent>
              <OptionTitle>Create a New Campaign</OptionTitle>
              <OptionDescription>
                You will lose all previous edits if you choose this option
              </OptionDescription>
              <div className="button" onClick={handleStartOver}>
                <ResumeArrowIcon
                  fill={!isSelectedResumeOption ? theme.colors.blue : "#000"}
                />
                Create a New Campaign
              </div>
            </OptionContent>
          </Option>
        </OptionsBlock>
      </Section>
    </Form>
  );
}

export default ResumeCampaignStep;

const Form = styled.form`
  padding: 28px 54px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
`;

const Section = styled.div`
  padding: 52px 25px;
  width: 100%;
  height: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px 10px 0 0;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 20px 0px #0000000d;
`;

const Title = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
`;

const SelectBlock = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.28px;
  text-align: center;
  max-width: 448px;
`;

const LastSavedBlock = styled.div`
  margin-top: 14px;
  padding: 1px 16px 1px 3px;
  width: fit-content;
  min-height: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 9px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.28px;
  text-align: left;
  color: #ffffff;
  background: #595959;
  border-radius: 41px;

  span {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }
`;

const OptionsBlock = styled.div`
  margin: 59px 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  align-items: center;
  justify-content: center;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ $isSelected, $isResumeOption }) =>
    $isSelected
      ? `41px ${$isResumeOption ? 65 : 52}px 50px`
      : `24px ${$isResumeOption ? 50 : 40}px 26px`};
  background-color: ${({ $isSelected }) =>
    $isSelected ? theme.colors.blue : "#F4F4F4"};
  border-radius: 10px;
  border: ${({ $isSelected }) =>
    `1px solid ${$isSelected ? "#CDCDCD" : "#E5E5E5"}`};
  ${({ $isSelected }) =>
    $isSelected ? "box-shadow: 0px 4px 40px 0px #0000001a;" : ""};
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#000")};

  &:hover {
    border-color: #d2cdcd;
  }

  .button {
    margin-top: 33px;
    background-color: ${({ $isSelected }) =>
      $isSelected ? "#fff" : "transparent"};
    color: ${({ $isSelected }) => ($isSelected ? theme.colors.blue : "#000")};
    border-radius: 6px;
    ${({ $isSelected }) => ($isSelected ? "" : "border: 1px solid #757678;")};
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 19.2px;
    width: fit-content;
    padding: ${({ $isSelected }) => ($isSelected ? "10px 25px" : "11px 22px")};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;

    &:hover {
      ${({ $isSelected }) =>
        $isSelected ? "opacity: 0.85;" : "background-color: #fff;"};
    }

    svg {
      min-width: ${({ $isSelected }) => `${$isSelected ? 18 : 16}px`};
      min-height: ${({ $isSelected }) => `${$isSelected ? 18 : 16}px`};
      width: ${({ $isSelected }) => `${$isSelected ? 18 : 16}px`};
      height: ${({ $isSelected }) => `${$isSelected ? 18 : 16}px`};
    }
  }
`;

const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const OptionTitle = styled.div`
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-align: center;
`;

const OptionDescription = styled.div`
  margin-top: 1px;
  max-width: 252px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.28px;
  text-align: center;
  opacity: 0.7;
`;
