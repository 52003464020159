import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Loader from "../../Loader";
import { getStrategies } from "../../../services/campaigns";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
} from "../../../utils/constants";
import StepButton from "../../StepButton";
import { isBullhornMarketingCampaignType } from "../utils";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";
import {
  ButtonGroup,
  ErrorMessage,
  OFFSET_LEFT,
  RadioBlock,
  RadioButtonGroup,
  RadioInput,
  Section,
} from "../styles";
import DefineNewType from "./DefineNewType";
import SaveDraftButton from "./SaveDraftButton";

function SalesStrategy({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
  crm,
}) {
  const parentRef = useRef(null);
  const [strategies, setStrategies] = useState([]);
  const [isCustomStrategyInputOpened, setIsCustomStrategyInputOpened] =
    useState(false);
  const [selectedStrategy, setStrategy] = useState(campaign.strategy);
  const [customStrategy, setCustomStrategy] = useState("");
  const [customStrategyErrorMessage, setCustomStrategyErrorMessage] =
    useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fetchStrategiesErrorMessage, setFetchStrategiesErrorMessage] =
    useState("");
  const [isLoading, setIsLoading] = useState(true);

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.salesStrategy,
    parentRef,
  };

  const handleChange = (strategy) => {
    setErrorMessage("");
    setCustomStrategyErrorMessage("");

    setStrategy(strategy);
    setIsCustomStrategyInputOpened(false);
    setCustomStrategy("");
  };

  const handleClickDefineNewStrategy = () => {
    setIsCustomStrategyInputOpened(true);

    setErrorMessage("");
    setCustomStrategyErrorMessage("");

    setStrategy("");
  };

  const handleCancelDefineNewType = () => {
    setIsCustomStrategyInputOpened(false);
    setCustomStrategyErrorMessage("");
  };

  const handleCreateNewType = (newStrategy) => {
    setIsCustomStrategyInputOpened(false);
    setCustomStrategyErrorMessage("");

    const newStrategyLc = newStrategy.toLowerCase();
    const existingStrategy = strategies.find(
      (g) => g.toLowerCase() === newStrategyLc
    );
    if (existingStrategy) {
      handleChange(existingStrategy);
      return;
    }

    setCustomStrategy(newStrategy);
  };

  const handleClickNext = (event) => {
    event.preventDefault();
    if (
      selectedStrategy === "" &&
      !isCustomStrategyInputOpened &&
      !customStrategy
    ) {
      setErrorMessage("Please choose a campaign theme");
      return;
    }
    if (
      selectedStrategy === "" &&
      (isCustomStrategyInputOpened || customStrategy)
    ) {
      if (!customStrategy.length && isCustomStrategyInputOpened) {
        setCustomStrategyErrorMessage("This field is required");
        return;
      }
      if (customStrategy) {
        setCustomStrategyErrorMessage("");
        setCampaign((campaign) => ({ ...campaign, strategy: customStrategy }));
        handleIncreaseStep();
        return;
      }
    }

    setCampaign((campaign) => ({
      ...campaign,
      strategy: selectedStrategy,
    }));
    handleIncreaseStep();
  };

  const applyCampaignChanges = (asDraft) => {
    if (selectedStrategy === "" && customStrategy !== "") {
      setCampaign((campaign) => ({
        ...campaign,
        strategy: customStrategy,
        asDraft,
      }));
      return;
    }

    setCampaign((campaign) => ({
      ...campaign,
      strategy: selectedStrategy,
      asDraft,
    }));
  };

  const handleClickBack = (event) => {
    event.preventDefault();
    applyCampaignChanges();
    handleDecreaseStep();
  };

  const handleClickSaveDraft = (event) => {
    event.preventDefault();
    applyCampaignChanges(true);
  };

  const fetchStrategies = async () => {
    setIsLoading(true);

    const data = {
      crm,
    };

    if (
      crm === CRMS.BULLHORN &&
      !isBullhornMarketingCampaignType(campaign.type)
    ) {
      const job_description =
        campaign.jobDescription[campaign.jobDescriptionKey];
      const job_pay_rate = campaign.payRate;

      data.job_description = job_description;
      data.job_pay_rate = job_pay_rate;
    } else {
      data.campaign_type = campaign.type;
      data.campaign_details = campaign.details;
    }

    const response = await getStrategies(data);

    if (response.success !== undefined && !response.success) {
      setFetchStrategiesErrorMessage(response.message);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.isMoneyInsufficient
            ? "You do not have enough credits to perform this operation"
            : "Unfortunately, we had trouble fetching themes",
        },
      });
      setIsLoading(false);
      handleDecreaseStep();
      return;
    }

    const currentStrategies = Object.values(response.result.result);

    setFetchStrategiesErrorMessage(null);
    setStrategies(currentStrategies);
    setCampaign((campaign) => ({
      ...campaign,
      localStrategies: currentStrategies,
      strategy: "",
      jobDescriptionChanged: false,
    }));
    setIsLoading(false);
  };

  useEffect(() => {
    if (!campaign.localStrategies || campaign.jobDescriptionChanged) {
      return;
    }
    setStrategies(campaign.localStrategies);
    if (
      !campaign.localStrategies.includes(campaign.strategy) &&
      campaign.strategy !== ""
    ) {
      setIsCustomStrategyInputOpened(false);
      setCustomStrategy(campaign.strategy);
      setIsLoading(false);
      return;
    }

    setIsCustomStrategyInputOpened(false);
    setCustomStrategy("");
    setStrategy(campaign.strategy);
    setIsLoading(false);
  }, [campaign]);

  useEffect(() => {
    if (!campaign.localStrategies || campaign.jobDescriptionChanged) {
      fetchStrategies();
    }
  }, [campaign]);

  return (
    <>
      {isLoading ? (
        <Loader text="Generating campaign themes..." />
      ) : fetchStrategiesErrorMessage ? (
        <ErrorMessage>{fetchStrategiesErrorMessage}</ErrorMessage>
      ) : (
        <>
          <Section ref={parentRef}>
            <FirstTimeCampaignOnboarding
              config={firstTimeCampaignOnboardingConfig}
            />
            <SelectBlock>
              <Label fontWeight="bold">
                Select a theme for the messages in this campaign
              </Label>
            </SelectBlock>
            <RadioButtonGroup>
              {strategies.map((strategy, index) => (
                <RadioBlock key={index}>
                  <RadioInput
                    $checked={strategy === selectedStrategy}
                    onClick={() => handleChange(strategy)}
                  >
                    <div />
                    <span>{strategy}</span>
                  </RadioInput>
                </RadioBlock>
              ))}
              {!!customStrategy && (
                <RadioBlock key={customStrategy}>
                  <RadioInput $checked={true}>
                    <div />
                    <span>{customStrategy}</span>
                  </RadioInput>
                </RadioBlock>
              )}
            </RadioButtonGroup>

            <DefineNewType
              label="Theme"
              isOpened={isCustomStrategyInputOpened}
              onOpen={handleClickDefineNewStrategy}
              onClose={handleCancelDefineNewType}
              onCreate={handleCreateNewType}
              onInput={() => setCustomStrategyErrorMessage("")}
              errorMessage={customStrategyErrorMessage}
            />

            {errorMessage !== "" && (
              <ErrorMessage $marginLeft={OFFSET_LEFT}>
                {errorMessage}
              </ErrorMessage>
            )}
          </Section>
          <ButtonGroup>
            <StepButton onClick={handleClickBack} isNext={false} />
            <StepButton onClick={handleClickNext} isNext={true} />
            <SaveDraftButton onClick={handleClickSaveDraft} />
          </ButtonGroup>
        </>
      )}
    </>
  );
}

export default SalesStrategy;

const SelectBlock = styled.div`
  padding: 39px ${OFFSET_LEFT} 17px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
`;

const Label = styled.label`
  margin: 0;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
`;
