import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PrimaryButton as Button } from "../../styles";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import Loader from "../../Loader";
import SuggestionPopup from "../../SuggestionPopup";
import CampaignMayExceedCreditsPopup from "./CampaignMayExceedCreditsPopup";
import ConfirmLaunchPopup from "./ConfirmLaunchPopup";
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
} from "../../../api/localStorage";
import {
  CRM_LABELS,
  CRMS,
  INTEGRATION_TYPE,
  PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY,
} from "../../../utils/constants";
import { validateIntegrations } from "../../../services/integrations";
import { hasEmailStepInSequence, hasSmsStepInSequence } from "../utils";

const MAY_EXCEED_CREDITS_POPUP_CLOSED = 0;
const MAY_EXCEED_CREDITS_POPUP_OPENED_FROM_PREPARE = 1;
const MAY_EXCEED_CREDITS_POPUP_OPENED_FROM_LAUNCH = 2;

function CreateCampaignButton({
  campaign,
  isDisabled,
  mayExceedCreditsData,
  createCampaign,
  isPreparing,
  setIsPreparing,
}) {
  const navigate = useNavigate();

  const [mayExceedCreditsPopupOpened, setMayExceedCreditsPopupOpened] =
    useState(MAY_EXCEED_CREDITS_POPUP_CLOSED);
  const [isConfirmLaunchPopupOpened, setIsConfirmLaunchPopupOpened] =
    useState(false);
  const [noIntegrationListForPopup, setNoIntegrationListForPopup] =
    useState(null);

  const openConfirmLaunchPopup = () => {
    setIsConfirmLaunchPopupOpened(true);
  };

  const closeConfirmLaunchPopup = (e) => {
    e?.preventDefault();
    setIsConfirmLaunchPopupOpened(false);
  };

  const confirmCreate = (e) => {
    removeDataFromLocalStorage(PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY, true);
    setIsPreparing(true);
    closeConfirmLaunchPopup();
    createCampaign(e);
  };

  const handlePrepare = async (e) => {
    e.preventDefault();

    const message = await validateIntegrations(
      campaign.crmType,
      setIsPreparing,
      false
    );

    if (message) {
      setNoIntegrationListForPopup(message);
      return;
    }

    if (mayExceedCreditsData) {
      setMayExceedCreditsPopupOpened(
        MAY_EXCEED_CREDITS_POPUP_OPENED_FROM_PREPARE
      );
      return;
    }

    confirmCreate(e);
  };

  const handleLaunch = async (e) => {
    e.preventDefault();

    const hasSmsStep = hasSmsStepInSequence(
      campaign.sequenceTemplate,
      campaign.sequenceTemplates
    );
    const hasEmailStep = hasEmailStepInSequence(
      campaign.sequenceTemplate,
      campaign.sequenceTemplates
    );

    const message = await validateIntegrations(
      campaign.crmType,
      setIsPreparing,
      hasEmailStep,
      hasSmsStep
    );

    if (message) {
      setNoIntegrationListForPopup(message);
      return;
    }

    if (mayExceedCreditsData) {
      setMayExceedCreditsPopupOpened(
        MAY_EXCEED_CREDITS_POPUP_OPENED_FROM_LAUNCH
      );
      return;
    }

    openConfirmLaunchPopup();
  };

  const handleCloseMayExceedCreditsPopup = (e) => {
    e.preventDefault();
    setMayExceedCreditsPopupOpened(MAY_EXCEED_CREDITS_POPUP_CLOSED);
  };

  const handleConfirmMayExceedCredits = (e) => {
    e.preventDefault();
    setMayExceedCreditsPopupOpened(MAY_EXCEED_CREDITS_POPUP_CLOSED);
    confirmCreate(e);
  };

  const getIntegrationErrorPopup = () => {
    if (!noIntegrationListForPopup) {
      return null;
    }

    const isEmailInt = noIntegrationListForPopup.includes(
      INTEGRATION_TYPE.EMAIL
    );
    const isSmsInt = noIntegrationListForPopup.includes(INTEGRATION_TYPE.SMS);

    const onConfirm = () => {
      const extensionId = getDataFromLocalStorage("extensionId") || "";
      const extensionVer = getDataFromLocalStorage("extensionVersion") || "";
      navigate(
        `/settings?extensionId=${extensionId}&extensionVersion=${extensionVer}&${
          isEmailInt ? "isEmailInt" : isSmsInt ? "isSmsInt" : "isCrm"
        }=true`
      );
      sendMessageToExtension({
        message: "change-iframe-window-type",
        data: {
          iframeWindowType: "drawer",
        },
      });
    };

    if (!isEmailInt && !isSmsInt) {
      const error = noIntegrationListForPopup[0];
      const action = campaign.isFullSequenceCampaign
        ? "launch your campaign"
        : "create a campaign";
      return (
        <SuggestionPopup
          imageSrc="/logo192.png"
          title={`Your Integration is ${error}`}
          description={
            campaign.crmType === CRMS.HUBSPOT && error === "expired"
              ? `We are unable to ${action} as your HubSpot integration is ${error}.  Please reinstall the HubSpot app.`
              : `Unfortunately, we can’t ${action} due to a ${error} ${
                  CRM_LABELS[campaign.crmType]
                } integration.  Would you like to update it now?`
          }
          onConfirm={onConfirm}
          onCancel={() => {
            sendMessageToExtension({
              message: "open-campaigns",
            });
          }}
          confirmBtnText="Let’s do it"
          cancelBtnText="I’ll do it later"
        />
      );
    }

    const labels = noIntegrationListForPopup.join(" and ");
    const ending = noIntegrationListForPopup.length > 1 ? "s" : "";
    return (
      <SuggestionPopup
        title={`${labels} Integration${ending} Needed`}
        description={`You cannot launch a campaign until you’ve integrated your ${labels} provider${ending}.  Would you like to do that now?`}
        onConfirm={onConfirm}
        onCancel={() => {
          setNoIntegrationListForPopup(null);
        }}
        confirmBtnText="Yes, take me there"
        cancelBtnText="Maybe later"
      />
    );
  };

  return (
    <>
      {isPreparing && <Loader zIndex={11} />}
      {mayExceedCreditsPopupOpened !== MAY_EXCEED_CREDITS_POPUP_CLOSED && (
        <CampaignMayExceedCreditsPopup
          values={mayExceedCreditsData}
          onConfirm={handleConfirmMayExceedCredits}
          onCancel={handleCloseMayExceedCreditsPopup}
        />
      )}
      {isConfirmLaunchPopupOpened && (
        <ConfirmLaunchPopup
          isFullSequenceCampaign={campaign.isFullSequenceCampaign}
          cancelLaunch={closeConfirmLaunchPopup}
          confirmLaunch={confirmCreate}
        />
      )}
      {getIntegrationErrorPopup()}
      <CustomButton
        $large={true}
        onClick={campaign.isFullSequenceCampaign ? handleLaunch : handlePrepare}
        type="submit"
        disabled={isDisabled}
      >
        {campaign.isFullSequenceCampaign
          ? "Launch Campaign"
          : "Prepare Campaign"}
      </CustomButton>
    </>
  );
}

export default CreateCampaignButton;

const CustomButton = styled(Button)`
  // margin-left: auto;
`;
