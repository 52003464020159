import React from "react";
import styled from "styled-components";

function Separator() {
  return <Container>OR</Container>;
}

export default Separator;

const Container = styled.span`
  width: 90%;
  display: flex;
  margin: 40px 0 35px;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #000;
  height: 21px;
  position: relative;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background-color: #000;
    opacity: 0.2;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;
