import styled from "styled-components";

export const Layout = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  height: ${({ theme }) => `calc(100vh - ${theme.navBarHeightPx}px)`};
  background-color: ${({ theme }) => theme.colors.background_color};
  padding: 2.3% 3.3% 0 3.3%;
`;

export const SideBarTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-weight: 700;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #d0d0d0;
  padding: 25px 0 10px 25px;
`;

export const ContentContainer = styled.div`
  display: flex;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 20px 0px #0000001a inset;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 100%;
`;

export const SideBar = styled.div`
  background-color: #fff;
  border-top-left-radius: 10px;
  width: 265px;
  flex: 0 0 265px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 1px solid #dbdbdb;
`;

export const SidebarItemTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-weight: ${({ $isActive }) => ($isActive ? 700 : 400)};
  font-size: 12px;
  color: ${({ $isDisabled }) => ($isDisabled ? "#bbb" : "#000")};
  ${({ $isSubItem }) => ($isSubItem ? "line-height: 14.4px;" : "")};
  width: 100%;
`;

export const TabList = styled.ul`
  padding: ${({ $isSubList }) => ($isSubList ? "7px 18px 0 0" : "15px 18px")};
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

export const TabItem = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${({ $isSubItem }) =>
    $isSubItem ? "7px 0 7px 27px" : "6px 0 6px 27px"};

  ${({ $isActive, $isMulti, $isSubItem }) =>
    $isActive &&
    !$isSubItem &&
    `
    ${
      $isMulti
        ? `
          margin-bottom: 10px;
          box-shadow: 0 -3px #f7f7f7;
    `
        : ""
    }
    
    background-color: #f7f7f7;
    border-radius: 10px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      border: 2px solid #f95959;
      border-radius: 10px;
      top: 7px;
      left: 16px;
      height: 12px;
    }
  `}
`;

export const Content = styled.div`
  flex-grow: 1;
  border-top-right-radius: 10px;
  margin-top: 10px;
  box-shadow: 0 -10px white;
  background-color: white;
  padding: 33px 0 43px 63px;
  overflow: auto;
`;

export const EnvName = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-size: 12px;
  color: grey;
`;
