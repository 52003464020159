import React from "react";

import AuthLayout from "../components/AuthLayout";
import ContactUs from "../components/ContactUs";
import { useLocation } from "react-router-dom";

const ContactUsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isLogin = searchParams.get("reason") !== "code";

  return (
    <AuthLayout
      switchAuthPageUrl={`/${isLogin ? "login" : "register"}`}
      switchAuthPageBtn={`Back to sign ${isLogin ? "in" : "up"}`}
    >
      <ContactUs />
    </AuthLayout>
  );
};

export default ContactUsPage;
