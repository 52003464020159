import React from "react";
import { useLocation } from "react-router-dom";
import StepButton from "../StepButton";
import { sendMessageToExtension } from "../../utils/postToExtension";
import {
  removeDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../api/localStorage";

export default function WizardStepControls({ stepConfig, goToTab }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromSettings = String(searchParams.get("fromSettings")) === "true";

  const handleClickFinishLater = (event) => {
    event?.preventDefault();

    if (stepConfig.curTab) {
      saveDataToLocalStorage("outreachConfigWizardTab", stepConfig.curTab);
    }

    sendMessageToExtension({
      message: fromSettings ? "open-crm-settings" : "open-campaigns",
    });
  };

  const handleClickFinish = (event) => {
    removeDataFromLocalStorage("outreachConfigWizardTab");
    goToTab(event, "finish");
  };

  return (
    <>
      {stepConfig.prevTab && (
        <StepButton
          onClick={(event) => goToTab(event, stepConfig.prevTab)}
          isNext={false}
        />
      )}

      {stepConfig.nextTab ? (
        <>
          <StepButton
            onClick={(event) => goToTab(event, stepConfig.nextTab)}
            isNext={true}
          />
          <StepButton
            onClick={handleClickFinishLater}
            label="Finish Later"
            isNext={false}
          />
        </>
      ) : (
        <StepButton onClick={handleClickFinish} label="Finish" isNext={true} />
      )}
    </>
  );
}
