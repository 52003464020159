import React from "react";

export default function CheckCircleIcon({ fill }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="13.9922" r="11" fill={fill || "#F95959"} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5303 18.2393L12 17.709L11.4697 18.2393C11.7626 18.5322 12.2374 18.5322 12.5303 18.2393ZM12 16.6483L8.53033 13.1787C8.23744 12.8858 7.76256 12.8858 7.46967 13.1787C7.17678 13.4715 7.17678 13.9464 7.46967 14.2393L11.4697 18.2393L12 17.709C12.5303 18.2393 12.5303 18.2394 12.5303 18.2393L20.2478 10.5218C20.5407 10.2289 20.5407 9.75403 20.2478 9.46114C19.955 9.16824 19.4801 9.16824 19.1872 9.46114L12 16.6483Z"
        fill="white"
      />
    </svg>
  );
}
