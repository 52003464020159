import React from "react";
import SuggestionPopup from "../components/SuggestionPopup";
import { sendMessageToExtension } from "../utils/postToExtension";

const Alert = () => (
  <SuggestionPopup
    imageSrc="/logo192.png"
    title="We can't find your contacts!"
    description="Please navigate to a browser tab with your CRM or spreadsheet to start a campaign"
    confirmBtnText="Got it!"
    onConfirm={() => {
      sendMessageToExtension({
        message: "close-iframe",
      });
    }}
    removeCancel
    noOverlay
  />
);

export default Alert;
