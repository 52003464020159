export const CONTENT_TYPES = {
  EMAIL: "email",
  PROMPT: "prompt",
};

export const QUILL_SETTINGS = {
  theme: "snow",
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ align: [] }],
      [{ color: [] }],
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "clean",
        { list: "bullet" },
        { list: "ordered" },
        "link",
        "code-block",
        "blockquote",
      ],
    ],
  },
};
