import { sendMessageToExtension } from "../utils/postToExtension";

const getAllLocalStorageValues = () => {
  const values = {};
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const value = localStorage.getItem(key);
    values[key] = value;
  }
  return values;
};

const sendUpdatedValuesToExtension = () => {
  const allValues = getAllLocalStorageValues();

  sendMessageToExtension({
    message: "save-value-to-storage",
    data: { ...allValues },
  });
};

const saveDataToLocalStorage = (key, value, isNotForExtension = false) => {
  localStorage.setItem(key, value);
  if (isNotForExtension) {
    return;
  }

  sendUpdatedValuesToExtension();
};

const saveObjectToLocalStorage = (key, object, isNotForExtension = false) => {
  const value = JSON.stringify(object);
  localStorage.setItem(key, value);
  if (isNotForExtension) {
    return;
  }

  sendUpdatedValuesToExtension();
};

const getDataFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

const getObjectFromLocalStorage = (key) => {
  try {
    const item = localStorage.getItem(key);
    const value = JSON.parse(item);
    return value;
  } catch (error) {
    console.error("getObjectFromLocalStorage error:", error);
    return undefined;
  }
};

const removeDataFromLocalStorage = (key, isNotForExtension = false) => {
  localStorage.removeItem(key);
  if (isNotForExtension) {
    return;
  }

  sendUpdatedValuesToExtension();
};

const removeDataListFromLocalStorage = (keys) => {
  for (const key of keys) {
    localStorage.removeItem(key);
  }

  sendUpdatedValuesToExtension();
};

const CREATE_CAMPAIGN_DATA_KEYS = [
  "jobTitle",
  "jobDescription",
  "payRate",
  "isEmailIntegration",
  "isSmsIntegration",
  "currentDraftCampaign",
  "currentTempCampaign",
  "tempCampaignId",
  "isCampaignChanged",
  "campaignInfo",
  "viewedCandidatesIds",
];

const CLEAR_CACHE_EXCLUDE_KEYS = [
  "extensionId",
  "extensionVersion",
  "refreshToken",
  "MinExtensionVersion",
  "accessToken",
  "user",
  "version",
  "src",
];

const removeCreateCampaignDataFromLocalStorage = () => {
  removeDataListFromLocalStorage(CREATE_CAMPAIGN_DATA_KEYS);
};

const removeClearCacheDataFromLocalStorage = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!CLEAR_CACHE_EXCLUDE_KEYS.includes(key)) {
      localStorage.removeItem(key);
    }
  }
};

const removeLoginDataFromLocalStorage = () => {
  const keys = [
    "token",
    "user",
    "accessToken",
    "refreshToken",
    "isPostSignUpOnboardingFinishLater",
    "companyInfo",
    "campaignToMinimize",
    "campaignToSelect",
    ...CREATE_CAMPAIGN_DATA_KEYS,
  ];
  removeDataListFromLocalStorage(keys);
};

const clearLocalStorage = () => {
  localStorage.clear();
};

export {
  saveDataToLocalStorage,
  saveObjectToLocalStorage,
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
  removeDataFromLocalStorage,
  removeCreateCampaignDataFromLocalStorage,
  removeLoginDataFromLocalStorage,
  clearLocalStorage,
  removeClearCacheDataFromLocalStorage,
};
