import React, { useEffect, useState } from "react";
import { AccountProvider } from "../context";
import {
  Content,
  ContentContainer,
  Layout,
  SideBar,
  SidebarItemTitle,
  SideBarTitle,
  TabItem,
  TabList,
} from "./styles";
import { Tooltip } from "react-tooltip";

import { FAQs } from "../components/FAQs";
import CheckExtensionVersion from "../components/CheckExtensionVersion";
import { loadUserData } from "../services/user";

const Support = () => {
  const SIDEBAR_TABS = {
    FAQs: {
      title: "FAQs",
      component: () => <FAQs />,
    },
    supportTicket: {
      title: "Log a Support Ticket",
      disabledContent: true,
    },
  };
  const [activeTab, setActiveTab] = useState(Object.keys(SIDEBAR_TABS)[0]);
  const [styledTab, setStyledTab] = useState(Object.keys(SIDEBAR_TABS)[0]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadUserData(null, () => {
      setIsLoading(false);
    });
  }, []);

  const handleTabClick = (key) => {
    setStyledTab(key);
    setActiveTab(key);
  };

  if (isLoading) {
    return (
      <AccountProvider>
        <CheckExtensionVersion />
      </AccountProvider>
    );
  }

  return (
    <Layout>
      <ContentContainer>
        <SideBar>
          <SideBarTitle>Support</SideBarTitle>
          <TabList>
            {Object.keys(SIDEBAR_TABS).map((key) => {
              const isActive = styledTab === key;
              const isDisabled = SIDEBAR_TABS[key].disabledContent;
              return (
                <React.Fragment key={key}>
                  <TabItem
                    $isActive={isActive}
                    data-tooltip-id={isDisabled ? `${key}-tooltip` : null}
                    data-tooltip-content={isDisabled ? "Coming soon" : null}
                  >
                    <SidebarItemTitle
                      onClick={() => (isDisabled ? null : handleTabClick(key))}
                      $isActive={isActive}
                      $isDisabled={isDisabled}
                    >
                      {SIDEBAR_TABS[key].title}
                    </SidebarItemTitle>
                  </TabItem>
                  {isDisabled ? (
                    <Tooltip
                      id={`${key}-tooltip`}
                      place="right"
                      className="custom-tooltip"
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </TabList>
        </SideBar>
        <Content $activeTab={activeTab}>
          {SIDEBAR_TABS[activeTab].component()}
        </Content>
      </ContentContainer>
    </Layout>
  );
};

export default Support;
