const WIZARD_STEPS = [
  {
    id: 1,
    title: "Adding Private App",
    description:
      "Reach is creating a new Private App within your HubSpot instance.",
    imageSrc: "/images/automate-hubspot-API/step1.png",
  },
  {
    id: 2,
    title: "Adding Scope",
    description:
      "Now, we are limiting the Private App's scope to Contact and Company entities.",
    imageSrc: "/images/automate-hubspot-API/step2.png",
  },
  {
    id: 3,
    title: "Private App Created",
    description:
      "The Private App has now been created and we are almost ready to begin using Reach.",
    imageSrc: "/images/automate-hubspot-API/step3.png",
  },
  {
    id: 4,
    title: "Generating API Token",
    description:
      "Now, Reach is ready to grab the newly generated Private App's token and complete the setup.",
    imageSrc: "/images/automate-hubspot-API/step4.png",
  },
];

export { WIZARD_STEPS };
