import React from "react";

import {
  AiCreditMonthly,
  AiCreditMonthlyContainer,
  Benefits,
  Button,
  Description,
  ItemText,
  MainContainer,
  Name,
  PlanItem,
  Price,
  TextHighlight,
} from "./styles";

const Plan = ({
  id,
  name,
  description,
  planItems,
  price,
  isSelected,
  onSelect,
  onBuy,
  isDisabled = false,
}) => {
  const isFreePlan = price === 0;

  return (
    <MainContainer
      onClick={!isSelected ? () => onSelect(id, isFreePlan) : undefined}
      $isSelected={isSelected}
    >
      <Name $isSelected={isSelected}>{name}</Name>
      <Description $isSelected={isSelected}>
        <AiCreditMonthlyContainer>
          <AiCreditMonthly $isSelected={isSelected}>
            {description}
          </AiCreditMonthly>
        </AiCreditMonthlyContainer>
        {!!planItems?.length && (
          <Benefits>
            {planItems.map((pi, i) => (
              <PlanItem key={i} $isSelected={isSelected}>
                <ItemText>{pi}</ItemText>
              </PlanItem>
            ))}
          </Benefits>
        )}
      </Description>
      <Price $isSelected={isSelected}>
        <TextHighlight>{`$${price}`}</TextHighlight>/month
      </Price>
      {!isDisabled && (
        <Button
          $isSelected={isSelected}
          onClick={isSelected ? () => onBuy() : undefined}
        >
          Select {name}
        </Button>
      )}
    </MainContainer>
  );
};

export default Plan;
