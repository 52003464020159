/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { displayDate } from "./utils";
import { styled } from "styled-components";
import theme from "../../theme";
import { getCampaigns } from "../../services/campaigns";
import InfiniteScroll from "../InfiniteScroll";
import Loader from "../Loader";
import OverlayMessage from "./OverlayMessage";
import Spinner from "../Spinner";
import { debounce } from "lodash";
import { sendMessageToExtension } from "../../utils/postToExtension";
import TableFilters from "./TableFilter";
import { CAMPAIGN_STATUSES } from "./constants";
import { displayNumber, displayPercent } from "../../utils/utils";
import { PrimaryButton } from "../styles";
import Search from "../CreateCampaign/ContentSteps/Search";

const CampaignsTable = ({
  selectCampaign,
  campaignsFilters,
  attemptToCreateCampaign,
}) => {
  const [campaignList, setCampaignList] = useState(null);
  const [dataLength, setDataLength] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({
    filters: campaignsFilters?.filters || {},
    searchString: campaignsFilters?.searchString || "",
  });

  const [loadingDataParams, setLoadingDataParams] = useState(null);

  const [hasMore, setHasMore] = useState(true);

  const [relevantStatuses, setRelevantStatuses] = useState([]);
  const [lastError, setLastError] = useState(null);
  const [isErrorOverlayShown, setIsErrorOverlayShown] = useState(false);
  const [errorTooltipMessage, setErrorTooltipMessage] = useState(null);
  const [errorCampaignId, setErrorCampaignId] = useState(null);

  const getLimit = () => {
    const elm = document.querySelector("#campaigns-table-title");
    const tableMargins = elm
      ? elm.getBoundingClientRect().bottom
      : +theme.navBarHeightPx;
    const campaignsTableHeight = window.innerHeight - tableMargins;

    const limit = Math.floor(campaignsTableHeight / 50);
    return limit;
  };

  const fetchCampaigns = async (params) => {
    console.log("fetchCampaigns", params, selectedFilters);

    const newLoadingDataParams = JSON.stringify({ params, selectedFilters });
    if (newLoadingDataParams === loadingDataParams) {
      console.log("fetchCampaigns", "SKIP");
      return;
    }

    setLoadingDataParams(newLoadingDataParams);

    const limit = getLimit();

    const data = {
      offset: params?.clearAfterSearch ? 0 : dataLength,
      limit,
    };

    const { filters, searchString } = selectedFilters;

    if (searchString && searchString !== "") {
      data.query = searchString;
    }

    if (filters && Object.keys(filters).length > 0) {
      data.filters = filters;
    }

    const result = await getCampaigns(data);

    if (result.success !== undefined && !result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch campaigns",
        },
      });
      sendMessageToExtension({
        message: "close-iframe",
      });
      return;
    }

    setRelevantStatuses(result.result.meta.statuses);

    setCampaignList(
      params?.clearAfterSearch
        ? result.result.campaigns
        : [...(campaignList || []), ...result.result.campaigns]
    );

    setDataLength(result.result.meta.offset + result.result.campaigns.length);
    setHasMore(result.result.meta.hasMore);

    setLoadingDataParams(null);
  };

  const handleSearch = debounce((searchString) => {
    setSelectedFilters({
      ...selectedFilters,
      searchString,
    });
  }, 500);

  const showErrorOverlay = (campaign) => {
    if (
      campaign.status === CAMPAIGN_STATUSES.ERROR &&
      !isErrorOverlayShown &&
      campaign.lastError
    ) {
      setLastError(campaign.lastError);
      setErrorTooltipMessage("Click to copy");
      setErrorCampaignId(campaign.id);
      setIsErrorOverlayShown(true);
    }
  };

  const hideErrorOverlay = () => {
    if (isErrorOverlayShown) {
      setLastError(null);
      setErrorTooltipMessage("Click to copy");
      setErrorCampaignId(null);
      setIsErrorOverlayShown(false);
    }
  };

  const handleCopy = () => {
    setErrorTooltipMessage("Copied!");
  };

  useEffect(() => {
    fetchCampaigns({ clearAfterSearch: true });
  }, [selectedFilters]);

  return (
    <>
      {!campaignList ? (
        <LoaderWrapper>
          <Loader parentSize={true} />
        </LoaderWrapper>
      ) : (
        <TableLayout>
          {isErrorOverlayShown && <OverlayMessage message={lastError} />}
          <TableTitleBlock id="campaigns-table-title">
            <TableTitle>All Campaigns</TableTitle>
            <ButtonBlock>
              <TableFilters
                fields={[
                  {
                    type: "select",
                    title: "Status",
                    inputs: [
                      {
                        key: "statuses",
                        isMultiSelect: true,
                        relevantValues: relevantStatuses,
                      },
                    ],
                  },
                ]}
                values={selectedFilters.filters}
                load={(newFilters) =>
                  setSelectedFilters({
                    ...selectedFilters,
                    filters: newFilters,
                  })
                }
              />
              <Search
                value={selectedFilters.searchString}
                onChange={handleSearch}
              />
            </ButtonBlock>
            <ButtonBlock>
              <PrimaryButton
                $margin="-10px 0 0"
                $padding="0 15px"
                onClick={attemptToCreateCampaign}
              >
                <img src="/images/plus-icon-white.svg" alt="+" width="9" />{" "}
                Create a Campaign
              </PrimaryButton>
              <RefreshButton
                onClick={() => fetchCampaigns({ clearAfterSearch: true })}
              >
                <img src="/images/refresh-icon.svg" alt="refresh" width="20" />
              </RefreshButton>
            </ButtonBlock>
          </TableTitleBlock>
          <InfiniteScroll
            dataLength={dataLength}
            next={fetchCampaigns}
            hasMore={hasMore}
            scrollableTarget={"table_layout"}
            style={{ overflow: "hidden" }}
            loader={<Spinner width="60" height="60" margin="20px 0 0 40vw" />}
          >
            <Table>
              <TableHead>
                <TableRow $bgColor={theme.colors.candidate_table_head_color2}>
                  <TableHeadData $padding="9px 5px 7px 32px" $maxWidth="200px">
                    Campaign
                  </TableHeadData>
                  <TableHeadData>Created</TableHeadData>
                  <TableHeadData>Goal</TableHeadData>
                  <TableHeadData>Recipients</TableHeadData>
                  <TableHeadData>Emails Sent</TableHeadData>
                  <TableHeadData>Open Rate</TableHeadData>
                  <TableHeadData>Engagement Rate</TableHeadData>
                  <TableHeadData $padding="9px 32px 7px 0">
                    Current Status
                  </TableHeadData>
                </TableRow>
              </TableHead>
              {campaignList && campaignList.length !== 0 ? (
                <TableBody>
                  {campaignList.map((campaign, index) => {
                    return (
                      <TableRow $borderBottom="1px solid #E8E8E8" key={index}>
                        <TableData
                          onClick={() =>
                            selectCampaign(campaign, selectedFilters)
                          }
                          $cursor={"pointer"}
                          $maxWidth="200px"
                          $padding="18px 5px 18px 32px"
                          $isHighlight={true}
                        >
                          {campaign.name}
                        </TableData>
                        <TableData>{displayDate(campaign.createdAt)}</TableData>
                        <TableData>{campaign.goal}</TableData>
                        <TableData>{campaign.contactCount}</TableData>
                        <TableData>{displayNumber(campaign.sent)}</TableData>
                        <TableData>
                          {displayPercent(campaign.openRate)}
                        </TableData>
                        <TableData>
                          {displayPercent(campaign.engagementRate)}
                        </TableData>
                        <TableData
                          $padding="18px 32px 18px 0"
                          $cursor={
                            campaign.status === CAMPAIGN_STATUSES.ERROR
                              ? "pointer"
                              : "default"
                          }
                        >
                          <CopyToClipboard
                            text={lastError ? lastError : null}
                            onCopy={handleCopy}
                          >
                            <CampaignStatus
                              onMouseOver={() => showErrorOverlay(campaign)}
                              onMouseLeave={() => hideErrorOverlay()}
                            >
                              {isErrorOverlayShown &&
                              lastError &&
                              campaign.id === errorCampaignId
                                ? errorTooltipMessage
                                : campaign.status}
                            </CampaignStatus>
                          </CopyToClipboard>
                        </TableData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableData $padding="0 0 0 32px" colSpan={100}>
                      No campaigns to show
                    </TableData>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </InfiniteScroll>
        </TableLayout>
      )}
    </>
  );
};

export default CampaignsTable;

const TableHeadData = styled.th`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 12px;
  padding: ${({ $padding }) => $padding || "9px 5px 7px 0"};
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth};` : "")};
  color: ${({ theme }) => theme.colors.black_o70};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: ${({ theme }) => theme.colors.white};
`;

const TableHead = styled.thead`
  background-color: ${({ theme }) => theme.colors.white};
  text-align: left;
  position: sticky;
  top: -1px;
  z-index: 10;
`;

const TableRow = styled.tr`
  height: 28px;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor}` : "")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}` : "")};
  padding: ${({ $padding }) => $padding || "18px 5px 18px 0"};
  color: ${({ theme, $isHighlight }) =>
    $isHighlight ? theme.colors.blue : theme.colors.black_o70};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")};
  font-size: ${({ $isHighlight }) => ($isHighlight ? "12px" : "11px")};
  font-weight: 400;
  line-height: ${({ $isHighlight }) => ($isHighlight ? "14.4px" : "13.2px")};

  @media (min-width: 2000px) {
    font-size: ${({ $isHighlight }) => ($isHighlight ? "14px" : "13px")};
  }
`;

const LoaderWrapper = styled.div`
  flex-grow: 1;
`;

const TableLayout = styled.div`
  flex-grow: 1;
  border-right: 1px solid #e8e8e8;
  box-shadow: 0px 0px 30px 0px #0000001a;
  background-color: #fff;
  // max-width: 1440px;
`;

const TableTitleBlock = styled.div`
  position: sticky;
  top: 0px;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 36px;
  padding: 25px 28px 15px 42px;
  background-color: white;
  box-shadow: ${({ theme }) =>
    `0 1px ${theme.colors.candidate_table_head_color2}`};

  + div {
    padding-left: 1px;
  }
`;

const TableTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
  color: #000;
`;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 25px;
  & + & {
    margin-left: auto;
  }
`;

const RefreshButton = styled.button`
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: fit-content;
  border-radius: 6px;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer;

  img {
    width: 20px;
  }
`;

const CampaignStatus = styled.div`
  height: 100%;
  min-width: 100px;
`;
