import React, { useContext } from "react";

import TabItem from "./TabItem";
import { SettingsContext } from "../../context";
import { MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE } from "../../../../utils/constants";

import { List } from "./styles";

function TabList() {
  const {
    sidebarTabs,
    setActiveTab,
    setStyledTab,
    fetchIntegrations,
    styledTab,
  } = useContext(SettingsContext);

  const handleTabClick = (key) => {
    if (Object.keys(sidebarTabs).includes(key)) {
      setStyledTab(key);

      if (MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE.includes(key)) {
        if (key === "integrations") {
          fetchIntegrations();
        }
        setActiveTab(Object.keys(sidebarTabs[key].tabs)[0]);
      } else {
        setActiveTab(key);
      }

      return;
    }

    const topKey = MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE.find((tk) =>
      Object.keys(sidebarTabs[tk]?.tabs || {}).includes(key)
    );
    if (topKey === "integrations") {
      fetchIntegrations();
    }
    setStyledTab(topKey);
    setActiveTab(key);
  };

  return (
    <List>
      {Object.keys(sidebarTabs).map((tabName) => (
        <TabItem
          key={tabName}
          tabName={tabName}
          title={sidebarTabs[tabName].title}
          isActive={styledTab === tabName}
          handleClick={handleTabClick}
        />
      ))}
    </List>
  );
}

export default TabList;
