import React from "react";

export default function ReceiveSquareIcon({ $toRight }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2764 15.2109L22.2764 9.21094C22.2764 4.21094 20.2764 2.21094 15.2764 2.21094L9.27637 2.21094C4.27637 2.21094 2.27637 4.21094 2.27637 9.21094L2.27637 15.2109C2.27637 20.2109 4.27637 22.2109 9.27637 22.2109L15.2764 22.2109C20.2764 22.2109 22.2764 20.2109 22.2764 15.2109Z"
        fill={$toRight ? "#093660" : "#494949"}
      />
      <path
        d="M11.7861 15.2109L14.7861 12.2109L11.7861 9.21094"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7861 12.2109L6.78613 12.2109"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7861 18.2109C18.0861 14.3209 18.0861 10.1009 16.7861 6.21094"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
