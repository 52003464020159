import React from "react";

import OnboardingContainer from "./OnboardingContainer";
import getUserFromLocalStorage from "../../utils/getUserFromLocalStorage";
import { isUserInFirstTimeCampaignOnboardingUserList } from "../../utils/utils";

function FirstTimeCampaignOnboarding({ config }) {
  const { id: userId } = getUserFromLocalStorage() || {};
  const { name } = config;
  const isUserExist = isUserInFirstTimeCampaignOnboardingUserList({
    userId,
    name,
  });

  return (
    !isUserExist && (
      <OnboardingContainer
        config={{ ...config, userId }}
        isShownOnboarding={!isUserExist}
      />
    )
  );
}

export default FirstTimeCampaignOnboarding;
