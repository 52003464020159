/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import StepButton from "../../StepButton";
import {
  validateCampaignGoal,
  getFilters,
  generateCampaignPreview,
} from "../../../services/campaigns";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { CAMPAIGN_TYPES, GOALS, VALIDATE_GOAL_GPT_ANSWERS } from "../constants";
import ValidateGoalPopup from "./ValidateGoalPopup";
import { isBullhornMarketingCampaignType } from "../utils";
import { FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS } from "../../../utils/constants";
import Loader from "../../Loader";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";
import {
  ButtonGroup,
  InputTextarea,
  InputTextareaBlock,
  OFFSET_LEFT,
  OFFSET_LEFT_BTN_GR,
  Section,
} from "../styles";
import SaveDraftButton from "./SaveDraftButton";
import {
  isUserInFirstTimeCampaignType,
  updateFirstTimeCampaignTypeForUser,
} from "../../../utils/utils";
import { PrimaryButton, SecondaryButton } from "../../styles";

const QUESTIONS = [
  "Can you tell us about your product or service?",
  "What do you want to tell your contacts with this message?",
  "Is there anything specific you’d like to use to personalize the messages?",
];

function CampaignDetails({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
}) {
  const previewTextType = CAMPAIGN_TYPES[campaign.crmType]?.includes(
    campaign.type
  )
    ? campaign.type
    : "other";

  const parentRef = useRef(null);
  const detailsRef = useRef(null);

  const [details, setDetails] = useState(campaign.details);
  const [validateGoalGPTAnswer, setValidateGoalGPTAnswer] = useState("");
  const [isValidateGoalPopupOpened, setIsValidateGoalPopupOpened] =
    useState(false);
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const [isPreviewTextLoading, setIsPreviewTextLoading] = useState(
    !campaign.detailsPreview?.options
  );
  const [previewText, setPreviewText] = useState(
    campaign.detailsPreview?.options?.[previewTextType]?.replace(/\\n/g, "")
  );
  const [isOpenedHint, setIsOpenedHint] = useState(
    !isUserInFirstTimeCampaignType(previewTextType)
  );

  const [isQuestionsMode, setIsQuestionsMode] = useState(false);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState(
    QUESTIONS.map((question) => ({ question, answer: "" }))
  );
  const [isGenerating, setIsGenerating] = useState(false);

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.campaignDetails,
    parentRef,
  };

  useEffect(() => {
    if (isPreviewTextLoading) {
      const getConfig = async () => {
        const filtersData = await getFilters({
          filter: ["campaignDetails"],
        });
        const options = filtersData.result?.campaignDetails;
        setPreviewText(options?.[previewTextType]?.replace(/\\n/g, ""));
        setCampaign((campaign) => ({
          ...campaign,
          detailsPreview: { options },
        }));
        setIsPreviewTextLoading(false);
      };
      getConfig();
    }
  }, [isPreviewTextLoading]);

  const isChanged = () => {
    return campaign.details !== details;
  };

  const applyCampaignChanges = (asDraft) => {
    setCampaign((campaign) => ({
      ...campaign,
      details,
      jobDescriptionChanged: isChanged(),
      asDraft,
    }));
  };

  const handleClickBack = (event) => {
    event.preventDefault();
    applyCampaignChanges();
    handleDecreaseStep();
  };

  const handleClickSaveDraft = (event) => {
    event.preventDefault();
    applyCampaignChanges(true);
  };

  const changeGoalDescription = () => {
    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.emailReply) {
      return 'It looks like you\'d like your recipients to simply reply to your message.  Changing your campaign goal to "Any email reply" will allow Reach to better track goal completions.';
    }

    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link) {
      return 'It looks like you\'d like your recipients to click on a link.  Changing your campaign goal to "Get contact to visit a link" will allow Reach to automatically track successful clicks.';
    }

    return "";
  };

  const confirmChangeGoal = (link) => {
    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link) {
      setCampaign((campaign) => ({
        ...campaign,
        goal: GOALS.visitLink,
        link,
        isCustomGoal: false,
      }));

      handleIncreaseStep();
      return;
    }

    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.emailReply) {
      setCampaign((campaign) => ({
        ...campaign,
        goal: GOALS.emailReply,
        isCustomGoal: false,
      }));

      handleIncreaseStep();
      return;
    }
  };

  const cancelChangeGoal = () => {
    handleIncreaseStep();
  };

  const handleClickNext = async (event) => {
    event.preventDefault();

    if (details === "") {
      return;
    }

    setCampaign((campaign) => ({
      ...campaign,
      details,
      jobDescriptionChanged: isChanged(),
    }));

    if (!campaign.isCustomGoal) {
      handleIncreaseStep();
      return;
    }

    setIsValidationLoading(true);

    const data = {
      crm: campaign.crmType,
      goal: campaign.goal,
      campaign_type: campaign.type,
      campaign_details: details,
    };
    const response = await validateCampaignGoal(data);

    if (!response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });
      handleIncreaseStep();
      return;
    }

    if (
      Object.values(VALIDATE_GOAL_GPT_ANSWERS).includes(response.result.result)
    ) {
      if (response.result.result === VALIDATE_GOAL_GPT_ANSWERS.custom) {
        handleIncreaseStep();
        return;
      }
      setValidateGoalGPTAnswer(response.result.result);
      setIsValidateGoalPopupOpened(true);
      setIsValidationLoading(false);
    } else {
      handleIncreaseStep();
    }
  };

  const handleChange = (value, delta, source, editor) => {
    const text = editor.getText();
    if (!text?.replace(/\r?\n/g, "").trim()) {
      setDetails("");
      return;
    }

    setDetails(value);
  };

  const quillSettings = {
    theme: "snow",
    modules: {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        [{ align: [] }],
        [{ color: [] }],
        [
          "bold",
          "italic",
          "underline",
          "strike",
          "clean",
          { list: "bullet" },
          { list: "ordered" },
          "link",
          "code-block",
          "blockquote",
        ],
      ],
    },
  };

  const handleChangeAnswer = (e, idx, isBlur) => {
    const val = e.target.value;
    const items = [...questionsAndAnswers];
    items[idx].answer = isBlur ? val.trim() : val;
    setQuestionsAndAnswers(items);
  };

  const handleGenerateNow = async () => {
    const questions = questionsAndAnswers
      .map((item) => ({
        ...item,
        answer: item.answer.trim(),
      }))
      .filter((item) => item.answer);

    setIsGenerating(true);
    setIsQuestionsMode(false);

    const res = await generateCampaignPreview({
      questions,
      crm: campaign.crmType,
    });

    setIsGenerating(false);

    if (res.success !== true) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.isMoneyInsufficient
            ? "You do not have enough credits to perform this operation"
            : "Unfortunately, we had trouble generating preview",
        },
      });
      return;
    }

    setDetails(res.result.result);
  };

  return isPreviewTextLoading ? (
    <Loader text="Loading preview..." />
  ) : (
    <>
      <Section ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <Title>Campaign Details</Title>
        <InputBlock>
          <Label>
            {isBullhornMarketingCampaignType(campaign.type)
              ? "Enter a detailed description of the marketing campaign.  This will be the basis of the customized communications received by each candidate"
              : "Enter a detailed description of your campaign"}
          </Label>
          {isQuestionsMode ? (
            <QuestionsContainer>
              {questionsAndAnswers.map((item, idx) => (
                <QuestionBlock key={idx}>
                  <QuestionLabel>{item.question}</QuestionLabel>
                  <AnswerArea
                    value={item.answer}
                    onChange={(e) => handleChangeAnswer(e, idx)}
                    onBlur={(e) => handleChangeAnswer(e, idx, true)}
                    autoComplete="off"
                  />
                </QuestionBlock>
              ))}
              <QuestionButtons>
                <PrimaryButton
                  $padding="0 22px"
                  onClick={handleGenerateNow}
                  disabled={questionsAndAnswers.some(
                    (item) => !item.answer.trim()
                  )}
                >
                  Generate Now
                </PrimaryButton>
                <SecondaryButton
                  $padding="0 22px"
                  onClick={() => setIsQuestionsMode(false)}
                >
                  Return to Details
                </SecondaryButton>
              </QuestionButtons>
            </QuestionsContainer>
          ) : (
            <>
              {isGenerating && <Loader text="Generating..." zIndex={11} />}
              <GenerateBtnBlock>
                <PrimaryButton
                  $padding="0 31px"
                  onClick={() => setIsQuestionsMode(true)}
                >
                  Generate this for me
                </PrimaryButton>
                or, continue writing on your own...
              </GenerateBtnBlock>
              <InputTextareaBlock
                $haxHint={!!previewText}
                $isOpenedHint={isOpenedHint}
              >
                <InputTextarea
                  ref={detailsRef}
                  id="details"
                  placeholder={previewText || "Campaign Details"}
                  value={details}
                  onChange={handleChange}
                  onFocus={() => {
                    if (previewText && isOpenedHint) {
                      updateFirstTimeCampaignTypeForUser(previewTextType);
                      setIsOpenedHint(false);
                    }
                  }}
                  {...quillSettings}
                />
                {!!previewText && !isOpenedHint && !details && (
                  <ShowHintButton
                    title="View Hint"
                    onClick={(event) => {
                      event?.preventDefault();
                      event?.stopPropagation();
                      setIsOpenedHint(true);
                      try {
                        detailsRef?.current?.blur();
                      } catch {
                        //
                      }
                    }}
                  />
                )}
              </InputTextareaBlock>
            </>
          )}
        </InputBlock>
      </Section>
      <ButtonGroup>
        <StepButton onClick={handleClickBack} isNext={false} />
        <StepButton
          onClick={handleClickNext}
          disabled={details === "" || isValidationLoading}
          isNext={true}
        />
        <SaveDraftButton
          onClick={handleClickSaveDraft}
          disabled={isValidationLoading}
        />
      </ButtonGroup>
      {isValidateGoalPopupOpened && (
        <ValidateGoalPopup
          title="There’s something else you can do here!"
          description={changeGoalDescription()}
          showLinkInput={
            validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link
          }
          onConfirm={confirmChangeGoal}
          onCancel={cancelChangeGoal}
        />
      )}
    </>
  );
}

export default CampaignDetails;

const Title = styled.div`
  padding: 19px ${OFFSET_LEFT} 0;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
`;

const InputBlock = styled.div`
  padding: 0 ${OFFSET_LEFT_BTN_GR};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Label = styled.label`
  margin: 0 calc(${OFFSET_LEFT} - ${OFFSET_LEFT_BTN_GR});
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
`;

const ShowHintButton = styled.img.attrs({
  src: "/images/hint.svg",
  alt: "hint",
})`
  position: absolute;
  right: 12px;
  top: 57px;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

const GenerateBtnBlock = styled.div`
  margin: 10px 0 -1px;
  display: flex;
  align-items: center;
  column-gap: 26px;
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
`;

const QuestionsContainer = styled.div`
  margin: 25px calc(${OFFSET_LEFT} - ${OFFSET_LEFT_BTN_GR}) 0;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  width: 74%;
  flex-grow: 1;
  // max-height: calc(100% - 61px);
  // overflow: auto;
`;

const QuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

const QuestionLabel = styled.div`
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const AnswerArea = styled.textarea`
  &,
  &:focus-visible {
    margin: 0;
    padding: 7px 10px;
    width: 100%;
    min-height: 100px;
    outline: none;
    resize: none;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #00000040 inset;
    color: #000000;
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
  }
`;

const QuestionButtons = styled.div`
  margin-top: -1px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;
