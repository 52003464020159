import React from "react";

export default function ResumeIcon({ fill, opacity }) {
  return (
    <svg
      width="33"
      height="37"
      viewBox="0 0 33 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity || 1}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2376 7.94685C32.1713 8.92668 32.6886 10.2399 32.6886 11.6394L32.6887 16.5816C32.6887 17.5388 31.9459 18.3236 31.0401 18.3236C30.1342 18.3236 29.3915 17.5388 29.3915 16.5816V11.6394C29.3915 11.1744 29.2219 10.7389 28.9085 10.4078L23.6987 4.89848C23.3896 4.57413 22.9668 4.38815 22.5331 4.38815H6.86069C5.34307 4.38815 4.11307 5.6878 4.11307 7.29136V16.5816C4.11307 17.5388 3.37033 18.3236 2.46449 18.3236C1.55866 18.3236 0.815918 17.5388 0.815918 16.5816V7.29136C0.815918 3.76669 3.52491 0.904297 6.86069 0.904297H22.5331C23.8512 0.904297 25.094 1.45091 26.0278 2.43754L31.2376 7.94685ZM13.455 35.1624C13.455 37.485 10.1578 37.485 10.1578 35.1624C10.1578 32.8398 13.455 32.8398 13.455 35.1624ZM4.11307 25.8721C4.11307 28.1947 0.815918 28.1947 0.815918 25.8721C0.815918 23.5496 4.11307 23.5496 4.11307 25.8721ZM0.815918 35.1624C0.815918 32.8398 4.11307 32.8398 4.11307 35.1624C4.11307 37.485 0.815918 37.485 0.815918 35.1624ZM23.3461 35.1624C23.3461 37.485 20.049 37.485 20.049 35.1624C20.049 32.8398 23.3461 32.8398 23.3461 35.1624ZM32.6886 35.1624C32.6886 37.485 29.3914 37.485 29.3914 35.1624C29.3914 32.8398 32.6886 32.8398 32.6886 35.1624ZM32.6886 25.8721C32.6886 28.1947 29.3914 28.1947 29.3914 25.8721C29.3914 23.5496 32.6886 23.5496 32.6886 25.8721Z"
        fill={fill || "white"}
      />
    </svg>
  );
}
