import React from "react";

import CreateCampaignBlock from "./CreateCampaignBlock";
import { Content, Message, TopBlock, TopTitle, Wrapper } from "./styles";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";

function NoAnyCampaignsBlock({ attemptToCreateCampaign }) {
  const user = getUserFromLocalStorage();
  const userName = user?.name || "";

  return (
    <Content>
      <TopBlock>
        <TopTitle>Hola, {userName}</TopTitle>
        <Message>
          You’re just a few clicks away from generating completely personalized
          messages. Begin by creating your first campaign
        </Message>
      </TopBlock>
      <Wrapper>
        <CreateCampaignBlock
          attemptToCreateCampaign={attemptToCreateCampaign}
        />
      </Wrapper>
    </Content>
  );
}

export default NoAnyCampaignsBlock;
