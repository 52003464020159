import React, { useState } from "react";
import styled from "styled-components";
import {
  Divider,
  ErrorIcon,
  ErrorMessage,
  Input,
  InputContent,
  OFFSET_LEFT,
  Relative,
} from "../styles";
import { PrimaryButton, SecondaryButton } from "../../styles";

function DefineNewType(props) {
  const { label, isOpened, onOpen, onClose, onCreate, onInput, errorMessage } =
    props;
  const hasError = !!errorMessage;

  const [newType, setNewType] = useState("");

  const handleChange = (value) => {
    setNewType(value);
    onInput(value);
  };

  const handleCancel = () => {
    handleChange("");
    onClose();
  };

  const handleCreate = () => {
    onCreate(newType);
    setNewType("");
  };
  return (
    <>
      <Divider />
      {isOpened ? (
        <CustomInputContent>
          <Label>{`Please enter a new ${label}`}</Label>
          <Relative>
            <Input
              type="text"
              placeholder={label}
              value={newType}
              onInput={(event) => handleChange(event.target.value)}
              onBlur={(event) => handleChange(event.target.value.trim())}
            />
            <img
              src="/images/divider-vert.svg"
              alt="divider-vert"
              className="divider-vert"
            />
            {hasError && <ErrorIcon />}
          </Relative>
          {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <ButtonGroup>
            <PrimaryButton
              $small={true}
              onClick={handleCreate}
              disabled={!newType.trim()}
            >
              Create
            </PrimaryButton>
            <SecondaryButton $small={true} onClick={handleCancel}>
              Cancel
            </SecondaryButton>
          </ButtonGroup>
        </CustomInputContent>
      ) : (
        <DefineNewButton onClick={onOpen}>
          <img src="/images/circle-plus.svg" alt="" />
          {`Define a New ${label}`}
        </DefineNewButton>
      )}
    </>
  );
}

export default DefineNewType;

const DefineNewButton = styled.div`
  margin: 0 ${OFFSET_LEFT} 20px;
  color: ${({ theme }) => theme.colors.blueLight};
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.025em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 21px;
  cursor: pointer;

  img {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
    margin: 1px;
  }

  &:hover {
    opacity: 0.85;
  }
`;

const CustomInputContent = styled(InputContent)`
  margin: 0 ${OFFSET_LEFT} 20px;
  padding: 11px 14px 15px 16px;
  width: 41%;
  @media (max-width: 1000px) {
    width: 100%;
  }
  background: #f2f1f1;
  border-radius: 5px;
`;

const Label = styled.div`
  margin-bottom: 11px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #5a5a5a;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 13px;
  width: 100%;
  margin: 12px 0 0;
`;
