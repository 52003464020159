import styled from "styled-components";

export const Container = styled.div`
  width: 87.5%;
  background-color: #fff;
  padding: ${({ $activeTab }) =>
    $activeTab === "account" ||
    $activeTab === "emailSignature" ||
    $activeTab === "usage" ||
    $activeTab === "myTeam" ||
    $activeTab === "unsubscribe"
      ? "0"
      : "43px 0 43px 63px"};
  overflow: auto;
  border-top-right-radius: 10px;
`;
