import React, { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import { pickBy, identity, isEmpty } from "lodash";

const CandidateTableFilters = ({ load, relevantStatuses }) => {
  const useCloseByClickOutside = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpenFilters(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const emptyFilters = { emailStatus: "" };
  const [filters, setFilters] = useState(emptyFilters);
  const [appliedFilters, setAppliedFilters] = useState({});
  const wrapperRef = useRef(null);

  useCloseByClickOutside(wrapperRef);

  const handleChangeFiltersForm = (e, field, value) => {
    e.preventDefault();
    setFilters({ [field]: value });
  };

  const handleApplyFilters = (e) => {
    e.preventDefault();
    loadWithFilters(filters);
    setIsOpenFilters(false);
  };

  const handleResetFilters = (e) => {
    e.preventDefault();
    setFilters(emptyFilters);
    loadWithFilters(emptyFilters);
  };

  const loadWithFilters = (filter) => {
    const pureFilters = pickBy(filter, identity);
    const newAppliedFilters = isEmpty(pureFilters) ? {} : pureFilters;
    setAppliedFilters(newAppliedFilters);
    load(newAppliedFilters);
  };

  useEffect(() => {
    if (!relevantStatuses.includes(appliedFilters.emailStatus)) {
      setFilters(emptyFilters);
      loadWithFilters(emptyFilters);
    }
  }, [relevantStatuses]);

  return (
    <Filters ref={wrapperRef}>
      <FilterIcon
        onClick={() => setIsOpenFilters(!isOpenFilters)}
        $number={Object.values(appliedFilters).filter(Boolean).length}
      />
      {isOpenFilters && (
        <FiltersBody>
          <FiltersContent>
            <Label>Filter</Label>
            <FiltersContentBox>
              <SubLabel>Status</SubLabel>
              <div>
                {relevantStatuses.map((status, index) => (
                  <SelectableButton
                    key={index}
                    className={filters.emailStatus === status && "selected"}
                    onClick={(e) =>
                      handleChangeFiltersForm(e, "emailStatus", status)
                    }
                  >
                    {status}
                  </SelectableButton>
                ))}
              </div>
            </FiltersContentBox>
          </FiltersContent>
          <FiltersActions>
            <ApplyButton onClick={handleApplyFilters}>Apply</ApplyButton>
            <ResetFiltersButton onClick={handleResetFilters}>
              Reset Filters
            </ResetFiltersButton>
          </FiltersActions>
        </FiltersBody>
      )}
    </Filters>
  );
};

export default CandidateTableFilters;

const Filters = styled.div`
  position: relative;
`;

const FiltersBody = styled.div`
  width: 208px;
  height: 338px;
  position: absolute;
  box-shadow: 0px 4px 50px 0px #00000059;
  z-index: 20;
  background: #fff;
  left: -188px;
  right: 0px;
  top: 30px;
  border-radius: 6px;

  display: flex;
  flex-direction: column;

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-bottom: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    left: 189px;
    top: -7px;
  }
`;

const FilterIcon = styled.div`
  cursor: pointer;
  background-image: ${({ $number }) =>
    `url("/images/filter${$number ? "-selected" : ""}.svg");`};
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 18px;
  width: 18px;
  height: 18px;
  position: relative;
  ${({ $number }) =>
    $number
      ? `&:after {
          content: '${$number}';
          position: absolute;
          top: -7px;
          right: -7px;
          color: #fff;
          background-color: #f95959;
          box-shadow: 0 0 0 2px #fff;
          border-radius: 50%;
          text-align: center;
          width: 16px;
          height: 16px;
          line-height: 16px;
          font-size: 10px;
          font-weight: 700;
        }`
      : ""};
`;

const FiltersContent = styled.div`
  flex: 1 0 auto;
  padding: 10px;
`;

const FiltersContentBox = styled.div`
  margin-bottom: 10px;
`;

const FiltersActions = styled.div`
  width: 100%;
  height: 60px;
  background: ${({ theme }) => theme.colors.background_color};
  flex-shrink: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 10px;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 9px;
`;

const SubLabel = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #a8adb7;
`;

const SelectableButton = styled.button`
  cursor: pointer;
  color: #c3c3c3;
  border: 1px solid #e3e3e3;
  background: inherit;
  padding: 5px;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 5px;

  &.selected {
    background: #f95959;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    border-radius: 3px;
  }
`;

const ApplyButton = styled.button`
  cursor: pointer;
  width: 94px;
  height: 40px;
  background: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 6px;
  margin-right: 15px;
`;

const ResetFiltersButton = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue};
  border: none;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
`;
