import { styled } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background-image: url(/images/ranking.svg);
  background-repeat: no-repeat;
  background-size: 24px;

  ${({ $isCollapsed }) =>
    $isCollapsed
      ? `    
        background-position: center 12px;
        padding: 45px 0 0;
    `
      : `    
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        background-color: #f4f4f4;
        background-position: calc(100% - 21px) 12px;
        padding: 13px 22px 21px 19px;
    `}
`;

export const Loader = styled.div`
  position: absolute;
  top: calc((100% - 8px) / 2);
  left: ${({ $isCollapsed }) => ($isCollapsed ? "0" : "12px")};
  min-width: 100%;
  font-size: 28px;
  color: ${({ $isCollapsed }) => ($isCollapsed ? "#494949" : "#093660")};

  ${({ $isCollapsed }) =>
    $isCollapsed
      ? `    
        display: flex;
        align-items: center;
        justify-content: center;
    `
      : ""}

  .dot-flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #c23675;
    color: #c23675;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #c23675;
    color: #c23675;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #c23675;
    color: #c23675;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #c23675;
    }
    50%,
    100% {
      background-color: #c2367533;
    }
  }
`;

export const Credits = styled.div`
  position: relative;
  height: fit-content;
  ${({ $isCollapsed }) =>
    $isCollapsed
      ? `    
        display: flex;
        align-items: center;
        justify-content: center;
    `
      : `    
        display: flex;
        align-items: center;
        justify-content: flex-start;
    `}
`;

export const CreditsLabel = styled.div`
  padding-right: 35px;
  color: #4e4e4e;
  transform: rotate(-90deg);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EstValueText = styled.div`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: 1;
  color: ${({ $isCollapsed }) => ($isCollapsed ? "#4e4e4e" : "#000000")};
  ${({ $isLoading }) => ($isLoading ? "visibility: hidden;" : "")};
`;

export const EstValueSvg = styled.svg`
  @keyframes showEstValueSvg {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: showEstValueSvg 0.3s ease-in;

  ${({ $isLoading }) => ($isLoading ? "visibility: hidden;" : "")};
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 1;
  }
  text {
    text-anchor: middle;
    dominant-baseline: central;
    fill: #4e4e4e;
  }
`;

export const EstTitle = styled.div`
  margin: 6px 0 7px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

export const Description = styled.div`
  margin: 0 0 17px;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #000000;
  opacity: 0.7;
`;

export const Info = styled.div`
  width: max-content;
  margin-left: auto;
  color: #000000;
  font-size: 12px;
`;

export const MoreInfoLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: ${({ theme }) => theme.colors.blueLight};
  cursor: pointer;
  ${({ $disabled }) => ($disabled ? "pointer-events: none;" : "")}
`;

export const InfoTooltip = styled.div`
  position: absolute;
  width: 100%;
  bottom: unset !important;
  left: unset !important;
  right: 0 !important;
  top: calc(100% - 11px) !important;
  z-index: 99999;

  > .react-tooltip {
    width: 100%;
    padding: 7px 22px 2px 19px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    box-shadow: 0px 18px 40px 0px #00000033;
    opacity: 1;

    &.react-tooltip__place-bottom {
      .react-tooltip-arrow {
        width: 15px;
        height: 15px;
        top: -8px !important;
        left: 51px !important;
        right: unset !important;
        bottom: unset !important;
        border-left: 1px solid #dfdfdf;
        border-top: 1px solid #dfdfdf;
        border-top-left-radius: 6px;
        transform: rotate(45deg);
      }
    }
  }
`;

export const CreditsAllocatedValue = styled.div`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 26px;
  font-weight: 800;
  line-height: 34.84px;
  color: #000000;
`;

export const CreditsAllocatedText = styled.div`
  margin-top: -5px;
  margin-bottom: 1px;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.4px;
  color: #959595;
`;

export const CreditsUsedBar = styled.div`
  margin-top: 7px;
  height: 6.13px;
  width: 100%;
  border-radius: 19px;
  background: #d9d9d9;

  div {
    height: 100%;
    width: ${({ $value }) => `${$value}%;`};
    border-radius: 19px;
    background: #4f4f4f;
  }
`;

export const CreditsUsedText = styled.div`
  margin-top: 9px;
  margin-bottom: ${({ $withSubscription }) =>
    $withSubscription ? "10px" : "12px"};
  font-size: 9px;
  font-weight: 400;
  line-height: 10.8px;
  color: #959595;
`;

export const SubscriptionPlan = styled.span`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 9px;
  font-weight: 700;
  line-height: 10.8px;
  color: #000000;
`;

export const UpgradePlanContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 13px 0 4px;
  width: 100%;
  height: 53.13px;
  border-radius: 0 0 6px 6px;
`;

export const UpgradePlanBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 32px;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  color: #ffffff;
  background: #f95959;
  border: 1px solid #f95959;
  border-radius: 6px;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;
