import axios from "axios";
import { apiUrl } from "../utils/config";
import { sendMessageToExtension } from "../utils/postToExtension";
import { fetchRefreshToken } from "../services/queries";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "./localStorage";

const urlsWithoutAuth = [
  "/login",
  "/recover/start",
  "/recover/confirm",
  "/register",
  "/register/check-invite-code",
  "/register/dictionaries",
  "/contact-us",
  "/refresh-token",
  "/get-oauth-user-data",
];

const api = axios.create({
  baseURL: apiUrl,
});

api.interceptors.request.use(
  (config) => {
    if (urlsWithoutAuth.includes(config.url)) {
      return config;
    }
    const accessToken = getAccessToken();
    if (!accessToken) {
      console.log(
        "Need to log in.  Access token missing (request interceptor)."
      );
      sendMessageToExtension({
        message: "need-log-in",
      });
      return;
    }
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    }
    if (response.status === 302) {
      const newUrl = response.headers.get("Location");
      window.location.href = newUrl;
    }
    return Promise.resolve(response);
  },
  async (error) => {
    const isForbidden = error.response?.status === 403;
    if (isForbidden && error.response?.data?.unverified) {
      console.log("Need to verify email");
      sendMessageToExtension({
        message: "need-verify",
      });
      return Promise.reject(error);
    }

    if (
      (isForbidden && !urlsWithoutAuth.includes(error.response?.config?.url)) ||
      error.response?.status === 401
    ) {
      await refreshAccessToken();

      const originalRequestConfig = error.response?.config;
      const token = getAccessToken();
      if (!token || !originalRequestConfig) {
        console.log(
          "Need to log in.  !token || !originalRequestConfig (response interceptor)."
        );
        sendMessageToExtension({
          message: "need-log-in",
        });
        return;
      }
      originalRequestConfig.headers.Authorization = `Bearer ${token}`;
      return await axios.request(originalRequestConfig);
    }
    if (
      error.response?.status === 403 &&
      error.response?.config?.url === "/refresh-token"
    ) {
      console.log("Need to log in.  403 in response.");
      sendMessageToExtension({
        message: "need-log-in",
      });
      return;
    }
    return Promise.reject(error);
  }
);

async function refreshAccessToken() {
  try {
    const token = getRefreshToken();

    if (!token) {
      console.log(
        "Need to log in.  Unable to find refresh token in local storage."
      );
      sendMessageToExtension({
        message: "need-log-in",
      });
      return;
    }
    const response = await fetchRefreshToken(token);

    saveDataToLocalStorage("accessToken", response.token);
    saveDataToLocalStorage("refreshToken", response.refreshToken);

    sendMessageToExtension({
      message: "refreshed-token",
      data: {
        accessToken: response.token,
        refreshToken: response.refreshToken,
      },
    });

    return { accessToken: response.token, refreshToken: response.refreshToken };
  } catch (error) {
    console.log("Need to log in.  Unable to refresh access token.");
    sendMessageToExtension({
      message: "need-log-in",
    });
    throw new Error("Failed to refresh tokens");
  }
}

export const getRefreshToken = () => {
  return getDataFromLocalStorage("refreshToken");
};

export const getAccessToken = () => {
  return getDataFromLocalStorage("accessToken");
};

export { api as default, refreshAccessToken };
