import React, { useCallback, useState } from "react";
import { styled } from "styled-components";
import { MultiSelect } from "react-multi-select-component";
import {
  CRM_OPTIONS,
  DEFAULT_VALUE_FOR_MODEL,
  DEFAULT_VALUE_FOR_PROMPT_COMPANY,
  USER_ROLES,
} from "../../../utils/constants";

import ConnectBtn from "../../ConnectBtn";
import PromptList from "./Prompts/PromptList";
import CustomSelect from "../../CustomSelect";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";

const PromptFormPopup = ({
  typesOptions,
  promptsTypes,
  modelsOptions,
  companies,
  prompt,
  handleClose,
  onSave,
}) => {
  console.log(prompt);
  const [title, setTitle] = useState(prompt.title || "");
  const [model, setModel] = useState(prompt.defaultModelId);
  const [sequence, setSequence] = useState(prompt.sequence);
  const [type, setType] = useState(prompt.type);
  const [crm, setCRM] = useState(prompt.crm);
  const [companyId, setCompanyId] = useState(prompt.companyId);
  const userInfo = getUserFromLocalStorage();

  const isAdmin = userInfo.role === USER_ROLES.ADMIN;

  const companyOptions = [
    {
      value: DEFAULT_VALUE_FOR_PROMPT_COMPANY,
      label: DEFAULT_VALUE_FOR_PROMPT_COMPANY,
    },
    ...companies,
  ];

  const onSelectCompany = (newCompany) => {
    setCompanyId(
      newCompany && newCompany !== DEFAULT_VALUE_FOR_PROMPT_COMPANY
        ? newCompany
        : ""
    );
  };

  const selectedCrmOption = () => {
    return CRM_OPTIONS.filter((option) => crm?.includes(option.value));
  };

  const onSelectType = (newType) => {
    setType(newType);
  };

  const onSelectModel = (newModel) => {
    setModel(newModel && newModel !== DEFAULT_VALUE_FOR_MODEL ? newModel : "");
  };

  const onSelectCRM = (newCRM) => {
    if (newCRM.length > 1) {
      newCRM = newCRM.filter(({ value }) => !crm.includes(value));
    }
    setCRM(newCRM.map((currentCrm) => currentCrm.value));
  };

  const [titleErrorMessage, setTitleErrorMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const handleUpdatePrompt = async (e) => {
    e.preventDefault();

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setTitleErrorMessage("");

    onSave({
      id: prompt.id,
      type,
      crm,
      companyId,
      title,
      defaultModelId: model,
      sequence,
    });
  };

  const isFormValid = () => {
    let isTitleValid = true;
    let isTemplateChanged = false;

    if (!title || title === "") {
      setTitleErrorMessage("Please enter prompt title");
      isTitleValid = false;
    }

    if (!sequence?.length !== prompt.sequence) {
      isTemplateChanged = true;
    }

    if (isTemplateChanged === false && sequence?.length && prompt.sequence) {
      for (let i = 0; i < sequence?.length; i++) {
        if (sequence[i].role !== prompt.sequence[i].role) {
          isTemplateChanged = false;
          break;
        }

        if (sequence[i].content !== prompt.sequence[i].content) {
          isTemplateChanged = false;
          break;
        }
      }
    }

    if (title && title === prompt.title && !isTemplateChanged) {
      setErrorMessage("No changes made");
      return false;
    }

    if (!isTitleValid) {
      return false;
    }

    return true;
  };

  const onChangeList = useCallback((items) => {
    setSequence(items);
  }, []);

  return (
    <UpdatePromptContainer>
      <Content>
        <CloseIcon className="close-icon" onClick={handleClose}>
          x
        </CloseIcon>

        <Form onSubmit={handleUpdatePrompt}>
          <Inputs>
            <InputBlock>
              <Label htmlFor="title">Title</Label>
              <Relative>
                <Input
                  id="title"
                  placeholder="Prompt title..."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onInput={(e) => setTitleErrorMessage("")}
                  autoComplete="off"
                />
                {titleErrorMessage && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {titleErrorMessage && (
                <ErrorMessage>{titleErrorMessage}</ErrorMessage>
              )}
              {isAdmin && (
                <>
                  <Label htmlFor="modelDropdown">Model</Label>
                  <CustomSelect
                    options={modelsOptions}
                    title="Model"
                    selected={model || DEFAULT_VALUE_FOR_MODEL}
                    onSelect={onSelectModel}
                    id="modelDropdown"
                  />
                  <Label htmlFor="companyDropdown">Company</Label>
                  <CustomSelect
                    id="companyDropdown"
                    options={companyOptions}
                    title="Company"
                    selected={companyId || DEFAULT_VALUE_FOR_PROMPT_COMPANY}
                    onSelect={onSelectCompany}
                  />
                </>
              )}
              <Label htmlFor="email">Type</Label>
              <CustomSelect
                options={typesOptions}
                title="Type"
                selected={type}
                onSelect={onSelectType}
              />
              <Label htmlFor="email">CRM</Label>
              <MultiSelect
                options={CRM_OPTIONS}
                value={selectedCrmOption()}
                onChange={onSelectCRM}
                labelledBy="CRM"
                hasSelectAll={false}
                disableSearch={true}
                closeOnChangedValue={true}
                display="chip"
              />
            </InputBlock>
            <PromptList
              items={prompt.sequence}
              type={type}
              crm={crm}
              promptsTypes={promptsTypes}
              onChange={onChangeList}
            />

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </Inputs>

          <Actions>
            <ConnectBtn type="submit" label={prompt.id ? "Update" : "Create"} />
          </Actions>
        </Form>
      </Content>
    </UpdatePromptContainer>
  );
};

export default PromptFormPopup;

const UpdatePromptContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;
const Content = styled.div`
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
`;

const CloseIcon = styled.span`
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;
