import styled from "styled-components";

export const PlansListContainer = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1160px) {
    flex-direction: column;
  }
`;
