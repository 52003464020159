import React, { useContext } from "react";

import SubTabItem from "./SubTabItem";
import { SettingsContext } from "../../../context";

import { SubList } from "./styles";

function SubTabList({ tabName, handleClick, activeTab }) {
  const { sidebarTabs } = useContext(SettingsContext);

  return (
    <SubList>
      {Object.keys(sidebarTabs[tabName].tabs).map((subTabName) => (
        <SubTabItem
          key={subTabName}
          tabName={tabName}
          subTabName={subTabName}
          handleClick={handleClick}
          title={sidebarTabs[tabName].tabs[subTabName].title}
          isActive={activeTab === subTabName}
        />
      ))}
    </SubList>
  );
}

export default SubTabList;
