import React, { useRef, useState, useEffect } from "react";
import { styled } from "styled-components";
import PromptSelect from "../../../PromptSelect";

const SampleListItem = ({ index, promptsTokens, item, onDelete, onChange }) => {
  const timeoutRef = useRef(null);

  const [promptTokensOptions, setPromptTokensOptions] = useState([]);

  useEffect(() => {
    if (!item) {
      return;
    }

    setText(item.text);
    setToken(item.title);
  }, [item]);

  useEffect(() => {
    let combinedTokens = [];

    for (let key in promptsTokens) {
      combinedTokens = combinedTokens.concat(promptsTokens[key]);
    }

    const tokensOptions = combinedTokens.map((token) => ({
      label: token,
      value: token,
    }));

    setPromptTokensOptions(tokensOptions);
  }, [promptsTokens]);

  const [text, setText] = useState("");

  const [token, setToken] = useState("");

  const onSelect = (token) => {
    setToken(token);
    onChange(index, {
      title: token,
      text,
    });
  };

  const onTextareaChange = (e) => {
    const text = e.target.value;
    setText(text);

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      onChange(index, {
        title: token,
        text,
      });
    }, 100);
  };

  return (
    <Relative>
      <DeleteButton onClick={() => onDelete(index)} type="button">
        -
      </DeleteButton>
      <PromptSelect
        width="50%"
        options={promptTokensOptions}
        title="Tokens"
        selected={token}
        onSelect={onSelect}
      />
      <InputBlock>
        <InputTextarea
          placeholder="Token value..."
          value={text}
          onChange={onTextareaChange}
          autoComplete="off"
        />
      </InputBlock>
    </Relative>
  );
};

const DeleteButton = styled.button`
  color: ${({ theme }) => `${theme.colors.red}`};
  background: none;
  border: ${({ theme }) => `1px solid ${theme.colors.red}`};
  /* padding: 2px; */
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 6px;
  left: calc(90% - 20px);
  cursor: pointer;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Relative = styled.div`
  position: relative;
`;

const InputTextarea = styled.textarea`
  margin-top: 10px;
  border: 1px solid #d1d1d1;
  outline: none;
  padding: 10px;
  height: 150px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    margin-top: 10px;
    border: 1px solid #d1d1d1;
    outline: none;
    height: 150px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

export default SampleListItem;
