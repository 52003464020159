import React from "react";

import TabItem from "./TabItem";

import { List } from "./styles";

function TabList({
  viewedCandidatesIds,
  filteredCandidates,
  filteredData,
  activeCandidateIndex,
  setActiveCandidateIndex,
  isScrollListActive,
}) {
  return (
    <List $isScrollListActive={isScrollListActive}>
      {filteredCandidates.map((candidate, index) => {
        return (
          filteredData.includes(candidate) && (
            <TabItem
              key={index}
              candidate={candidate}
              isActive={index === activeCandidateIndex}
              isViewed={viewedCandidatesIds.includes(candidate.externalId)}
              handleClick={() => setActiveCandidateIndex(index)}
            />
          )
        );
      })}
    </List>
  );
}

export default TabList;
