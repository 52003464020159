import React, { useContext } from "react";

import SubTabList from "../SubTabs";
import { SettingsContext } from "../../../context";
import { MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE } from "../../../../../utils/constants";

import { Item, Title } from "./styles";

function TabItem({ tabName, title, isActive, handleClick }) {
  const { activeTab, styledTab } = useContext(SettingsContext);

  const isMulti =
    MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE.includes(tabName) &&
    styledTab === tabName;

  return (
    <Item $isActive={isActive} $isMulti={isMulti}>
      <Title $isActive={isActive} onClick={() => handleClick(tabName)}>
        {title}
      </Title>
      {isMulti && (
        <SubTabList
          tabName={tabName}
          handleClick={handleClick}
          activeTab={activeTab}
        />
      )}
    </Item>
  );
}

export default TabItem;
