import { useState } from "react";
import { signUp, getOauthUserData, checkInviteCode } from "../services/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useGoogleLogin } from "@react-oauth/google";

import ConnectBtn from "./ConnectBtn";
import queryString from "query-string";

import styled from "styled-components";
import {
  Actions,
  CheckboxBlock,
  CheckboxLabel,
  Content,
  ErrorIcon,
  ErrorMessage,
  Form,
  Input,
  InputBlock,
  Inputs,
  Label,
  LinkText,
  Relative,
  ShowPwdIcon,
  SignInRedirectBlock,
  SignInRedirectText,
  Title,
} from "./authStyles";
import { sendMessageToExtension } from "../utils/postToExtension";
import { CRMS, EMAIL_REGEX, OAUTH_TYPES } from "../utils/constants";
import ThirdPartyOauthButtons from "./ThirdPartyOauthButtons";
import { AuthErrorCodes, loginRequest } from "../utils/auth-o365-config";

const PWD_REGEX = /^(?=.*\d).{6,}$/;

const Register = () => {
  const { instance } = useMsal();
  const location = useLocation();
  const userData = location.state?.userData || {};
  const publicHubspotAppTokensData = getPublicHubspotAppTokensData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState(userData.user || "");
  const [email, setEmail] = useState(userData.email || "");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [code, setCode] = useState("");
  const [company, setCompany] = useState(userData.company || "");
  const [codeCompanyId, setCodeCompanyId] = useState(null);
  const [hasInviteCode, setHasInviteCode] = useState(false);
  const [oauthType, setOauthType] = useState(userData.oauthType || "");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [userErrorMessage, setUserErrorMessage] = useState("");
  const [pwdErrorMessage, setPwdErrorMessage] = useState("");
  const [confirmPwdErrorMessage, setConfirmPwdErrorMessage] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [companyErrorMessage, setCompanyErrorMessage] = useState("");

  function getPublicHubspotAppTokensData() {
    const tokensFromSearch = queryString.parse(location.search);
    const { accessToken, refreshToken, expiryDate } = tokensFromSearch;

    if (accessToken && refreshToken && expiryDate) {
      return {
        accessToken,
        refreshToken,
        expiryDate,
      };
    }

    const tokensFromState = location.state?.publicHubspotAppTokensData;

    if (
      tokensFromState &&
      tokensFromState.accessToken &&
      tokensFromState.refreshToken &&
      tokensFromState.expiryDate
    ) {
      return {
        accessToken: tokensFromState.accessToken,
        refreshToken: tokensFromState.refreshToken,
        expiryDate: tokensFromState.expiryDate,
      };
    }

    return null;
  }

  const toggleShowPassword = (isConfirmPwd) => {
    if (isConfirmPwd) {
      setShowConfirmPassword(!showConfirmPassword);
      return;
    }
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    const signUpData = getSignUpData();

    const result = await signUp(signUpData);
    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });

      return;
    }

    resetForm();

    navigate("/verification");
  };

  const isFormValid = () => {
    let isEmailValid = true;
    let isPwdValid = true;
    let isUserValid = true;
    let isCodeValid = true;
    let isCompanyValid = true;
    let isConfirmPwdValid = true;

    if (!oauthType) {
      if (!email || !EMAIL_REGEX.test(email) || email === "") {
        setEmailErrorMessage("Please enter a valid email address");
        isEmailValid = false;
      }

      if (!pwd || !PWD_REGEX.test(pwd) || pwd === "") {
        setPwdErrorMessage(
          "Password must be at least 6 characters long and have at least one number"
        );
        isPwdValid = false;
      }

      if (confirmPwd !== pwd) {
        setConfirmPwdErrorMessage("Passwords do not match");
        isConfirmPwdValid = false;
      }

      if (!user || user === "") {
        setUserErrorMessage("Please enter a username");
        isUserValid = false;
      }
    }

    if (hasInviteCode && !code.trim()) {
      setCodeErrorMessage("Please enter an invite code");
      isCodeValid = false;
    }

    if ((!hasInviteCode || codeCompanyId === "") && !company.trim()) {
      setCompanyErrorMessage("Please enter a company name");
      isCompanyValid = false;
    }

    if (
      !isEmailValid ||
      !isPwdValid ||
      !isUserValid ||
      !isCodeValid ||
      !isCompanyValid ||
      !isConfirmPwdValid
    ) {
      return false;
    }

    return true;
  };

  const resetForm = () => {
    setOauthType("");
    setUser("");
    setUserErrorMessage("");
    setEmail("");
    setEmailErrorMessage("");
    setPwd("");
    setPwdErrorMessage("");
    setConfirmPwd("");
    setConfirmPwdErrorMessage("");
    setCodeCompanyId(null);
    setCode("");
    setCodeErrorMessage("");
    setHasInviteCode(false);
    setCompany("");
    setCompanyErrorMessage("");
  };

  const getSignUpData = () => {
    const data = {
      user,
      email,
    };

    if (pwd) {
      data.pwd = pwd;
    }

    if (code) {
      data.code = code.trim();
    }

    if (company) {
      data.company = company.trim();
    }

    if (oauthType) {
      data.oauthType = oauthType;
    }

    if (publicHubspotAppTokensData) {
      data.hubspotIntegrationData = {
        type: "crm",
        connectionKeys: {
          type: CRMS.HUBSPOT,
          accessToken: publicHubspotAppTokensData.accessToken,
          expiryDate: Number(publicHubspotAppTokensData.expiryDate),
          refreshToken: publicHubspotAppTokensData.refreshToken,
          method: "oauth",
        },
      };
    }

    return data;
  };

  const handleGsuiteSignUp = useGoogleLogin({
    onSuccess: async (response) => handleSuccessGsuiteSignUp(response),
    onError: (error) => handleErrorGsuiteSignUp(error),
    flow: "auth-code",
  });

  const handleSuccessGsuiteSignUp = async (response) => {
    setIsLoading(true);

    const { code } = response;
    const result = await getOauthUserData({ code });

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsLoading(false);
      return;
    }

    const { name, email } = result.result;

    setOauthType(OAUTH_TYPES.gsuite);
    setUser(name);
    setUserErrorMessage("");
    setEmail(email);
    setEmailErrorMessage("");
    setPwd("");
    setPwdErrorMessage("");
    setConfirmPwd("");
    setConfirmPwdErrorMessage("");
    setIsLoading(false);
  };

  const handleErrorGsuiteSignUp = (error) => {
    sendMessageToExtension({
      message: "show-error-message",
      data: {
        message: error?.message || "Unable to register with Google",
      },
    });
  };

  const handleO365SignUp = async () => {
    try {
      setIsLoading(true);

      const response = await instance.loginPopup(loginRequest);
      const { account } = response;

      setOauthType(OAUTH_TYPES.office365);
      setUser(account.name);
      setUserErrorMessage("");
      setEmail(account.username);
      setEmailErrorMessage("");
      setPwd("");
      setPwdErrorMessage("");
      setConfirmPwd("");
      setConfirmPwdErrorMessage("");
      setIsLoading(false);
    } catch (error) {
      if (error.errorCode !== AuthErrorCodes.userCancelled) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: error?.message || "Unable to register with Microsoft",
          },
        });
      }

      setIsLoading(false);
    }
  };

  const handleBlurInviteCode = async (e) => {
    const val = e.target.value.trim();
    setCode(val);

    if (!val) {
      setCodeCompanyId(null);
      return;
    }

    setIsLoading(true);
    const res = await checkInviteCode(val);
    setIsLoading(false);

    if (!res.success) {
      setCodeErrorMessage(res.message || "Invalid invite code");
      return;
    }

    setCodeCompanyId(res.companyId ?? "");

    if (res.companyId) {
      setCompany("");
      setCompanyErrorMessage("");
    }
  };

  return (
    <Content>
      <ThirdPartyOauthButtons
        handleGsuiteOauth={handleGsuiteSignUp}
        handleO365Oauth={handleO365SignUp}
      />
      <Title>
        Sign Up <span>With Email</span>
      </Title>
      <Form onSubmit={handleSubmit}>
        <Inputs>
          <InputBlock>
            <Label htmlFor="username">Your Full Name</Label>
            <Relative>
              <Input
                type="text"
                id="username"
                placeholder="Your name..."
                value={user}
                onChange={(e) => setUser(e.target.value)}
                onInput={(e) => setUserErrorMessage("")}
                autoComplete="off"
              />
              {userErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {userErrorMessage && (
              <ErrorMessage>{userErrorMessage}</ErrorMessage>
            )}
          </InputBlock>

          <InputBlock>
            <Label htmlFor="email">Your Email Address</Label>
            <Relative>
              <Input
                id="email"
                placeholder="Email address..."
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                onInput={(e) => setEmailErrorMessage("")}
                autoComplete="off"
                disabled={oauthType ? true : false}
              />
              {emailErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {emailErrorMessage && (
              <ErrorMessage>{emailErrorMessage}</ErrorMessage>
            )}
          </InputBlock>

          {!oauthType && (
            <InputBlock>
              <Label htmlFor="password">Password</Label>
              <Relative>
                <Input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Give your account a strong password..."
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                  onInput={(e) => setPwdErrorMessage("")}
                  autoComplete="off"
                />
                {pwdErrorMessage ? (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                ) : (
                  <ShowPwdIcon
                    src="/images/show-pwd-icon.svg"
                    alt="show password"
                    onClick={() => toggleShowPassword(false)}
                  />
                )}
              </Relative>
              {pwdErrorMessage && (
                <ErrorMessage>{pwdErrorMessage}</ErrorMessage>
              )}
            </InputBlock>
          )}

          {!oauthType && (
            <InputBlock>
              <Label htmlFor="confirm-password">Confirm Password</Label>
              <Relative>
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  placeholder="Repeat your password..."
                  value={confirmPwd}
                  onChange={(e) => setConfirmPwd(e.target.value)}
                  onInput={(e) => setConfirmPwdErrorMessage("")}
                  autoComplete="off"
                />
                {confirmPwdErrorMessage ? (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                ) : (
                  <ShowPwdIcon
                    src="/images/show-pwd-icon.svg"
                    alt="show password"
                    onClick={() => toggleShowPassword(true)}
                  />
                )}
              </Relative>
              {confirmPwdErrorMessage && (
                <ErrorMessage>{confirmPwdErrorMessage}</ErrorMessage>
              )}
            </InputBlock>
          )}

          <InputBlock>
            <CheckboxBlock>
              <input
                id="hasInviteCode"
                type="checkbox"
                checked={hasInviteCode}
                value={hasInviteCode}
                onChange={(event) => {
                  const val = event.target.checked;
                  if (val) {
                    setCompany("");
                    setCompanyErrorMessage("");
                  } else {
                    setCodeCompanyId(null);
                    setCode("");
                    setCodeErrorMessage("");
                  }
                  setHasInviteCode(val);
                }}
              />
              <label htmlFor="hasInviteCode" className="toggle"></label>
              <CheckboxLabel $checked={hasInviteCode}>
                I have an invite code
              </CheckboxLabel>
            </CheckboxBlock>
          </InputBlock>

          {hasInviteCode && (
            <>
              <InputBlock>
                <Label htmlFor="code">Invite Code</Label>
                <Relative>
                  <Input
                    type="text"
                    id="code"
                    placeholder="Your invite code..."
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    onInput={(e) => setCodeErrorMessage("")}
                    onBlur={handleBlurInviteCode}
                    autoComplete="off"
                  />
                  {codeErrorMessage && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {codeErrorMessage && (
                  <ErrorMessage>{codeErrorMessage}</ErrorMessage>
                )}
              </InputBlock>
              <InviteCodeLink to="/contact-us?reason=code">
                <InviteCodeLinkText>Request an invite code</InviteCodeLinkText>
              </InviteCodeLink>
            </>
          )}

          {(!hasInviteCode || codeCompanyId === "") && (
            <InputBlock>
              <Label htmlFor="company">Company Name</Label>
              <Relative>
                <Input
                  type="text"
                  id="company"
                  placeholder="Your company name..."
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  onInput={(e) => setCompanyErrorMessage("")}
                  autoComplete="off"
                />
                {companyErrorMessage && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {companyErrorMessage && (
                <ErrorMessage>{companyErrorMessage}</ErrorMessage>
              )}
            </InputBlock>
          )}
        </Inputs>
        <Actions>
          <ConnectBtn
            large={true}
            type="submit"
            label="Sign Up Now"
            disabled={isLoading}
          />
          <SignInRedirectBlock>
            <SignInRedirectText>Already a member?</SignInRedirectText>
            <Link to="/login">
              <LinkText>Sign In</LinkText>
            </Link>
          </SignInRedirectBlock>
        </Actions>
      </Form>
    </Content>
  );
};

export default Register;

const InviteCodeLink = styled(Link)`
  margin-top: -6px;
  margin-bottom: 16px;
  width: fit-content;
`;

const InviteCodeLinkText = styled.span`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 9px;
  color: #4640de;
`;
