import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton, SecondaryButton } from "./styles";

function StepButton({ isNext, onClick, disabled = false, label }) {
  return (
    <>
      {isNext ? (
        <PrimaryButton onClick={onClick} disabled={disabled}>
          {label || "Next"}
        </PrimaryButton>
      ) : (
        <SecondaryButton onClick={onClick} disabled={disabled}>
          {label || "Previous"}
        </SecondaryButton>
      )}
    </>
  );
}

StepButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isNext: PropTypes.bool.isRequired,
};

export default StepButton;
