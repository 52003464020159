import React, { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import { pickBy, identity, isEmpty } from "lodash";

const TableFilters = ({ load, fields, values }) => {
  const useCloseByClickOutside = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpenFilters(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const inputKeys = fields
    .map(({ inputs }) => inputs.map((input) => input.key))
    .flat();

  const emptyFilters = inputKeys.reduce(
    (acc, key) => ({ ...acc, [key]: "" }),
    {}
  );

  const startFilters = inputKeys.reduce(
    (acc, key) => ({ ...acc, [key]: values?.[key] || "" }),
    {}
  );

  const [filters, setFilters] = useState(startFilters);
  const [appliedFilters, setAppliedFilters] = useState(
    pickBy(startFilters, identity) || {}
  );
  const wrapperRef = useRef(null);

  useCloseByClickOutside(wrapperRef);

  const handleChangeFiltersForm = (field, value) => {
    filters[field] = value;
    setFilters({ ...filters });
  };

  const handleApplyFilters = () => {
    loadWithFilters(filters);
    setIsOpenFilters(false);
  };

  const handleResetFilters = () => {
    setFilters(emptyFilters);
    loadWithFilters(emptyFilters);
  };

  const loadWithFilters = (filter) => {
    const pureFilters = pickBy(filter, identity);
    const newAppliedFilters = isEmpty(pureFilters) ? {} : pureFilters;
    setAppliedFilters(newAppliedFilters);
    load(newAppliedFilters);
  };

  return (
    <Filters ref={wrapperRef}>
      <FilterIcon
        onClick={() => setIsOpenFilters(!isOpenFilters)}
        $number={Object.values(appliedFilters).filter(Boolean).length}
      />
      {isOpenFilters && (
        <FiltersBody>
          <FiltersContent>
            <Label>Filter</Label>
            {fields.map((subLevel) => (
              <FiltersContentBox key={subLevel.title}>
                {subLevel.title && <SubLabel>{subLevel.title}</SubLabel>}
                {subLevel.inputs.map((input) =>
                  subLevel.type === "select" ? (
                    <div key={input.key}>
                      {input.relevantValues.map((value, index) => (
                        <SelectableButton
                          key={index}
                          className={
                            (input.isMultiSelect
                              ? filters[input.key].includes?.(value)
                              : filters[input.key] === value) && "selected"
                          }
                          onClick={(e) =>
                            handleChangeFiltersForm(
                              input.key,
                              input.isMultiSelect ? [value] : value
                            )
                          }
                        >
                          {value}
                        </SelectableButton>
                      ))}
                    </div>
                  ) : (
                    <FilterTextInput
                      placeholder={input.label}
                      onInput={(e) =>
                        handleChangeFiltersForm(input.key, e.target.value)
                      }
                      value={filters[input.key]}
                    />
                  )
                )}
              </FiltersContentBox>
            ))}
          </FiltersContent>
          <FiltersActions>
            <ApplyButton onClick={handleApplyFilters}>Apply</ApplyButton>
            <ResetFiltersButton onClick={handleResetFilters}>
              Reset Filters
            </ResetFiltersButton>
          </FiltersActions>
        </FiltersBody>
      )}
    </Filters>
  );
};

export default TableFilters;

const Filters = styled.div`
  position: relative;
`;

const FiltersBody = styled.div`
  width: 208px;
  height: 338px;
  position: absolute;
  box-shadow: 0px 4px 50px 0px #00000059;
  z-index: 20;
  background: #fff;
  left: -93px;
  right: 0px;
  top: 30px;
  border-radius: 6px;

  display: flex;
  flex-direction: column;

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-bottom: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    left: 94px;
    top: -7px;
  }
`;

const FilterIcon = styled.div`
  cursor: pointer;
  background-image: ${({ $number }) =>
    `url("/images/filter${$number ? "-selected" : ""}.svg");`};
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 18px;
  width: 18px;
  height: 18px;
  position: relative;
  ${({ $number }) =>
    $number
      ? `&:after {
          content: '${$number}';
          position: absolute;
          top: -7px;
          right: -7px;
          color: #fff;
          background-color: #f95959;
          box-shadow: 0 0 0 2px #fff;
          border-radius: 50%;
          text-align: center;
          width: 16px;
          height: 16px;
          line-height: 16px;
          font-size: 10px;
          font-weight: 700;
        }`
      : ""};
`;

const FiltersContent = styled.div`
  flex: 1 0 auto;
  padding: 10px;
`;

const FiltersContentBox = styled.div`
  margin-bottom: 10px;
`;

const FiltersActions = styled.div`
  width: 100%;
  height: 60px;
  background: ${({ theme }) => theme.colors.background_color};
  flex-shrink: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 10px;
`;

const FilterTextInput = styled.input`
  width: 100%;
  height: 28px;
  box-shadow: none;
  padding: 12px 0px 12px 6px;
  margin-bottom: 7px;

  &::placeholder {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #a8adb7;
  }

  &:focus-visible {
    border-radius: 3px;
    outline: none;
    color: black;
    height: 28px;
    box-shadow: none;
    width: 100%;
    padding: 12px 0px 12px 6px;
    margin-bottom: 7px;
  }
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 9px;
`;

const SubLabel = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #a8adb7;
`;

const SelectableButton = styled.button`
  cursor: pointer;
  color: #c3c3c3;
  border: 1px solid #e3e3e3;
  background: inherit;
  padding: 5px;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 5px;

  &.selected {
    background: #f95959;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    border-radius: 3px;
  }
`;

const ApplyButton = styled.button`
  cursor: pointer;
  width: 94px;
  height: 40px;
  background: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 6px;
  margin-right: 15px;
`;

const ResetFiltersButton = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue};
  border: none;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
`;
