import React from "react";

export default function StartNewCampaignIcon({ fill, opacity }) {
  return (
    <svg
      width="39"
      height="37"
      viewBox="0 0 39 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity || 1}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4604 7.57619C38.3951 8.50445 38.913 9.74857 38.913 11.0744L38.913 33.1148C38.913 33.1536 38.9116 33.1921 38.909 33.2303C38.9115 33.2724 38.9128 33.3154 38.9128 33.3593C38.9128 34.5114 38.0079 35.0603 37.1458 35.0059C37.109 35.0083 37.0718 35.0096 37.0344 35.0096H17.5944C16.684 35.0096 15.946 34.2716 15.946 33.3612C15.946 32.4508 16.684 31.7127 17.5944 31.7127H17.8933C17.9709 31.7078 18.0489 31.7078 18.1266 31.7127H27.7944C27.872 31.7078 27.95 31.7078 28.0277 31.7127H35.6125V24.5837L35.6124 24.558L35.6125 24.5323V11.0744C35.6125 10.6339 35.4428 10.2213 35.129 9.9076L29.914 4.68826C29.6046 4.38099 29.1813 4.20479 28.7472 4.20479H13.0589C11.5397 4.20479 10.3085 5.43604 10.3085 6.9552V15.7565C10.3085 16.6633 9.56497 17.4068 8.65821 17.4068C7.75146 17.4068 7.00797 16.6633 7.00797 15.7565V6.9552C7.00797 3.61604 9.71971 0.904297 13.0589 0.904297H28.7472C30.0666 0.904297 31.3107 1.42214 32.2454 2.35684L37.4604 7.57619ZM7.29523 21.0944C7.29523 20.2497 7.98054 19.5644 8.82522 19.5644C9.6699 19.5644 10.3552 20.2497 10.3552 21.0944V26.7044H15.9652C16.8099 26.7044 17.4952 27.3897 17.4952 28.2343C17.4952 29.079 16.8099 29.7643 15.9652 29.7643H10.3552V35.3743C10.3552 36.219 9.6699 36.9043 8.82522 36.9043C7.98054 36.9043 7.29523 36.219 7.29523 35.3743V29.7643H1.68526C0.840582 29.7643 0.155273 29.079 0.155273 28.2343C0.155273 27.3897 0.840582 26.7044 1.68526 26.7044H7.29523V21.0944Z"
        fill={fill || "white"}
      />
    </svg>
  );
}
