import React from "react";

import {
  Discount,
  Input,
  LeftLabel,
  MainContainer,
  RightLabel,
  RightLabelContainer,
  ToggleButton,
} from "./styles";

const ToggleSwitch = ({ leftLabel, rightLabel, position, onChange }) => {
  const isChecked = position === "left";

  return (
    <MainContainer>
      <LeftLabel htmlFor="toggleSwitch">{leftLabel}</LeftLabel>
      <Input
        id="toggleSwitch"
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <ToggleButton htmlFor="toggleSwitch" $checked={isChecked} />
      <RightLabelContainer>
        <RightLabel htmlFor="toggleSwitch">{rightLabel}</RightLabel>
        <Discount>Save 33%</Discount>
      </RightLabelContainer>
    </MainContainer>
  );
};

export default ToggleSwitch;
