import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  top: -54px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 128px 10px;
  position: relative;
`;

export const Header = styled.h3`
  text-align: center;
  margin-top: 45px;
  color: #5a5a5a;
  line-height: 28.8px;
  font-size: 18px;
  font-weight: 700;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: #5a5a5a;
  width: 456px;
  margin: 0 auto;
  margin-top: 5px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }

  &::before {
    content: "";
    background-image: url("/images/prepared-campaign-arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 114px;
    height: 153px;
    display: block;
    position: absolute;
    top: 15px;
    right: -75px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const QuestionBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  color: ${({ theme }) => theme.colors.black};
  font-family: "AlbertSans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.25px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 28px;
  color: #5a5a5a;
`;

export const QuestionIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin: 128px auto 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CampaignTitleInput = styled.input`
  border: 1px solid #d6ddeb;
  border-radius: 3px;
  width: 515px;
  height: 61px;
  box-shadow: none;
  margin-bottom: 3px;

  &:focus-visible {
    border: 2px solid #d6ddeb;
    border-radius: 3px;
    width: 515px;
    height: 61px;
    margin-bottom: 3px;
  }

  &::placeholder {
    color: #888888;
    opacity: 0.5;
    font-size: 16px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ErrorIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-43%);
`;

export const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
  display: block;
`;

export const ButtonWrapper = styled.div`
  margin-top: 5px;
`;

export const CreateCampaignButton = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  height: 40px;
  padding: 10px 34px;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-weight: 800;
  font-size: 12px;
  border-radius: 6px;
  border: none;
  margin-left: 20px;
`;
