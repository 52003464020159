/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";

import CampaignOverview from "./CampaignOverview";
import CampaignDetails from "./CampaignDetails";
import CampaignType from "./CampaignType";
import SalesStrategy from "./SalesStrategy";
import SelectRecipients from "./SelectRecipients";
import ConfigureOptions from "./ConfigureOptions";
import JobDescription from "./JobDescription";

import {
  GOALS,
  ATTEMPTS_OPTIONS,
  BEGIN_OPTIONS,
  DURATION_OPTIONS,
  TONE_OPTIONS,
  BULLHORN_CAMPAIGN_TYPES,
} from "../constants";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from "../../../api/localStorage";
import {
  buildMarketingCampaignName,
  getWizardTabs,
  isBullhornMarketingCampaignType,
} from "../utils";
import {
  createCampaign,
  postTempCampaign,
  getCampaignTokensCost,
} from "../../../services/campaigns";
import { getOptedOutCandidates } from "../../../services/candidates";
import { uniq } from "lodash";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
} from "../../../utils/constants";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import { isUserInFirstTimeCampaignOnboardingUserList } from "../../../utils/utils";
import Loader from "../../Loader";
import ReceiveSquareIcon from "./icons/ReceiveSquareIcon";
import EstimatedTokenCostCard from "./EstimatedTokenCostCard";
import PreviewMessage from "./PreviewMessage";

function ContentSteps({
  currentTempCampaign,
  setCurrentTempCampaign,
  activeTab,
  setActiveTab,
  setCampaignType,
  isRecreatingCampaignIframe,
  crm,
  campaignData,
  isEmailIntegration,
  isSmsIntegration,
  onCloseWizard: onClose,
}) {
  const [campaign, setCampaign] = useState({
    attempts: ATTEMPTS_OPTIONS[0].value + 1,
    begin: BEGIN_OPTIONS[0].value,
    beginDate: "",
    duration: DURATION_OPTIONS[0].value,
    goal: "",
    isCustomGoal: false,
    name: crm === CRMS.BULLHORN ? "" : buildMarketingCampaignName(),
    jobDescriptionKey: "",
    jobDescription: {},
    type: "",
    details: "",
    strategy: "",
    useSMS: false,
    candidatesLength: 0,
    aiModel: "",
    tone: TONE_OPTIONS[0].value,
    additionalInstructions: "",
    link: "",
    smsTiming: 1,
    autoReply: false,
    isFollowUpsAsReplies: false,
    useContactNotesInCommunications: false,
    isFullSequenceCampaign: false,
    sequenceTemplate: null,
    crmType: crm,
    sampleEmail: "",
  });

  const tabs = getWizardTabs(crm, campaign.type);

  const [tempCampaignId, setTempCampaignId] = useState(null);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [isShowCredits, setIsShowCredits] = useState(null);
  const [estimatedToken, setEstimatedToken] = useState(0);
  const [creditsRemaining, setCreditsRemaining] = useState(0);
  const [isEstimatedTokenLoading, setIsEstimatedTokenLoading] = useState(false);
  const [mayExceedCreditsData, setMayExceedCreditsData] = useState(null);

  const isAllowShowCredits = isShowCredits !== null && estimatedToken > 0;

  const onCloseWizard = () => {
    onClose();
    sendMessageToExtension({
      message: "open-campaigns",
    });
  };

  const fetchEstimatedTokenCost = async () => {
    const {
      attempts,
      crmType,
      candidates,
      goal,
      autoReply,
      strategy,
      aiModel,
      additionalInstructions,
      jobDescription,
      jobDescriptionKey,
      sequenceTemplate,
    } = campaign;

    const filteredCandidates = candidates?.filter(
      (candidate) => !candidate.isInvalid
    );

    const data = {
      attempts,
      crmType,
      contacts: filteredCandidates || candidates,
      goal,
      autoReply,
      strategy,
      aiModel,
      additionalInstructions,
      sequenceTemplate,
    };
    if (sequenceTemplate) {
      delete data.attempts;
    }

    if (
      crm === CRMS.BULLHORN &&
      !isBullhornMarketingCampaignType(campaign.type)
    ) {
      const job_description = jobDescription[jobDescriptionKey];
      const job_pay_rate = campaign.payRate;

      data.crmData = {
        job_description,
        job_pay_rate,
      };
    } else {
      data.crmData = {
        campaign_type: campaign.type,
        campaign_details: campaign.details,
      };
    }

    if (
      !goal ||
      !campaign.type ||
      !(data.crmData.job_description || data.crmData.campaign_details)
    ) {
      return;
    }

    if (!data.contacts?.length) {
      setEstimatedToken(0);
      return;
    }

    setIsEstimatedTokenLoading(true);

    const response = await getCampaignTokensCost(data);

    if (response.success !== undefined && !response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: data.inProgress
            ? data.errorMessage
            : "Unfortunately, we were unable to calculate campaign cost",
        },
      });
      setIsEstimatedTokenLoading(false);
      return;
    }

    const estimatedTokenCount = Math.ceil(
      response.result.estimateTokens / 1000
    );
    const creditsRemainingCount = Math.round(
      response.result.creditsRemaining / 1000
    );

    setMayExceedCreditsData(
      creditsRemainingCount < estimatedTokenCount
        ? {
            estimatedTokenCount,
            creditsRemaining: creditsRemainingCount,
          }
        : null
    );

    setEstimatedToken(estimatedTokenCount);
    setCreditsRemaining(creditsRemainingCount);

    if (isShowCredits === null) {
      setIsShowCredits(activeTab !== tabs.REVIEW_MESSAGES);
    }

    setIsEstimatedTokenLoading(false);
  };

  useEffect(() => {
    fetchEstimatedTokenCost();
  }, [
    campaign.attempts,
    campaign.candidates,
    campaign.goal,
    campaign.autoReply,
    campaign.strategy,
    campaign.details,
    campaign.type,
    campaign.aiModel,
    campaign.additionalInstructions,
    campaign.sequenceTemplate,
  ]);

  const changeCampaignValues = (campaign) => {
    setCampaign(campaign);
  };

  const finishCreation = async (e, updValues) => {
    e?.preventDefault();

    const preparedFields = prepareCreateCampaignFields(updValues);

    // stop contacts observer in extension
    sendMessageToExtension({
      message: "finish-create-campaign",
      data: {
        noRedirect: true,
      },
    });

    if (!preparedFields.isFullSequenceCampaign) {
      const createdAtMs = Date.now();

      saveObjectToLocalStorage("campaignToMinimize", {
        ...preparedFields,
        tempCampaignId,
        createdAtMs,
      });

      const userInfo = getUserFromLocalStorage();
      const isNeedToShowWarning = !isUserInFirstTimeCampaignOnboardingUserList({
        userId: userInfo?.id,
        name: FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.prepareCampaign.name,
      });

      sendMessageToExtension({
        message: "minimize-campaign-prep",
        data: {
          createdAtMs,
          isNeedToShowWarning,
        },
      });

      return;
    }

    const result = await createCampaign({
      campaign: { ...preparedFields, tempCampaignId },
    });

    if (result.success !== undefined && !result.success) {
      console.log("create temp campaign error", result.message);

      sendMessageToExtension({
        message: "create-campaign-error",
      });
      return;
    }

    const createdCampaign = result.result.campaign;

    if (!createdCampaign) {
      sendMessageToExtension({
        message: "finish-create-campaign",
      });
      sendMessageToExtension({
        message: "create-campaign-error",
      });
      return;
    }

    saveObjectToLocalStorage("campaignToSelect", createdCampaign);

    sendMessageToExtension({
      message: "finish-create-campaign",
      data: {
        campaignId: createdCampaign.id,
      },
    });
  };

  const prepareCreateCampaignFields = (updValues) => {
    const {
      attempts,
      begin,
      beginDate,
      duration,
      goal,
      name,
      link,
      smsTiming,
      strategy,
      useSMS,
      aiModel,
      tone,
      additionalInstructions,
      autoReply,
      candidates,
      crmType,
      jobDescription,
      jobDescriptionKey,
      isFollowUpsAsReplies,
      useContactNotesInCommunications,
      isFullSequenceCampaign,
      sequenceTemplate,
      sampleEmail,
    } = { ...campaign, ...updValues };

    const filteredCandidates = candidates.filter(
      (candidate) => !candidate.isInvalid
    );

    const durationCount = parseInt(duration);
    const durationUnit = duration.charAt(duration.length - 1);

    const createCampaignFields = {
      attempts: Number(attempts),
      begin: beginDate || begin,
      duration: durationCount,
      durationUnit,
      goal,
      name,
      strategy,
      useSMS,
      aiModel,
      tone,
      additionalInstructions,
      autoReply,
      contacts: filteredCandidates,
      crmType,
      isFollowUpsAsReplies,
      useContactNotesInCommunications,
      isFullSequenceCampaign,
      sequenceTemplate,
      sampleEmail,
    };

    if (goal === GOALS.visitLink) {
      createCampaignFields.link = link;
    }

    if (useSMS) {
      createCampaignFields.smsTiming = Number(smsTiming);
    }

    if (
      crm === CRMS.BULLHORN &&
      !isBullhornMarketingCampaignType(campaign.type)
    ) {
      const job_description = jobDescription[jobDescriptionKey];
      const job_pay_rate = campaign.payRate;

      createCampaignFields.crmData = {
        job_description,
        job_pay_rate,
      };
    } else {
      createCampaignFields.crmData = {
        campaign_type: campaign.type,
        campaign_details: campaign.details,
      };
    }

    if (isFullSequenceCampaign) {
      delete createCampaignFields.attempts;
      delete createCampaignFields.useSMS;
      delete createCampaignFields.smsTiming;
      delete createCampaignFields.begin;
      delete createCampaignFields.duration;
    } else {
      delete createCampaignFields.sequenceTemplate;
    }

    return createCampaignFields;
  };

  const handleIncreaseStep = async (event) => {
    event?.preventDefault();

    if (activeTab === Object.keys(tabs).length) {
      return;
    }

    const nextTab = activeTab + 1;
    setActiveTab(nextTab);

    if (nextTab === tabs.REVIEW_MESSAGES) {
      setIsShowCredits(false);
    }
  };

  const handleDecreaseStep = (event) => {
    event?.preventDefault();
    if (activeTab > 1) {
      setActiveTab((activeTab) => activeTab - 1);
    }
  };

  useEffect(() => {
    if (isRecreatingCampaignIframe) {
      const currentCampaign = getObjectFromLocalStorage("campaignInfo");
      setCampaign(currentCampaign);
      setActiveTab(tabs.SELECT_RECIPIENTS);
    }
  }, [isRecreatingCampaignIframe]);

  useEffect(() => {
    if (campaign.candidatesExternalIdList?.length) {
      const calcOptedOut = async () => {
        const optedOutCandidates = await getOptedOutCandidates({
          campaignType: campaign.type,
          externalIdList: campaign.candidatesExternalIdList,
          crmType: campaign.crmType,
        });
        const optedOutCandidatesList = uniq(
          optedOutCandidates.result?.result || []
        );
        const candidates = campaign.candidates.map((c) => {
          if (optedOutCandidatesList.includes(c.id)) {
            return { ...c, isInvalid: true };
          }
          return c;
        });
        changeCampaignValues({
          ...campaign,
          candidatesLength: candidates.length,
          candidates,
        });
      };
      calcOptedOut();
    }
  }, [campaign.candidatesExternalIdList]);

  const getDefaultCampaignData = () => {
    const addData = {};

    if (crm === CRMS.BULLHORN) {
      addData.localBullhornData = { ...campaignData };

      if (campaignData.isMarketingCampaign) {
        addData.type = BULLHORN_CAMPAIGN_TYPES.marketToCandidates;
        addData.name = buildMarketingCampaignName();
        return addData;
      }

      addData.type = BULLHORN_CAMPAIGN_TYPES.recruitForJob;
      addData.payRate = campaignData.payRate;
      addData.jobDescription = { ...campaignData.jobDescription };
    }

    addData.name = campaignData.jobTitle;
    return addData;
  };

  useEffect(() => {
    if (currentTempCampaign) {
      setTempCampaignId(currentTempCampaign.tempCampaignId);

      const campData = { ...currentTempCampaign };
      delete campData.activeTab;
      delete campData.createdAt;

      if (crm === CRMS.BULLHORN && !currentTempCampaign.type) {
        campData.type = BULLHORN_CAMPAIGN_TYPES.recruitForJob;
        setCampaignType(campData.type);
      }

      setCampaign(campData);
    } else if (campaignData) {
      const defaultData = getDefaultCampaignData();
      setCampaign({
        ...campaign,
        ...defaultData,
      });

      if (defaultData.type) {
        setCampaignType(defaultData.type);
      }
    }
  }, []);

  useEffect(() => {
    if (campaign.name && (campaign.goal || campaign.asDraft)) {
      const saveTemp = async () => {
        const { asDraft } = campaign;
        delete campaign.asDraft;
        if (asDraft) {
          setIsSavingDraft(true);
        }
        const newCurrentTempCampaignStr = JSON.stringify({
          ...campaign,
          activeTab,
        });
        if (JSON.stringify(currentTempCampaign) !== newCurrentTempCampaignStr) {
          setCurrentTempCampaign(JSON.parse(newCurrentTempCampaignStr));
          const res = await postTempCampaign({
            crm,
            campaign: newCurrentTempCampaignStr,
          });
          if (!tempCampaignId && res.success && res.result.tempCampaign?.id) {
            setTempCampaignId(res.result.tempCampaign.id);
          }
        }
        if (asDraft) {
          onCloseWizard();
        }
      };
      saveTemp();
    }
  }, [campaign, activeTab]);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case tabs.CAMPAIGN_OVERVIEW:
        return (
          <CampaignOverview
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleIncreaseStep={handleIncreaseStep}
            crm={crm}
            isEmailIntegration={isEmailIntegration}
          />
        );
      case tabs.CAMPAIGN_TYPE:
        return (
          <CampaignType
            setCampaignType={setCampaignType}
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
          />
        );
      case tabs.CAMPAIGN_DETAILS:
        return (
          <CampaignDetails
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
          />
        );
      case tabs.JOB_DESCRIPTION:
        return (
          <JobDescription
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            currentTempCampaign={currentTempCampaign}
          />
        );
      case tabs.SALES_STRATEGY:
        return (
          <SalesStrategy
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            crm={crm}
          />
        );
      case tabs.SELECT_RECIPIENTS:
        return (
          <SelectRecipients
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            onCloseWizard={onCloseWizard}
            crm={crm}
            isRecreatingCampaignIframe={isRecreatingCampaignIframe}
          />
        );
      case tabs.CONFIGURE_OPTIONS:
        return (
          <ConfigureOptions
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            onCloseWizard={onCloseWizard}
            isEmailIntegration={isEmailIntegration}
            isSmsIntegration={isSmsIntegration}
            isEstimatedTokenLoading={isEstimatedTokenLoading}
          />
        );
      case tabs.REVIEW_MESSAGES:
        return (
          <PreviewMessage
            tempCampaignId={tempCampaignId}
            preparedFields={prepareCreateCampaignFields()}
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            onCloseWizard={onCloseWizard}
            isEstimatedTokenLoading={isEstimatedTokenLoading}
            mayExceedCreditsData={mayExceedCreditsData}
            createCampaign={finishCreation}
            isShowCredits={isShowCredits}
          />
        );

      default:
        return <div>GOT ERROR IN STEPS</div>;
    }
  };

  const isCreditsCollapseBtnInCreditsPanel = activeTab === tabs.REVIEW_MESSAGES;

  return (
    <Form $isShowLogo={isShowCredits && isAllowShowCredits}>
      {isSavingDraft && <Loader text="Saving draft..." />}
      <Section $isShowLogo={isShowCredits && isAllowShowCredits}>
        {getActiveTabComponent()}
      </Section>
      {isAllowShowCredits && (
        <Credits
          $withCollapseBtn={isCreditsCollapseBtnInCreditsPanel}
          $isCollapse={!isShowCredits}
        >
          <CreditsCollapseBtn
            $inCredits={isCreditsCollapseBtnInCreditsPanel}
            $toRight={isShowCredits}
            onClick={() => setIsShowCredits(!isShowCredits)}
          >
            <ReceiveSquareIcon $toRight={isShowCredits} />
          </CreditsCollapseBtn>
          <EstimatedTokenCostCard
            isCollapsed={!isShowCredits}
            isLoading={isEstimatedTokenLoading}
            estimatedToken={estimatedToken}
            creditsRemaining={creditsRemaining}
          />
        </Credits>
      )}
    </Form>
  );
}

export default ContentSteps;

const Form = styled.form`
  padding: 28px 54px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ $isOverflowAuto }) => ($isOverflowAuto ? "overflow: auto;" : "")}
  ${({ $isShowLogo }) =>
    $isShowLogo
      ? `
      background-image: url(/images/logos/full-logo-black-o10.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 33px) calc(100% - 32px);
      background-size: auto 32px;
    `
      : ""}
`;

const Section = styled.div`
  padding: 0 0 28px;
  width: 100%;
  height: 100%;
  ${({ $isOverflowAuto }) =>
    $isOverflowAuto ? "min-height: fit-content;" : ""}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px 10px 0 0;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 20px 0px #0000000d;
  position: relative;
  transition: 0.3s all;
  ${({ $isShowLogo }) =>
    $isShowLogo
      ? `
      width: calc(100% - 238px);
    `
      : ""}
`;

const Credits = styled.div`
  position: relative;
  height: fit-content;
  border-radius: 10px;
  transition: all 300ms cubic-bezier(0.2, 0, 0, 1);

  ${({ $withCollapseBtn, $isCollapse }) =>
    $withCollapseBtn
      ? `
          padding-top: ${$isCollapse ? "60px" : "75px"};
    `
      : `
    
    `}

  ${({ $isCollapse }) =>
    $isCollapse
      ? `
          margin-right: -34px;
          margin-left: 18px;
          min-width: 35px;
          width: 35px;
        `
      : `
          margin-right: -29px;
          margin-left: 19px;
          min-width: 248px;
          width: 248px;
        `}
`;

const CreditsCollapseBtn = styled.div`
  position: absolute;
  top: 18px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  ${({ $inCredits, $toRight }) =>
    $inCredits
      ? `
          left: ${$toRight ? "10px" : "6px"};
          &:after {
            content: '';
            border-bottom: 1px solid #000;
            position: absolute;
            bottom: -7px;
            left: -4px;
            width: 29px;
          }
    `
      : `
          left: -60px;    
    `}

  svg {
    width: 100%;
    height: 100%;

    ${({ $toRight }) =>
      $toRight
        ? ""
        : `
            transform: rotate(180deg);
          `}
  }
`;
