import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import Login from "../components/Login";
import AskResetPassword from "../components/AskResetPassword";
import ResetPassword from "../components/ResetPassword";

import { removeLoginDataFromLocalStorage } from "../api/localStorage";
import { LOGIN_PAGE_CONTENT_LIST } from "../utils/constants";
import AuthLayout from "../components/AuthLayout";

const LoginPage = ({ loginPageContent }) => {
  const location = useLocation();
  const { resetToken, invited } = queryString.parse(location.search);
  const isInvited = invited === "true";

  useEffect(() => {
    removeLoginDataFromLocalStorage();
  }, []);

  return (
    <AuthLayout
      isShowSwitchAuthPageSection={
        loginPageContent !== LOGIN_PAGE_CONTENT_LIST.RESET_PASSWORD ||
        !isInvited
      }
      switchAuthPageText="Don’t have an account yet?"
      switchAuthPageUrl="/register"
      switchAuthPageBtn="Sign Up Now"
    >
      {loginPageContent === LOGIN_PAGE_CONTENT_LIST.ASK_RESET_PASSWORD ? (
        <AskResetPassword />
      ) : loginPageContent === LOGIN_PAGE_CONTENT_LIST.RESET_PASSWORD ? (
        <ResetPassword resetToken={resetToken} isInvited={isInvited} />
      ) : (
        <Login />
      )}
    </AuthLayout>
  );
};

export default LoginPage;
