import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { sendMessageToExtension } from "../utils/postToExtension.js";

function CloseBtn({ isVisible = true, onClose, width }) {
  const closeIframe = () => {
    if (onClose) {
      onClose();
      return;
    }
    sendMessageToExtension({
      message: "close-iframe",
    });
  };

  return (
    <CloseBtnBlock className="close-icon-block">
      {isVisible && (
        <CloseIcon
          src="../close-icon.png"
          alt="close"
          className="close-icon"
          onClick={closeIframe}
          $width={width}
        />
      )}
    </CloseBtnBlock>
  );
}

CloseBtn.propTypes = {
  isVisible: PropTypes.bool,
};

export default CloseBtn;

const CloseBtnBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
`;

const CloseIcon = styled.img`
  width: ${({ $width }) => `${$width || 35}px`};
  cursor: pointer;
  margin: 10px;
  &:hover {
    opacity: 0.85;
  }
`;
