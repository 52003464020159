import {
  fetchChangeCandidateSubscribe,
  fetchCandidates,
  fetchOptedOutCandidates,
  fetchCandidateMessages,
  fetchCandidatesSubscription,
  fetchGenerateReplyForMessage,
  fetchSendReplyMessage,
  fetchChangeCandidateStatus,
  fetchChangeContactSequenceStatus,
  fetchGetContact,
  fetchParseGoogleSheetsContacts,
} from "./queries.js";

const getCandidates = async (data) => {
  const result = await fetchCandidates(data);

  if (result.message) {
    return result;
  }
  return { success: true, result };
};

const getOptedOutCandidates = async (data) => {
  const result = await fetchOptedOutCandidates(data);

  if (result.message) {
    return result;
  }

  return { success: true, result };
};

const getCandidateMessages = async ({ campaignId, candidateId }) => {
  const result = await fetchCandidateMessages(campaignId, candidateId);

  if (result.message) {
    return result;
  }
  return { success: true, result };
};

const changeCandidateSubscribe = async (data) => {
  const result = await fetchChangeCandidateSubscribe(data);
  return result.success;
};

const getCandidatesSubscription = async (crm, emailsList) => {
  const result = await fetchCandidatesSubscription(crm, emailsList);

  if (result.message) {
    return result;
  }
  return { success: true, result };
};

const parseGoogleSheetsContacts = async (crmType, contacts) => {
  const result = await fetchParseGoogleSheetsContacts(crmType, contacts);

  if (result.message) {
    return result;
  }

  return { success: true, result };
};

const generateReplyForMessage = async (message) => {
  return await fetchGenerateReplyForMessage(message);
};

const sendReplyMessage = async (message, reply) => {
  return await fetchSendReplyMessage(message, reply);
};

const changeCandidateStatus = async (data) => {
  return await fetchChangeCandidateStatus(data);
};

const changeContactSequenceStatus = async (data) => {
  return await fetchChangeContactSequenceStatus(data);
};

const getContact = async (contactId) => {
  return await fetchGetContact(contactId);
};

export {
  getCandidates,
  getOptedOutCandidates,
  getCandidateMessages,
  changeCandidateSubscribe,
  getCandidatesSubscription,
  parseGoogleSheetsContacts,
  generateReplyForMessage,
  sendReplyMessage,
  changeCandidateStatus,
  changeContactSequenceStatus,
  getContact,
};
