import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 21px 17px 16.5px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.div`
  margin-left: 10px;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 16.38px;
  text-align: left;
`;

export const Content = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background_color};
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
`;

export const PreviewContainer = styled.div`
  padding: 28px 54px 0;
  height: 100%;
  width: 100%;
`;

export const Preview = styled.div`
  display: flex;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 20px 0px #0000000d;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 100%;
  width: 100%;
`;

export const MessageLabel = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 9px;
  align-items: center;
  justify-content: flex-start;
`;
