import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import { updateOpenAiModels } from "../../services/integrations";
import { sendMessageToExtension } from "../../utils/postToExtension";
import CustomSelect from "../CustomSelect";

const OpenAiModelsPopup = ({
  data: { openAiModels, defaultModelId, regularModelId, premiumModelId },
  onClose,
  isWizard,
}) => {
  const [fields, setFields] = useState({
    defaultModelId,
    regularModelId,
    premiumModelId,
  });

  const changeModel = (name, val) => {
    if (!val || fields[name] === val) {
      return;
    }

    const newFields = { ...fields, [name]: val };
    setFields(newFields);
  };

  const saveModels = async () => {
    const response = await updateOpenAiModels(fields);

    if (!response) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while updating OpenAI models",
        },
      });
    }

    onClose(fields);
  };

  return (
    <Container $isFullScreen={isWizard}>
      <SidePanel>
        <SidePanelContentTitle>OpenAI Models</SidePanelContentTitle>
        <SidePanelContentDescription>
          Please select the OpenAI models
        </SidePanelContentDescription>
      </SidePanel>
      <Content>
        <Title>OpenAI Models</Title>

        <OverflowContent>
          <GridItem>
            <Label>Default Model:</Label>
            <CustomSelect
              options={openAiModels}
              title="Default Model"
              width="100%"
              selected={defaultModelId}
              onSelect={(value) => changeModel("defaultModelId", value)}
            />
          </GridItem>
          <GridItem>
            <Label>Basic Model:</Label>
            <CustomSelect
              options={openAiModels}
              title="Basic Model"
              width="100%"
              selected={regularModelId}
              onSelect={(value) => changeModel("regularModelId", value)}
            />
          </GridItem>
          <GridItem>
            <Label>Genius Model:</Label>
            <CustomSelect
              options={openAiModels}
              title="Genius Model"
              width="100%"
              selected={premiumModelId}
              onSelect={(value) => changeModel("premiumModelId", value)}
            />
          </GridItem>
        </OverflowContent>
        <Tooltip
          id="field-tooltip"
          place="right"
          className="custom-tooltip"
          style={{
            maxWidth: 200,
            textTransform: "capitalize",
            wordBreak: "break-word",
          }}
        />
        <ButtonGroup>
          <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
          <SaveButton
            onClick={saveModels}
            disabled={
              defaultModelId === fields.defaultModelId &&
              regularModelId === fields.regularModelId &&
              premiumModelId === fields.premiumModelId
            }
          >
            Save
          </SaveButton>
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default OpenAiModelsPopup;

const Container = styled.div`
  position: absolute;
  top: ${({ $isFullScreen, theme }) =>
    $isFullScreen ? "0" : `${theme.navBarHeightPx}px`};
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${({ $isFullScreen, theme }) =>
    $isFullScreen ? "100%" : `calc(100% - ${theme.navBarHeightPx}px)`};
`;

const SidePanel = styled.div`
  width: 30%;
  background-color: #fff;
  padding: 60px 30px 30px 30px;
  display: flex;
  flex-direction: column;
`;

const SidePanelContentTitle = styled.div`
  font-size: 2em;
  font-weight: 800;
  line-height: 0.9em;
  color: #808080;
  margin-bottom: 20px;
  font-family: "AlbertSansExtraBold", sans-serif;
  letter-spacing: 0rem;
`;

const SidePanelContentDescription = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 20px;
`;

const Content = styled.div`
  width: 70%;
  background-color: ${({ theme }) => theme.colors.background_color};
  padding: 60px 45px 30px 45px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #cdcdcd;
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: calc(100% - 100px);
`;

const Title = styled.h3`
  font-size: 14pt;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
  margin-top: 30px;
`;

const CancelButton = styled.button`
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colors.btn_border_color};
  color: ${({ theme }) => theme.colors.btn_border_color};
  border-radius: 5px;
  height: 40px;
  width: 120px;
  background-color: transparent;
  font-weight: 700;
`;

const SaveButton = styled.button`
  cursor: pointer;
  color: white;
  border-radius: 5px;
  height: 40px;
  width: 180px;
  margin-left: 20px;
  border: none;
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  font-weight: 700;
  &:disabled {
    opacity: 0.75;
    pointer-events: none;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ $plusWidth }) =>
    $plusWidth ? `calc(40% + ${$plusWidth})` : "40%"};
  margin-left: 30px;
  + div {
    margin-top: 8px;
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;
