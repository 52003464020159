import React, { useRef } from "react";
import styled from "styled-components";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
} from "../../../utils/constants";
import Avatars from "../../Avatars";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";

function PreparingScreen({
  crm,
  isCancelBtnDisabled,
  progress,
  remainingMinutes,
  onCancelPrepareCampaign,
}) {
  const parentRef = useRef(null);
  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.preparingCampaign,
    parentRef,
  };

  return (
    <Container ref={parentRef}>
      <FirstTimeCampaignOnboarding config={firstTimeCampaignOnboardingConfig} />
      <img
        width={
          crm === CRMS.GOOGLE_SHEETS || crm === CRMS.EXCEL_ONLINE ? 130 : 100
        }
        src={`/images/${crm}-logo.${
          crm === CRMS.BULLHORN || crm === CRMS.EXCEL_ONLINE ? "png" : "svg"
        }`}
        alt={`${crm}-logo`}
      />
      <Avatars />
      <CircleProgressBlock>
        <ReachLogoBlock>
          <img
            src="/images/minimal-logo-pink.png"
            alt="reach logo"
            width="30"
          />
        </ReachLogoBlock>
        <CircleProgress $value={progress} />
      </CircleProgressBlock>
      <Title>Preparing your campaign</Title>
      <Description>
        Reach is now generating sample messages for your review. Once you've had
        an opportunity to preview the messages, you can click "Launch Campaign"
        to begin sending.
      </Description>
      <CancelPrepareBtn
        disabled={isCancelBtnDisabled}
        onClick={() => (isCancelBtnDisabled ? null : onCancelPrepareCampaign())}
      >
        Cancel Campaign Prep
      </CancelPrepareBtn>
      <ProgressBlock>
        <Progress $value={progress} />
      </ProgressBlock>
      <ProgressText>{progress}%</ProgressText>
      <RemainingTimeBlock>
        <span>{remainingMinutes} minutes</span>&nbsp;remaining
      </RemainingTimeBlock>
    </Container>
  );
}

export default PreparingScreen;

const Container = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 30px 0px #0000001a;
  height: calc(100vh - 58px);
  margin: 46px 57px 37px 57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
`;

const ProgressBlock = styled.div`
  background-color: #e8e8e8;
  width: 600px;
  height: 9px;
  border-radius: 9px;
  margin-top: 70px;
`;

const Progress = styled.div`
  background-color: #471168;
  height: 9px;
  border-radius: 9px;
  width: ${({ $value }) => $value}%;
`;

const ProgressText = styled.div`
  color: #471168;
  font-size: 17px;
  font-weight: 700;
  margin-top: 25px;
`;

const RemainingTimeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  height: 21px;
  padding: 0 14px;
  margin-top: 8px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 400;
  span {
    font-weight: 700;
  }
`;

const CircleProgressBlock = styled.div`
  position: relative;
  width: 114px;
  height: 114px;
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReachLogoBlock = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 50%;
`;

const CircleProgress = styled.div`
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 96%, transparent 80% 100%),
    conic-gradient(#471168 ${({ $value }) => $value}%, #e8e8e8 0);
`;

const Title = styled.div`
  font-size: 19px;
  font-weight: 700;
  margin-top: 18px;
`;

const Description = styled.div`
  font-size: 11px;
  font-weight: 400;
  margin-top: 10px;
  width: 380px;
  text-align: center;
  z-index: 100;
`;

const CancelPrepareBtn = styled.button`
  height: 40px;
  width: 176px;
  border-radius: 6px;
  border: 1px solid #757678;
  color: #757678;
  font-size: 12px;
  font-weight: 700;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-top: 21px;
  z-index: 100;
`;
