/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ContentSteps from "./ContentSteps/ContentSteps";
import ResumeCampaignStep from "./ContentSteps/ResumeCampaignStep";
import SidePanel from "./SidePanel";
import { styled } from "styled-components";
import { getWizardTabs } from "./utils";
import { CRMS } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { sendMessageToExtension } from "../../utils/postToExtension";
import { removeCreateCampaignDataFromLocalStorage } from "../../api/localStorage";
import { FIRST_WIZARD_TAB_NUM } from "./constants";
import Loader from "../Loader";
import getUserFromLocalStorage from "../../utils/getUserFromLocalStorage";
import {
  isUserInFirstTimeCampaignOnboardingUserList,
  updateFirstTimeCampaignOnboardingUserList,
} from "../../utils/utils";

function CampaignWizard({
  crm,
  isShowLoading = false,
  isResumeStep = false,
  date,
  handleResume,
  handleStartOver,
  currentTempCampaign,
  setCurrentTempCampaign,
}) {
  const location = useLocation();
  const {
    isMarketingCampaign,
    isEmailIntegration,
    isSmsIntegration,
    isRecreatingCampaignIframe,
  } = queryString.parse(location.search);

  const isBullhornCrm = crm === CRMS.BULLHORN;
  const isSpreadsheet = crm === CRMS.GOOGLE_SHEETS || crm === CRMS.EXCEL_ONLINE;
  const isCrmWithPostMessage = isBullhornCrm || isSpreadsheet;

  const [campaignData, setCampaignData] = useState(
    isBullhornCrm && isMarketingCampaign === "true"
      ? { isMarketingCampaign: true }
      : null
  );
  const [campaignType, setCampaignType] = useState(null);

  const [activeTab, setActiveTab] = useState(FIRST_WIZARD_TAB_NUM);

  const isExistsTempCampaign =
    currentTempCampaign?.activeTab && currentTempCampaign.name;
  const userInfo = getUserFromLocalStorage();

  const postMessageEventHandler = (event) => {
    const { jobDescription, publishedDescription, payRate, jobTitle } =
      event.data;

    const isWrongGsData = isSpreadsheet && !jobTitle;
    const isWrongBhData =
      isBullhornCrm && !jobDescription && !publishedDescription;

    if (isWrongBhData || isWrongGsData) {
      return;
    }

    window.removeEventListener("message", postMessageEventHandler);

    let campaignData = {
      jobTitle,
    };

    if (isBullhornCrm) {
      campaignData = {
        ...campaignData,
        jobDescription: {
          jobDescription,
          publishedDescription,
        },
        payRate,
        jobTitle,
      };
    }

    setCampaignData(campaignData);
  };

  const onClose = () => {
    removeCreateCampaignDataFromLocalStorage();
    sendMessageToExtension({
      message: "cancel-campaign-creation",
    });
  };

  useEffect(() => {
    if (isResumeStep) {
      return;
    }

    if (isExistsTempCampaign) {
      setCampaignData(null);

      setCampaignType(currentTempCampaign.type);

      const lastTab = getWizardTabs(
        crm,
        currentTempCampaign.type
      ).REVIEW_MESSAGES;

      let resumeTab = currentTempCampaign.activeTab;
      if (resumeTab === lastTab) {
        // resumeTab--;
      }
      setActiveTab(resumeTab);
    } else if (isCrmWithPostMessage && !campaignData?.isMarketingCampaign) {
      window.addEventListener("message", postMessageEventHandler);

      sendMessageToExtension({
        message: "get-job-description",
      });
    }
  }, [isResumeStep]);

  useEffect(() => {
    const isUserExist = isUserInFirstTimeCampaignOnboardingUserList({
      userId: userInfo?.id,
    });

    if (isUserExist) {
      return;
    }

    if (userInfo?.hasCreatedCampaign) {
      updateFirstTimeCampaignOnboardingUserList({
        userId: userInfo?.id,
        initializeWithDefaultNames: true,
      });
    }
  }, []);

  return (
    <main className="app">
      <Layout className="layout">
        <SidePanel
          activeTab={activeTab}
          crm={crm}
          type={campaignType}
          onClose={onClose}
        />
        <Section>
          {isShowLoading ? (
            <Loader parentSize={true} />
          ) : isResumeStep ? (
            <ResumeCampaignStep
              date={date}
              handleResume={handleResume}
              handleStartOver={handleStartOver}
            ></ResumeCampaignStep>
          ) : isCrmWithPostMessage && !campaignData && !isExistsTempCampaign ? (
            <div />
          ) : (
            <ContentSteps
              {...{ currentTempCampaign, setCurrentTempCampaign }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setCampaignType={setCampaignType}
              isRecreatingCampaignIframe={isRecreatingCampaignIframe}
              crm={crm}
              campaignData={campaignData}
              isEmailIntegration={isEmailIntegration === "true"}
              isSmsIntegration={isSmsIntegration === "true"}
              onCloseWizard={onClose}
            />
          )}
        </Section>
      </Layout>
    </main>
  );
}

export default CampaignWizard;

const Section = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background_color};
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
`;

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
