import { useState } from "react";
import { getOauthUserData, logIn } from "../services/auth";
import { Link } from "react-router-dom";
import ConnectBtn from "./ConnectBtn";
import { useGoogleLogin } from "@react-oauth/google";
import { useMsal } from "@azure/msal-react";

import {
  Actions,
  Content,
  ErrorIcon,
  ErrorMessage,
  Form,
  Input,
  InputBlock,
  Inputs,
  Label,
  LinkText,
  Relative,
  ShowPwdIcon,
  SignInRedirectBlock,
  SignInRedirectText,
  Title,
} from "./authStyles";
import { sendMessageToExtension } from "../utils/postToExtension";
import {
  EMAIL_REGEX,
  LAST_LOGIN_METHOD_LS_KEY,
  OAUTH_TYPES,
} from "../utils/constants";
import ThirdPartyOauthButtons from "./ThirdPartyOauthButtons";
import { AuthErrorCodes, loginRequest } from "../utils/auth-o365-config";
import { getDataFromLocalStorage } from "../api/localStorage";

const Login = () => {
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [pwdErrorMessage, setPwdErrorMessage] = useState("");

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setEmailErrorMessage("");
    setPwdErrorMessage("");

    setIsLoading(true);

    const result = await logIn({ email, password });

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsLoading(false);
      return;
    }

    setEmail("");
    setPassword("");

    setIsLoading(false);
  };

  const isFormValid = () => {
    let isEmailValid = true;
    let isPwdValid = true;

    if (!email || !EMAIL_REGEX.test(email) || email === "") {
      setEmailErrorMessage("Please enter a valid email address");
      isEmailValid = false;
    }

    if (!password || password === "") {
      setPwdErrorMessage("Please enter your password");
      isPwdValid = false;
    }

    if (!isEmailValid || !isPwdValid) {
      return false;
    }

    return true;
  };

  const handleGsuiteSignIn = useGoogleLogin({
    onSuccess: (response) => handleSuccessGsuiteSignIn(response),
    onError: (error) => handleErrorGuiteSignIn(error),
    flow: "auth-code",
  });

  const handleSuccessGsuiteSignIn = async (response) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const { code } = response;
    const resultGetData = await getOauthUserData({ code });

    if (!resultGetData.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: resultGetData.message,
        },
      });
      setIsLoading(false);
      return;
    }

    const { email } = resultGetData.result;
    const resultLogin = await logIn({ email, oauthType: OAUTH_TYPES.gsuite });

    if (!resultLogin.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: resultLogin.message,
        },
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
  };

  const handleErrorGuiteSignIn = (error) => {
    sendMessageToExtension({
      message: "show-error-message",
      data: {
        message: error?.message || "Unable to log in with Google",
      },
    });
  };

  const handleO365SignIn = async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      const response = await instance.loginPopup(loginRequest);
      const { account } = response;

      const resultLogin = await logIn({
        email: account.username,
        oauthType: OAUTH_TYPES.office365,
      });

      if (!resultLogin.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: resultLogin.message,
          },
        });
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    } catch (error) {
      if (error.errorCode !== AuthErrorCodes.userCancelled) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: error?.message || "Unable to log in with Microsoft",
          },
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <Content>
      <ThirdPartyOauthButtons
        handleGsuiteOauth={handleGsuiteSignIn}
        handleO365Oauth={handleO365SignIn}
        lastUsed={getDataFromLocalStorage(LAST_LOGIN_METHOD_LS_KEY)}
        isLogin
      />
      <Title>
        Sign In <span>With Email</span>
      </Title>
      <Form onSubmit={handleSubmit}>
        <Inputs>
          <InputBlock>
            <Label htmlFor="email">Your Email Address</Label>
            <Relative>
              <Input
                id="email"
                placeholder="Email address..."
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                onInput={(e) => setEmailErrorMessage("")}
                autoComplete="off"
              />
              {emailErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {emailErrorMessage && (
              <ErrorMessage>{emailErrorMessage}</ErrorMessage>
            )}
          </InputBlock>

          <InputBlock>
            <Label htmlFor="password">Password</Label>
            <Relative>
              <Input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Your password..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onInput={(e) => setPwdErrorMessage("")}
                autoComplete="off"
              />
              {pwdErrorMessage ? (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              ) : (
                <ShowPwdIcon
                  src="/images/show-pwd-icon.svg"
                  alt="show password"
                  onClick={toggleShowPassword}
                />
              )}
            </Relative>
            {pwdErrorMessage && <ErrorMessage>{pwdErrorMessage}</ErrorMessage>}
          </InputBlock>
        </Inputs>
        <Actions>
          <ConnectBtn
            large={true}
            type="submit"
            label="Sign In"
            disabled={isLoading}
          />
          <SignInRedirectBlock>
            <SignInRedirectText>Having Trouble Signing In?</SignInRedirectText>
            <Link to="/ask-reset-password">
              <LinkText>Click Here</LinkText>
            </Link>
          </SignInRedirectBlock>
        </Actions>
      </Form>
    </Content>
  );
};

export default Login;
