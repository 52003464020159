import React from "react";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import { Button } from "./styles";

function ConfigurationWizardButton() {
  const openConfigurationWizard = async (event) => {
    event.preventDefault();

    sendMessageToExtension({
      message: "open-configuration-wizard",
      data: {
        fromSettings: true,
      },
    });
  };

  return (
    <Button onClick={openConfigurationWizard}>Configuration Wizard</Button>
  );
}

export default ConfigurationWizardButton;
