import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

function ConnectBtn({
  large,
  label,
  onClick,
  disabled,
  type = "submit",
  props,
}) {
  return (
    <Btn
      $large={large}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <Content>{label}</Content>
    </Btn>
  );
}

ConnectBtn.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ConnectBtn;

const Btn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.red_disabled : theme.colors.red};
  height: 40px;
  padding: ${({ $large }) => `0 ${$large ? "45px" : "25px"}`};
  border-radius: 6px;
  border: none;
  + button {
    margin-left: 20px;
  }
  ${({ disabled }) =>
    disabled
      ? ""
      : `
        &:hover {
          opacity: 0.85;
        }
    `};
`;

const Content = styled.span`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 12px;

  @media (min-width: 2000px) {
    font-size: 14px;
  }
`;
