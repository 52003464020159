import React, { useState } from "react";
import { styled } from "styled-components";
import SampleFormPopup from "./SampleFormPopup";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import { USER_ROLES } from "../../../utils/constants";

function SamplesContent({
  samples,
  promptsTokens,
  updateSample,
  createSample,
  removeSample,
}) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [currentSample, setCurrentSample] = useState(null);
  const [isSampleFormPopupOpen, setIsSampleFormPopupOpen] = useState(false);
  const userInfo = getUserFromLocalStorage();

  const isAdmin = userInfo.role === USER_ROLES.ADMIN;

  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const openUpdateSamplePopup = (event, sample) => {
    event.preventDefault();
    setCurrentSample(sample);
    setIsSampleFormPopupOpen(true);
  };

  const onClose = (event) => {
    event.preventDefault();
    setCurrentSample(null);
    setIsSampleFormPopupOpen(false);
  };

  const openCreateSamplePopup = (event) => {
    event.preventDefault();
    const defaultSample = { name: "", fields: [{ title: "", text: "" }] };
    setCurrentSample({ ...defaultSample });
    setIsSampleFormPopupOpen(true);
  };

  const handleSaveSample = (data) => {
    setIsSampleFormPopupOpen(false);

    if (!data.id) {
      return createSample(data);
    }

    updateSample(data);
  };

  const handleRemoveSample = (event, sample) => {
    event.preventDefault();
    removeSample(sample.id);
  };

  return (
    <Content className="list">
      <OverflowContent>
        {samples.map((item, index) => (
          <SampleItem
            key={index}
            className="list-item"
            onClick={() => handleItemClick(index)}
          >
            <SampleTitleBlock>
              <SampleTitle>{item.name}</SampleTitle>
              <ExpandIcon
                src={
                  index === activeIndex
                    ? "/images/contract-list-item.png"
                    : "/images/expand-list-item.png"
                }
              ></ExpandIcon>
            </SampleTitleBlock>

            {index === activeIndex && (
              <SampleDetails>
                {item.fields.map((field, index) => (
                  <SampleTemplate key={index}>
                    <span>{field.title}:</span> {field.text}
                  </SampleTemplate>
                ))}
                {isAdmin && (
                  <SampleActions>
                    <SampleEditBtn
                      className="button button-primary"
                      onClick={(event) => openUpdateSamplePopup(event, item)}
                    >
                      Edit
                    </SampleEditBtn>
                    <SampleEditBtn
                      className="button button-primary"
                      onClick={(event) => handleRemoveSample(event, item)}
                    >
                      Remove
                    </SampleEditBtn>
                  </SampleActions>
                )}
              </SampleDetails>
            )}
          </SampleItem>
        ))}
      </OverflowContent>
      <CreateSampleBtn onClick={(event) => openCreateSamplePopup(event)}>
        Create sample
      </CreateSampleBtn>

      {isSampleFormPopupOpen && (
        <SampleFormPopup
          sample={currentSample}
          promptsTokens={promptsTokens}
          onSave={handleSaveSample}
          handleClose={onClose}
        />
      )}
    </Content>
  );
}

export default SamplesContent;

const Content = styled.div`
  height: calc(100% - 50px);
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: calc(100% - 25px);
`;

const SampleItem = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #e4e4e3;
`;

const SampleTitleBlock = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;

const SampleTitle = styled.div`
  font-size: 13px;
  display: flex;
`;

const ExpandIcon = styled.img`
  margin-right: 30px;
  width: 15px;
  height: 15px;
`;

const SampleDetails = styled.div`
  background-color: #f6f6f5;
  border-radius: 5px;
  font-weight: 300;
  line-height: 20.82px;
  padding: 15px;
  margin-top: 10px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
`;

const SampleTemplate = styled.div`
  font-size: 12px;
  font-family: "AlbertSansThin", sans-serif;
`;

const SampleEditBtn = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.saturated_purple};
  color: ${({ theme }) => theme.colors.saturated_purple};
  border-radius: 5px;
  height: 40px;
  padding: 0 25px;
  background-color: transparent;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
`;

const SampleActions = styled.div`
  align-self: end;
`;

const CreateSampleBtn = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  height: 40px;
  padding: 0 25px;
  border-radius: 5px;
  border: none;
  font-weight: 800;
  margin-top: 10px;
`;
