import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import {
  getPrompts,
  setPromptActive,
  createPrompt,
  updatePrompt,
} from "../../../services/prompts";
import PromptsContent from "./PromptsContent";
import Loader from "../../Loader";
import {
  DEFAULT_GROUP_OPTION,
  DEFAULT_VALUE_FOR_MODEL,
  USER_ROLES,
} from "../../../utils/constants";
import { toSnakeCase } from "../../../utils/utils";
import { getFilters } from "../../../services/campaigns";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";

function Prompts() {
  const [isLoading, setIsLoading] = useState(true);

  const [prompts, setPrompts] = useState([]);
  const [promptsTypes, setPromptsTypes] = useState({});
  const [promptsGroups, setPromptsGroups] = useState({});
  const [modelsOptions, setModelsOptions] = useState({});
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({
    crm: [],
    type: [],
    group: [DEFAULT_GROUP_OPTION.value],
    company: [],
  });
  const [sort, setSort] = useState({ sort: "active", order: "desc" });
  const userInfo = getUserFromLocalStorage();

  const isAdmin = userInfo.role === USER_ROLES.ADMIN;

  useEffect(() => {
    fetchModels();
    fetchPrompts();
  }, []);

  const fetchPrompts = async () => {
    const result = await getPrompts();
    if (!result.success) {
      setIsLoading(false);
      return;
    }
    const types = {};

    result.prompts.types.forEach((type) => {
      types[type.type] = type;
    });

    const groups = getGroups(result.prompts.types);

    setPrompts(result.prompts.prompts);
    setPromptsTypes(types);
    setPromptsGroups(groups);
    setIsLoading(false);
  };

  const getGroups = (types) => {
    return types.reduce((groups, type) => {
      const groupName = type.promptGroup;
      const groupCode = toSnakeCase(groupName);

      if (!groups[groupCode]) {
        groups[groupCode] = {
          code: groupCode,
          name: groupName,
        };
      }

      return groups;
    }, {});
  };

  const fetchModels = async () => {
    if (!isAdmin) {
      return;
    }

    const result = await getFilters({ filter: ["models", "companies"] });
    if (!result.success) {
      setIsLoading(false);
      return;
    }

    setModelsOptions([
      ...(result.result?.models || [])
        .sort((a, b) =>
          `${a.modelMode}--${a.modelId}`.localeCompare(
            `${b.modelMode}--${b.modelId}`
          )
        )
        .map((m) => ({
          value: m.objectId,
          label: `(${m.modelMode}) ${m.modelId}`,
        })),
      {
        value: DEFAULT_VALUE_FOR_MODEL,
        label: DEFAULT_VALUE_FOR_MODEL,
      },
    ]);
    setCompanies(
      (result.result?.companies || []).map((c) => ({
        value: c.id,
        label: c.name,
      }))
    );
    setIsLoading(false);
  };

  const setActivePrompt = async (prompt, crm) => {
    setIsLoading(true);
    const result = await setPromptActive({
      id: prompt.id ? prompt.id : "default",
      type: prompt.type,
      crm,
    });
    if (!result.success) {
      setIsLoading(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message || "Failed to activate prompt",
        },
      });
      return;
    }

    await fetchPrompts();
  };

  const deactivatePrompt = async (prompt, crm) => {
    setIsLoading(true);
    const result = await setPromptActive({
      id: "default",
      type: prompt.type,
      crm,
    });
    if (!result.success) {
      setIsLoading(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message || "Failed to deactivate prompt",
        },
      });
      return;
    }

    await fetchPrompts();
  };

  const handleUpdatePrompt = async (data) => {
    setIsLoading(true);
    const conditionalStep = data.sequence.find(
      (item) => item.role === "conditional"
    );
    if (conditionalStep) {
      conditionalStep.options = conditionalStep.content;
      delete conditionalStep.content;
    }
    const result = await updatePrompt(data);
    if (!result.success) {
      setIsLoading(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message || "Failed to update prompt",
        },
      });
      return;
    }

    await fetchPrompts();
  };

  const handleCreatePrompt = async (data) => {
    setIsLoading(true);
    const conditionalStep = data.sequence.find(
      (item) => item.role === "conditional"
    );
    if (conditionalStep) {
      conditionalStep.options = conditionalStep.content;
      delete conditionalStep.content;
    }

    const result = await createPrompt(data);
    if (!result?.success) {
      setIsLoading(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result?.message || "Failed to create prompt",
        },
      });
      return;
    }

    await fetchPrompts();
  };

  return (
    <Container>
      {isLoading && !prompts.length ? (
        <Loader parentSize={true} padding="0 20px 30px 0" />
      ) : (
        <Content>
          {isLoading && <Loader zIndex={11} />}

          <Title>Prompt Settings</Title>

          <PromptsContent
            prompts={prompts}
            promptsTypes={promptsTypes}
            promptsGroups={promptsGroups}
            modelsOptions={modelsOptions}
            companies={companies}
            setActivePrompt={setActivePrompt}
            deactivatePrompt={deactivatePrompt}
            createPrompt={handleCreatePrompt}
            updatePrompt={handleUpdatePrompt}
            filters={filters}
            setFilters={setFilters}
            sort={sort}
            setSort={setSort}
          />
        </Content>
      )}
    </Container>
  );
}

export default Prompts;

const Container = styled.div`
  padding: 0 20px 0 0;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  height: 100%;
  position: relative;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;
