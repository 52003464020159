import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { CRMS, USER_ROLES } from "../../utils/constants";
import getUserFromLocalStorage from "../../utils/getUserFromLocalStorage";
import {
  Account,
  Subscription,
  Prompts,
  EmailSignature,
  Samples,
  Unsubscribe,
  Usage,
  Users,
  Companies,
  Campaigns,
  MyTeam,
} from "../../components/Settings/TabContent";
import SetEmailProvider from "../../components/SetIntegrations/SetEmailProvider";
import SetSmsProvider from "../../components/SetIntegrations/SetSmsProvider";
import SetAiModel from "../../components/SetIntegrations/SetAiModel";
import SetCRM from "../../components/SetIntegrations/SetCRM";
import Deliverability from "../../components/Settings/TabContent/Deliverability";
import Scheduling from "../../components/Settings/TabContent/Scheduling";
import SimpleConnect from "../../components/Settings/TabContent/SimpleConnect";
import { sendMessageToExtension } from "../../utils/postToExtension";
import { getObjectFromLocalStorage } from "../../api/localStorage";
import {
  disconnectIntegration,
  getIntegrations,
} from "../../services/integrations";
import { SettingsContext } from "./context";
import SuggestionPopup from "../../components/SuggestionPopup";
import { AccountProvider } from "../../context";
import CheckExtensionVersion from "../../components/CheckExtensionVersion";

import { Container, Layout } from "./styles";
import Sidebar from "./Sidebar";
import { loadUserData } from "../../services/user";
import { fetchAutoConfigureLog } from "../../services/queries";
import Content from "./Content";
import { isCompanyAdvIntegrationsSettings, isNeedToShowPostSignUpOnboarding } from "../../utils/utils";

function Settings() {
  const userInfo = getUserFromLocalStorage();
  const company = getObjectFromLocalStorage("companyInfo").company;

  const isAllowedAdvIntegrations = isCompanyAdvIntegrationsSettings({
    company,
  });
  const isAllowedEmailIntegration =
    isAllowedAdvIntegrations || !!company?.allowSimpleConnect;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isOffice365Integration =
    isAllowedEmailIntegration && searchParams.get("isOffice365");
  const isGSuiteIntegration =
    isAllowedEmailIntegration && searchParams.get("isGSuite");
  const isHubspotIntegration = searchParams.get("isHubspot");
  const isCrmIntegration = searchParams.get("isCrm");
  const isAccessError = searchParams.get("isAccessError");
  const isFailedAutoConfigure = searchParams.get("isFailedAutoConfigure");
  const errorMessageForAutoConfigureLog = searchParams.get("errorMessage");
  const integrationDataToSet = location.state?.integrationData;
  const useEmailEnterpriseDataToSet =
    isAllowedAdvIntegrations &&
    integrationDataToSet?.type === "email" &&
    ["office365", "gsuite"].includes(integrationDataToSet.connectionKeys?.type);
  const useCrmIntegrationDataToSet =
    integrationDataToSet?.type === "crm" &&
    integrationDataToSet.connectionKeys?.type === CRMS.HUBSPOT;
  const isSubscriptionTab =
    String(searchParams.get("isSubscription")) === "true";
  const isEmailIntegrationSection =
    isAllowedEmailIntegration &&
    String(searchParams.get("isEmailInt")) === "true";
  const isSmsIntegrationSection =
    isAllowedAdvIntegrations && String(searchParams.get("isSmsInt")) === "true";

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuggestionPopupOpen, setIsSuggestionPopupOpen] = useState(
    Boolean(isAccessError)
  );

  const [filter, setFilter] = useState({});

  const isAdmin = userInfo?.role === USER_ROLES.ADMIN;
  const isCompanyAdmin = userInfo?.role === USER_ROLES.COMPANY_ADMIN;

  const SIDEBAR_TABS = {
    account: {
      title: "Account",
      component: () => <Account />,
    },
    integrations: {
      title: "Integrations",
      tabs: {
        crm: {
          title: "CRM",
          component: () => (
            <SetCRM
              onConnected={handleServiceConnected}
              connectionFields={integrations.crm}
              onDisconnect={handleDisconnectIntegration}
              {...(useCrmIntegrationDataToSet
                ? {
                    dataToSet: integrationDataToSet.connectionKeys,
                  }
                : null)}
            />
          ),
        },
        emailService: {
          title: "Email System",
          disabledContent: !isAllowedEmailIntegration,
          disabledContentTooltip: "Not allowed",
          component: () => (
            <SetEmailProvider
              onConnected={handleServiceConnected}
              connectionFields={integrations.email}
              onReloadIntegrations={fetchIntegrations}
              onDisconnect={handleDisconnectIntegration}
              {...(useEmailEnterpriseDataToSet
                ? {
                    enterpriseDataToSet: integrationDataToSet.connectionKeys,
                  }
                : null)}
            />
          ),
        },
        smsService: {
          title: "SMS Gateway",
          disabledContent: !isAllowedAdvIntegrations,
          disabledContentTooltip: "Not allowed",
          component: () => (
            <SetSmsProvider
              onConnected={handleServiceConnected}
              isConnected={connectedServices.includes("smsService")}
              connectionFields={integrations.sms}
              onDisconnect={handleDisconnectIntegration}
            />
          ),
        },
        aiModel: {
          title: "AI Model Provider",
          disabledContent: !isAllowedAdvIntegrations,
          disabledContentTooltip: "Not allowed",
          component: () => (
            <SetAiModel
              onConnected={handleServiceConnected}
              isConnected={connectedServices.includes("aiModel")}
              connectionFields={integrations.aiModel}
              onConnect={(data) =>
                setIntegrations({ ...integrations, aiModel: data })
              }
              onDisconnect={handleDisconnectIntegration}
            />
          ),
        },
        marketingEmailService: {
          title: "Marketing Email Service",
          disabledContent: true,
        },
      },
    },
    emailOptions: {
      title: "Email Options",
      tabs: {
        emailSignature: {
          title: "Signature",
          component: () => <EmailSignature />,
        },
        unsubscribe: {
          title: "Unsubscribe",
          component: () => <Unsubscribe />,
        },
        deliverability: {
          title: "Deliverability",
          component: () => <Deliverability />,
        },
        scheduling: {
          title: "Scheduling",
          component: () => <Scheduling />,
        },
      },
    },
    usage: {
      title: "Usage",
      component: () => <Usage />,
    },
  };

  if (isAdmin || isCompanyAdmin) {
    SIDEBAR_TABS.myTeam = {
      title: "My Team",
      component: () => <MyTeam />,
    };

    const isPromptsAllowed = isAdmin || company.allowPromptPlayground;
    if (isPromptsAllowed) {
      SIDEBAR_TABS.promptUtils = {
        title: "Prompts",
        tabs: {
          prompts: {
            title: "Prompts",
            component: () => <Prompts />,
          },
          samples: {
            title: "Samples",
            component: () => <Samples />,
          },
        },
      };
    }

    if (company.subscriptionPlanId) {
      SIDEBAR_TABS.subscription = {
        title: "Subscription & Billing",
        component: () => <Subscription />,
      };
    }
  }

  if (isAdmin) {
    SIDEBAR_TABS.admin = {
      title: "Admin",
      tabs: {
        simpleConnect: {
          title: "Simple Connect",
          component: () => (
            <SimpleConnect onReloadIntegrations={fetchIntegrations} />
          ),
        },
        users: {
          title: "Users",
          component: () => (
            <Users openCampaignsWithFilter={openCampaignsWithFilter} />
          ),
        },
        companies: {
          title: "Companies",
          component: () => (
            <Companies openCampaignsWithFilter={openCampaignsWithFilter} />
          ),
        },
        campaigns: {
          title: "Campaigns",
          component: () => (
            <Campaigns companyId={filter.companyId} userId={filter.userId} />
          ),
        },
      },
    };
  }

  const [integrations, setIntegrations] = useState({});
  const [connectedServices, setConnectedServices] = useState([]);
  const handleServiceConnected = (service) => {
    setConnectedServices([...connectedServices, service]);
  };

  const isCrmIntegrationTab =
    isCrmIntegration || isHubspotIntegration || useCrmIntegrationDataToSet;
  const isEmailIntegrationTab =
    isEmailIntegrationSection ||
    isOffice365Integration ||
    isGSuiteIntegration ||
    useEmailEnterpriseDataToSet;
  const isIntegrationsTab =
    isCrmIntegrationTab || isEmailIntegrationTab || isSmsIntegrationSection;

  const [activeTab, setActiveTab] = useState(
    isIntegrationsTab
      ? Object.keys(SIDEBAR_TABS.integrations.tabs)[
          isSmsIntegrationSection ? 2 : isEmailIntegrationTab ? 1 : 0
        ]
      : isSubscriptionTab
      ? "subscription"
      : "account"
  );
  const [styledTab, setStyledTab] = useState(
    isIntegrationsTab
      ? "integrations"
      : isSubscriptionTab
      ? "subscription"
      : "account"
  );

  function openCampaignsWithFilter(filter) {
    setFilter(filter);
    setStyledTab("admin");
    setActiveTab("campaigns");
  }

  useEffect(() => {
    const isHubspotAccountsPageWhenAutoConfigure =
      isFailedAutoConfigure &&
      errorMessageForAutoConfigureLog?.includes("isHubspotAccountsPage=true");

    if (isAccessError || isFailedAutoConfigure) {
      const handleError = async () => {
        await fetchAutoConfigureLog({
          logInfo: `[${
            isOffice365Integration
              ? "Office365"
              : isHubspotIntegration
              ? "HubSpot"
              : "GSuite"
          }] ${
            isAccessError
              ? "Access denied"
              : errorMessageForAutoConfigureLog ||
                "Failed to auto-configure integration"
          }`,
        });

        if (isHubspotAccountsPageWhenAutoConfigure) {
          sendMessageToExtension({
            message: "close-iframe",
          });
        }
      };

      if (isFailedAutoConfigure && !isHubspotAccountsPageWhenAutoConfigure) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message:
              "Something went wrong during integration auto-configuration",
          },
        });
      }

      handleError();
    }

    if (isHubspotAccountsPageWhenAutoConfigure) {
      return;
    }

    loadUserData(userInfo, (res) => {
      setIsLoadingUserData(false);

      if (!isNeedToShowPostSignUpOnboarding(res)) {
        fetchIntegrations();
      }
    });
  }, []);

  const fetchIntegrations = async () => {
    const integrations = await getIntegrations();

    if (!integrations.integration) {
      if (
        integrations.message &&
        activeTab === "emailService" &&
        styledTab === "integrations"
      ) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: integrations.message,
          },
        });
      }
      setIsLoading(false);
      return;
    }

    if (
      integrations.integration.crm &&
      Object.keys(integrations.integration.crm).length > 0
    ) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "crm",
      ]);
    }

    if (integrations.integration.email?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "emailService",
      ]);
    }

    if (integrations.integration.sms?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "smsService",
      ]);
    }

    if (integrations.integration.aiModel?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "aiModel",
      ]);
    }

    setIntegrations(integrations.integration);
    setIsLoading(false);

    return integrations.integration;
  };

  const handleDisconnectIntegration = async (type) => {
    const res = await disconnectIntegration({ type });
    if (res.success) {
      const isOffice365 = type === "office365";
      if (
        integrations.email.method === "oauth" &&
        (isOffice365 || type === "gsuite")
      ) {
        sendMessageToExtension({
          message: "show-success-message",
          data: {
            message: `Please revoke the "SalesStream.ai Reach" app's access to information in your ${
              isOffice365 ? "Office 365 " : "Google"
            } Account settings.`,
            title: "",
          },
        });
        setTimeout(() => {
          window.open(
            isOffice365
              ? "https://account.live.com/consent/Manage"
              : "https://myaccount.google.com/connections",
            "_blank"
          );
        }, 2900);
      }

      await fetchIntegrations();
    } else {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.message,
        },
      });
    }
    return res.success;
  };

  if (isLoadingUserData) {
    return (
      <AccountProvider>
        <CheckExtensionVersion />
      </AccountProvider>
    );
  }

  const getSuggestionPopupDesc = () => {
    if (isHubspotIntegration) {
      return "It looks like your HubSpot account does not have the required privileges to create a new Private App.  Please reach out to your HubSpot administrator for assistance.";
    }

    if (isOffice365Integration) {
      return "It looks like your Azure account does not have the required privileges to register an Application.  Please reach out to your Azure administrator for assistance.";
    }

    return "It looks like your Google Cloud account does not have the required privileges to create a new Project.  Please reach out to your Google administrator for assistance.";
  };

  return (
    <SettingsContext.Provider
      value={{
        sidebarTabs: SIDEBAR_TABS,
        activeTab,
        setActiveTab,
        styledTab,
        setStyledTab,
        fetchIntegrations,
        isCompanyAdmin,
      }}
    >
      {isSuggestionPopupOpen && (
        <SuggestionPopup
          title="Permission Denied"
          description={getSuggestionPopupDesc()}
          onConfirm={() => setIsSuggestionPopupOpen(false)}
          confirmBtnText="Got it!"
          removeCancel={true}
          imageSrc="/images/permission-denied-icon.svg"
        />
      )}
      <Layout>
        <Container>
          <Sidebar isAdmin={isAdmin} />
          <Content isLoading={isLoading} />
        </Container>
      </Layout>
    </SettingsContext.Provider>
  );
}

export default Settings;
