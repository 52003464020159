import styled from "styled-components";

export const MessageContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export const MessageText = styled.p`
  line-height: 27.84px;
  color: #5a5a5a;
`;
