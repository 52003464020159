import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import Navbar from "./Navbar";
import { AccountProvider } from "../context";
import CheckExtensionVersion from "./CheckExtensionVersion";

function MainContent() {
  const [isCampaignDetails, setIsCampaignDetails] = useState(false);

  return (
    <AccountProvider>
      <CheckExtensionVersion />
      <GlobalLayout>
        {!isCampaignDetails && <Navbar />}
        <Wrapper $isCampaignDetails={isCampaignDetails}>
          <Outlet
            context={{
              isCampaignDetails: [isCampaignDetails, setIsCampaignDetails],
            }}
          />
        </Wrapper>
      </GlobalLayout>
    </AccountProvider>
  );
}

export default MainContent;

const GlobalLayout = styled.main`
  height: 100%;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background_color};
  height: ${({ theme, $isCampaignDetails }) =>
    $isCampaignDetails ? "100vh" : `calc(100vh - ${theme.navBarHeightPx}px)`};
`;
