import React from "react";
import { styled } from "styled-components";

function RateCard({
  disabled,
  isHighlight,
  forDetails,
  isNumber,
  currentRate,
  changeRate,
  title,
  text,
  isLoading,
  $width,
  $padding,
  $withFit,
}) {
  const isCurrentRateHigher = Number(changeRate) > 0;

  return (
    <CardWrapper
      $disabled={disabled}
      $isHighlight={isHighlight && !disabled && !isLoading}
      $hasText={text !== undefined}
      $forDetails={forDetails}
      $width={$width ? $width : ""}
      $padding={$padding ? $padding : ""}
      $withFit={$withFit ? $withFit : false}
    >
      <CardTitle
        $disabled={disabled}
        $isHighlight={isHighlight && !disabled && !isLoading}
        $hasText={text !== undefined}
      >
        {title}
      </CardTitle>
      <CardContent $forDetails={forDetails}>
        {disabled ? (
          <Text $disabled={disabled}>Available after the campaign starts</Text>
        ) : isLoading ? (
          <img src="/images/ellipsis.png" alt="loader" />
        ) : text !== undefined ? (
          <Text $isHighlight={isHighlight && !disabled}>
            {text !== "" ? text : "No data"}
          </Text>
        ) : (
          <>
            <CurrentRate $isHighlight={isHighlight && !disabled}>
              {currentRate}
              {isNumber ? "" : "%"}
            </CurrentRate>
            {changeRate !== 0 && (
              <PreviousRate $color={isCurrentRateHigher}>
                <Icon
                  src={
                    isCurrentRateHigher
                      ? "/images/rate-up-icon.png"
                      : "/images/rate-down-icon.png"
                  }
                  alt="rate-icon"
                />
                <span>{changeRate}%</span>
              </PreviousRate>
            )}
          </>
        )}
      </CardContent>
    </CardWrapper>
  );
}

export default RateCard;

const Text = styled.div`
  color: ${({ theme, $disabled }) =>
    $disabled ? "#aeaca9" : theme.colors.white};
  font-size: ${({ $disabled }) => ($disabled ? "12px" : "14px")};
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-weight: 300;
  line-height: 15.68px;
  ${({ $isHighlight }) => ($isHighlight ? "padding: 0 18px;" : "")};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &:hover {
    -webkit-line-clamp: initial;
    overflow: visible;
    z-index: 999;
    background-color: ${({ theme, $disabled, $isHighlight }) =>
      $disabled
        ? "#faf8f6"
        : $isHighlight
        ? theme.colors.blue
        : theme.colors.white};
    box-shadow: -1px 3px 0
        ${({ theme, $disabled, $isHighlight }) =>
          $disabled
            ? "#faf8f6"
            : $isHighlight
            ? theme.colors.blue
            : theme.colors.white},
      1px 3px 0
        ${({ theme, $disabled, $isHighlight }) =>
          $disabled
            ? "#faf8f6"
            : $isHighlight
            ? theme.colors.blue
            : theme.colors.white};
    border-radius: 0 0 5px 5px;
  }
`;

const CardWrapper = styled.div`
  border: 1px solid
    ${({ theme, $isHighlight }) =>
      $isHighlight ? theme.colors.blue : theme.colors.rateCard_border_color};
  max-width: 207px;
  height: 130px;
  background-color: ${({ theme, $disabled, $isHighlight }) =>
    $disabled
      ? "#faf8f6"
      : $isHighlight
      ? theme.colors.blue
      : theme.colors.white};
  border-radius: 5px;
  padding: ${({ $isHighlight, $hasText }) =>
    `15px ${$isHighlight && $hasText ? 0 : "18px"} 18px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Icon = styled.img`
  width: 8px;
  height: 8px;
  margin-right: 6px;
`;

const CurrentRate = styled.span`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 29px;
  line-height: 33px;
  color: ${({ theme, $isHighlight }) =>
    $isHighlight ? theme.colors.white : theme.colors.black};
  font-weight: bold;
`;

const PreviousRate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  color: ${({ $color, theme }) =>
    $color ? theme.colors.green : theme.colors.red};
  background-color: white;
  box-shadow: 0 0 1px 4px white;
  border-radius: 5px;
`;

const CardTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 12px;
  font-weight: 800;
  line-height: 12.9px;
  text-transform: uppercase;
  color: ${({ theme, $disabled, $isHighlight }) =>
    $disabled
      ? "#c9c6c4"
      : $isHighlight
      ? theme.colors.white
      : theme.colors.gray};
  max-width: ${({ $isHighlight, $hasText }) =>
    $isHighlight && $hasText ? "calc(73% + 36px)" : "73%"};
  ${({ $isHighlight, $hasText }) =>
    $isHighlight && $hasText ? "padding: 0 18px;" : ""};
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  min-width: calc(
    ${({ $forDetails }) => ($forDetails ? "128px" : "207px")} -
      (2 * (18px + 1px))
  );
`;
