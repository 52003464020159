import { styled } from "styled-components";

export const Layout = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.navBarHeightPx}px)`};
  padding: 2.3% 3.3% 0 3.3%;
`;

export const Container = styled.div`
  display: flex;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 20px 0px #0000001a inset;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 100%;
`;
