import {
  fetchPrompts,
  fetchUpdatePrompt,
  fetchSetPromptActive,
  fetchSamples,
  fetchCreateSample,
  fetchUpdateSample,
  fetchRemoveSample,
  fetchTestPrompt,
} from "./queries.js";

const getPrompts = async () => {
  const result = await fetchPrompts();

  if (result.message) {
    return result;
  }
  return { success: true, prompts: result };
};

const createPrompt = async (data) => {
  if (!data.type) {
    return { success: false, message: "Missed type" };
  }

  if (!data.title) {
    return { success: false, message: "Missed title" };
  }

  if (!data.sequence?.length) {
    return { success: false, message: "Missed template" };
  }

  try {
    const result = await fetchUpdatePrompt(data);
    if (result.prompt) {
      return { success: true, result: result.prompt };
    }

    if (result.success === false) {
      return { success: false, message: result.message };
    }

    return result.prompt;
  } catch (error) {
    return { success: false, message: "Create prompt failed" };
  }
};

const updatePrompt = async (data) => {
  const result = await fetchUpdatePrompt(data);

  if (result.message) {
    return result;
  }
  return { success: true, prompts: result };
};

const setPromptActive = async (data) => {
  const result = await fetchSetPromptActive(data);

  if (result.message) {
    return result;
  }
  return { success: true, prompts: result };
};

const getSamples = async () => {
  const result = await fetchSamples();

  if (result.message) {
    return result;
  }
  return { success: true, samples: result };
};

const createSample = async (data) => {
  if (!data.name) {
    return { success: false, message: "Missed name" };
  }

  if (!data.fields?.length) {
    return { success: false, message: "Missed fields" };
  }

  try {
    const result = await fetchCreateSample(data);

    if (result.sample) {
      return { success: true, result: result.sample };
    }
    return result.sample;
  } catch (error) {
    return { success: false, message: "Create sample failed" };
  }
};

const updateSample = async (data) => {
  const result = await fetchUpdateSample(data);

  if (result.message) {
    return result;
  }
  return { success: true, samples: result };
};

const removeSample = async (sampleId) => {
  const result = await fetchRemoveSample(sampleId);

  if (result.message) {
    return result;
  }
  return { success: true, samples: result };
};

const testPrompt = async (data) => {
  const result = await fetchTestPrompt(data);

  if (result.message) {
    return result;
  }
  return { success: true, samples: result };
};

export {
  getPrompts,
  createPrompt,
  updatePrompt,
  setPromptActive,
  getSamples,
  createSample,
  updateSample,
  removeSample,
  testPrompt,
};
