import styled from "styled-components";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-radius: 6px;
  height: ${({ $small }) => ($small ? "35px" : "40px")};
  min-width: 93px;
  padding: ${({ $padding, $large }) => $padding || `0 ${$large ? 28 : 13}px`};
  margin: ${({ $margin }) => $margin || 0};
  font-weight: ${({ $small }) => ($small ? "400" : "700")};
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  ${({ disabled }) =>
    disabled
      ? ""
      : `
        &:hover {
          opacity: 0.85;
        }
    `};
`;

export const PrimaryButton = styled(Button)`
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.red_disabled : theme.colors.red};
  color: white;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.red_disabled : theme.colors.red};
`;

export const SecondaryButton = styled(Button)`
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.gray2_disabled : theme.colors.gray2};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray2_disabled : theme.colors.gray2};
  background-color: transparent;
`;

export const ArrowBackIconBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $disabled, onClick }) =>
    !onClick ? "default" : $disabled ? "not-allowed" : "pointer"};
  margin-right: 18px;
  width: 18px;
  height: 19px;

  &::after {
    content: "";
    cursor: default;
    position: absolute;
    left: 8px;
    top: calc(-50% - 4px);
    height: 45px;
    width: 1px;
    background-color: #0000004d;
  }

  ${({ $disabled, onClick, theme }) =>
    !onClick || $disabled
      ? ""
      : `
            &:hover {
              box-shadow: 0 0 5px 2px ${theme.colors.background_color};
              border-radius: 50%;
            }
  `};
`;

export const ChevronLeftIcon = styled.img.attrs({
  src: "/images/circle-chevron-left.svg",
})`
  width: 18px;
  z-index: 1;
`;

export const CloseIcon = styled.img.attrs({
  src: "/images/circle-close.svg",
})`
  width: 18px;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

export const QuestionIcon = styled.img.attrs({
  src: "/images/circle-question.svg",
})`
  width: 18px;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

export const DividerVert = styled.img.attrs({
  src: "/images/divider-vert.svg",
})`
  ${({ $margin }) => ($margin ? `margin: ${$margin};` : "")};
  height: 45px;
  width: 1px;
  min-width: 1px;
  opacity: ${({ $opacity }) => $opacity || 0.1};
`;

export const DateRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  > span {
    margin: 0 5px;
  }
  > .react-datepicker-wrapper {
    > .react-datepicker__input-container > input {
      margin: 0;
      width: 97px;
      border-radius: 4px;
      border-color: #ccc;
      box-shadow: none;
      font-size: 12px;
    }
  }
  .react-datepicker-popper {
    z-index: 11;
    .react-datepicker__month-container {
      font-size: 12px;
      .react-datepicker__current-month {
        font-size: 12px;
      }
      .react-datepicker__day-name,
      .react-datepicker__day {
        margin: 3px;
        width: 21px;
        line-height: 21px;
      }
    }
  }
`;
