import { useState } from "react";
import { askResetPassword } from "../services/auth";
import ConnectBtn from "./ConnectBtn";
import { useNavigate } from "react-router-dom";

import {
  Actions,
  ErrorIcon,
  ErrorMessage,
  Form,
  Input,
  InputBlock,
  Inputs,
  Label,
  Relative,
  Title,
} from "./authStyles";
import { sendMessageToExtension } from "../utils/postToExtension";
import { EMAIL_REGEX } from "../utils/constants";
import { getDataFromLocalStorage } from "../api/localStorage";

const AskResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setEmailErrorMessage("");

    setIsLoading(true);

    const extensionId = getDataFromLocalStorage("extensionId");

    const result = await askResetPassword({ email, extensionId });

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsLoading(false);
      return;
    }

    sendMessageToExtension({
      message: "show-success-message",
      data: {
        message: "A password reset email has been sent to you",
        title: "",
      },
    });
    setEmail("");

    setIsLoading(false);
    navigate("/login");
  };

  const isFormValid = () => {
    let isEmailValid = true;

    if (!email || !EMAIL_REGEX.test(email) || email === "") {
      setEmailErrorMessage("Please enter a valid email address");
      isEmailValid = false;
    }

    return isEmailValid;
  };

  return (
    <section>
      <Title>Reset Password</Title>

      <Form onSubmit={handleSubmit}>
        <Inputs>
          <InputBlock>
            <Label htmlFor="email">Your Email Address</Label>
            <Relative>
              <Input
                id="email"
                placeholder="Email address..."
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                onInput={(e) => setEmailErrorMessage("")}
                autoComplete="off"
              />
              {emailErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {emailErrorMessage && (
              <ErrorMessage>{emailErrorMessage}</ErrorMessage>
            )}
          </InputBlock>
        </Inputs>

        <Actions>
          <ConnectBtn
            large={true}
            type="submit"
            label="Send Reset Email"
            disabled={isLoading}
          />
        </Actions>
      </Form>
    </section>
  );
};

export default AskResetPassword;

