import styled from "styled-components";

export const MainContainer = styled.li`
  display: flex;
  flex-direction: column;
  padding: ${({ $isSelected }) =>
    $isSelected ? "30px 24px 27px 24px" : "21px 20px"};
  cursor: ${({ $isSelected }) => ($isSelected ? "auto" : "pointer")};
  border-radius: 8px;
  width: 100%;
  /* max-width: ${({ $isSelected }) => ($isSelected ? "340px" : "276px")}; */
  max-width: ${({ $isSelected }) => ($isSelected ? "369px" : "276px")};
  min-width: ${({ $isSelected }) => ($isSelected ? "180px" : "120px")};
  /* height: ${({ $isSelected }) => ($isSelected ? "329px" : "282px")}; */
  height: ${({ $isSelected }) => ($isSelected ? "366px" : "290px")};
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#f95959" : "#f6f6f6"};
  border: ${({ $isSelected }) =>
    $isSelected ? "4px solid #f29c9c" : "1px solid #fff"};
  box-sizing: border-box;
  box-shadow: ${({ $isSelected }) =>
    $isSelected ? "0px 3px 7px 0px rgba(0, 0, 0, 0.5)" : "none"};
  z-index: ${({ $isSelected }) => ($isSelected ? "1" : "0")};
`;

export const Name = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: ${({ $isSelected }) => ($isSelected ? "30px" : "20px")};
  font-weight: 800;
  line-height: ${({ $isSelected }) => ($isSelected ? "34.8px" : "23.2px")};
  text-align: center;
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#000")};
`;

export const Description = styled.div`
  font-family: "Poppins", sans-serif;
  margin-top: 9px;
  font-size: ${({ $isSelected }) => ($isSelected ? "13px" : "12px")};
  font-weight: 400;
  line-height: ${({ $isSelected }) => ($isSelected ? "18.2px" : "16.08px")};
  text-align: center;
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#636363")};
  padding: 0 10px;
  max-height: 120px;
`;

export const PlanItem = styled.li`
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.08px;
  color: #636363;
  line-height: ${({ $isSelected }) => ($isSelected ? "18.2px" : "16.08px")};
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#636363")};
  font-size: ${({ $isSelected }) => ($isSelected ? "13px" : "12px")};
`;

export const ItemText = styled.span`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;

export const Price = styled.p`
  font-family: "Poppins", sans-serif;
  margin-top: 15px;
  font-size: 16px;
  line-height: 21.4px;
  text-align: center;
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#646464")};
`;

export const TextHighlight = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 700;
`;

export const Button = styled.button`
  font-family: "Poppins", sans-serif;
  margin: 25px auto 0 auto;
  padding: 10.5px 30.5px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#fff" : "transparent"};
  color: ${({ $isSelected }) => ($isSelected ? "#000" : "#3c3c3c")};
  border: ${({ $isSelected }) =>
    $isSelected ? "4px solid #ce3838" : "1px solid #a1a1a1"};
  box-shadow: ${({ $isSelected }) =>
    $isSelected ? "0px 0px 10px 0px #0000001a" : "none"};
`;

export const Benefits = styled.ul`
  min-height: 57px;
`;

export const AiCreditMonthlyContainer = styled.div`
  width: 100%;
  min-height: 30px;
  margin-bottom: 10px;
`;

export const AiCreditMonthly = styled.p`
  font-family: "Poppins", sans-serif;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: ${({ $isSelected }) => ($isSelected ? "18.2px" : "16.08px")};
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#636363")};
  font-size: ${({ $isSelected }) => ($isSelected ? "13px" : "12px")};
`;
