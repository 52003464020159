import React, { useEffect, useRef, useState } from "react";

import LeftSidePanel from "./LeftSidePanel";
import RightSidePanel from "./RightSidePanel";
import {
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from "../../../api/localStorage";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  getPreviewMessage,
  updatePreviewMessage,
} from "../../../services/campaigns";
import { CONTENT_TYPES } from "./constants";

import {
  Header,
  HeaderLeft,
  Title,
  Content,
  Preview,
  MessageLabel,
  Container,
  PreviewContainer,
} from "./styles";
import { ArrowBackIconBlock, ChevronLeftIcon } from "../../styles";

import { faEnvelope, faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PreviewMessage1({ campaign, candidates, handleBack }) {
  const textareaRef = useRef(null);

  const [activeCandidateIndex, setActiveCandidateIndex] = useState(0);
  const [currentSequence, setCurrentSequence] = useState(null);
  const [currentMessageAttempt, setCurrentMessageAttempt] = useState(null);
  const [currentMessageType, setCurrentMessageType] = useState(null);
  const [currentMessageSubject, setCurrentMessageSubject] = useState(null);
  const [currentMessageText, setCurrentMessageText] = useState(null);
  const [currentPrompt, setCurrentPrompt] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  const [updatedMessageSubject, setUpdatedMessageSubject] = useState(null);
  const [updatedMessageText, setUpdatedMessageText] = useState(null);

  const [viewedCandidatesIds, setViewedCandidatesIds] = useState([]);
  const [activeContent, setActiveContent] = useState(CONTENT_TYPES.EMAIL);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoadFailed, setIsLoadFailed] = useState(false);

  const [isRegenerating, setIsRegenerating] = useState(false);

  const filteredCandidates = candidates.filter((item) => {
    return !item.isInvalid;
  });

  const toggleActiveContent = (e) => {
    e.preventDefault();

    if (activeContent === CONTENT_TYPES.EMAIL) {
      setActiveContent(CONTENT_TYPES.PROMPT);
    } else {
      setActiveContent(CONTENT_TYPES.EMAIL);
    }
  };

  const fetchPreviewMessage = async (isRegenerate, messageAttempt) => {
    setIsLoading(true);
    if (isRegenerate) {
      setIsRegenerating(true);
    }

    const campaignId = campaign.id;
    const candidateId = filteredCandidates[activeCandidateIndex].externalId;

    const data = await getPreviewMessage({
      campaignId,
      candidateId,
      regenerate: isRegenerate,
      attempt: messageAttempt || currentMessageAttempt || 1,
    });

    if (data.success !== undefined && !data.success) {
      setIsLoadFailed(true);
      setIsLoading(false);
      setIsRegenerating(false);
      return;
    }

    markCandidateAsViewed(candidateId, campaignId);

    setCurrentSequence(
      data.result.sequence?.map((s) => ({
        value: s.attempt,
        label: (
          <MessageLabel>
            <FontAwesomeIcon
              icon={s.type === "sms" ? faMessage : faEnvelope}
              color="#787878"
            />
            {`Message #${s.attempt}`}
          </MessageLabel>
        ),
      }))
    );
    setCurrentMessageAttempt(data.result.message.attempt);
    setCurrentMessageType(data.result.message.type);
    setCurrentMessageSubject(data.result.message.subject);
    setCurrentMessageText(data.result.message.text);
    setCurrentPrompt(data.result.prompt || []);

    setUpdatedMessageSubject(data.result.message.subject);
    setUpdatedMessageText(data.result.message.text);

    setActiveContent(CONTENT_TYPES.EMAIL);

    setIsEditing(false);

    setIsLoadFailed(false);

    setIsLoading(false);

    setIsRegenerating(false);
  };

  const handleSelectMessage = (messageAttempt) => {
    fetchPreviewMessage(false, messageAttempt);
  };

  const editMessage = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const campaignId = campaign.id;
    const candidateId = filteredCandidates[activeCandidateIndex].externalId;

    const data = await updatePreviewMessage({
      campaignId,
      candidateId,
      data: {
        attempt: currentMessageAttempt,
        text: updatedMessageText,
        subject: updatedMessageSubject,
      },
    });

    if (data.success !== undefined && !data.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Unfortunately, we had trouble editing the preview message",
        },
      });
      handleBack();
      setIsLoading(false);
      return;
    }

    setCurrentMessageSubject(updatedMessageSubject);
    setCurrentMessageText(updatedMessageText);

    setIsEditing(false);
    setIsLoading(false);
  };

  const getViewedCandidatesFromStorage = () => {
    return getObjectFromLocalStorage("viewedCandidatesIds") || {};
  };

  const markCandidateAsViewed = (candidateId, campaignId) => {
    const viewedAllIdsFromStorage = getViewedCandidatesFromStorage();
    const viewedIdsFromStorage = viewedAllIdsFromStorage[campaignId] || [];
    const viewedIdsSet = new Set([...viewedIdsFromStorage, candidateId]);

    setViewedCandidatesIds(Array.from(viewedIdsSet));
    saveObjectToLocalStorage("viewedCandidatesIds", {
      ...viewedAllIdsFromStorage,
      [campaignId]: Array.from(viewedIdsSet),
    });
  };

  const setTextareaHeight = () => {
    const textarea = textareaRef.current;

    if (textarea?.style) {
      textarea.style.height = "19px";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTextareaHeight();
      setUpdatedMessageSubject(currentMessageSubject);
      setUpdatedMessageText(currentMessageText);
    }
  }, [isEditing]);

  useEffect(() => {
    const campaignId = campaign.id;

    if (!campaignId) {
      return;
    }

    const viewedCandidatesIds = getViewedCandidatesFromStorage();

    setViewedCandidatesIds(viewedCandidatesIds[campaignId] || []);
  }, [campaign]);

  const handleInput = (e) => {
    e.preventDefault();

    setUpdatedMessageSubject(e.target.value);

    setTextareaHeight();
  };

  const regenerateMessage = (e) => {
    e.preventDefault();

    fetchPreviewMessage(true);
  };

  useEffect(() => {
    fetchPreviewMessage(false, 1);
  }, [activeCandidateIndex]);

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <ArrowBackIconBlock onClick={handleBack}>
            <ChevronLeftIcon />
          </ArrowBackIconBlock>
          <Title>Preview</Title>
        </HeaderLeft>
      </Header>
      <Content>
        <PreviewContainer>
          <Preview>
            <LeftSidePanel
              viewedCandidatesIds={viewedCandidatesIds}
              filteredCandidates={filteredCandidates}
              activeCandidateIndex={activeCandidateIndex}
              setActiveCandidateIndex={setActiveCandidateIndex}
            />
            <RightSidePanel
              isLoading={isLoading}
              campaign={campaign}
              currentSequence={currentSequence}
              currentMessageAttempt={currentMessageAttempt}
              filteredCandidates={filteredCandidates}
              activeCandidateIndex={activeCandidateIndex}
              handleSelectMessage={handleSelectMessage}
              activeContent={activeContent}
              isEditing={isEditing}
              isLoadFailed={isLoadFailed}
              setIsEditing={setIsEditing}
              editMessage={editMessage}
              regenerateMessage={regenerateMessage}
              toggleActiveContent={toggleActiveContent}
              currentMessageType={currentMessageType}
              currentPrompt={currentPrompt}
              isRegenerating={isRegenerating}
              currentMessageSubject={currentMessageSubject}
              currentMessageText={currentMessageText}
              updatedMessageText={updatedMessageText}
              setUpdatedMessageText={setUpdatedMessageText}
              textareaRef={textareaRef}
              handleInput={handleInput}
              updatedMessageSubject={updatedMessageSubject}
            />
          </Preview>
        </PreviewContainer>
      </Content>
    </Container>
  );
}

export default PreviewMessage1;
