/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { AccountProvider } from "../context";
import { styled } from "styled-components";
import { sendMessageToExtension } from "../utils/postToExtension";
import { fetchGetPrepCampaigns } from "../services/queries";
import { useNavigate } from "react-router-dom";
import {
  calcCampaignProgress,
  calcCampaignRemainingMinutes,
  updateFirstTimeCampaignOnboardingUserList,
} from "../utils/utils";
import {
  getObjectFromLocalStorage,
  removeDataFromLocalStorage,
  saveObjectToLocalStorage,
} from "../api/localStorage";
import { createCampaign } from "../services/campaigns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import { FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS } from "../utils/constants";
import SuggestionPopup from "../components/SuggestionPopup";

const MinimizedCampaignPrep = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const createdAtMs = Number(searchParams.get("createdAtMs"));
  const isNeedToShowWarning =
    searchParams.get("isNeedToShowWarning") === "true";

  const userInfo = getUserFromLocalStorage();

  const campaignToMinimize = getObjectFromLocalStorage("campaignToMinimize");

  const [campaigns, setCampaigns] = useState(
    campaignToMinimize?.id || campaignToMinimize?.createdAtMs
      ? [
          {
            ...campaignToMinimize,
            progress: calcCampaignProgress(campaignToMinimize),
            remainingMinutes: calcCampaignRemainingMinutes(campaignToMinimize),
          },
        ]
      : []
  );
  const [isExpanded, setIsExpanded] = useState(true);
  const [isShowWarning, setIsShowWarning] = useState(isNeedToShowWarning);

  const timerRef = useRef();

  const isCreatingCampaign = (campaign) => !campaign.id;

  const isFailedCreation = (campaign) => !!campaign.creationError;

  const getCampaignProgress = (campaign) => {
    if (isCreatingCampaign(campaign) || isFailedCreation(campaign)) {
      return 0;
    }
    return campaign.progress;
  };

  const isCampaignPrepared = (campaign) => campaign.progress === 100;

  const handleCloseWarning = () => {
    setIsShowWarning(false);
    updateFirstTimeCampaignOnboardingUserList({
      userId: userInfo?.id,
      name: FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.prepareCampaign.name,
    });
    sendMessageToExtension({
      message: "minimize-campaign-prep",
      data: { noWarning: true },
    });
  };

  const handleToggleExpand = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    const expanded = !isExpanded;
    sendMessageToExtension({
      message: "minimize-campaign-prep",
      data: { expand: expanded },
    });
    setIsExpanded(expanded);
  };

  const handleMaximize = (event, idx) => {
    event?.preventDefault();
    event?.stopPropagation();

    let campaignToSelect = campaigns[idx || 0];
    if (
      isCreatingCampaign(campaignToSelect) ||
      isFailedCreation(campaignToSelect)
    ) {
      if (typeof idx === "number") {
        return;
      }

      campaignToSelect = campaigns.find(
        (c) => !(isCreatingCampaign(c) || isFailedCreation(c))
      );
      if (!campaignToSelect) {
        return;
      }
    }

    clearTimeout(timerRef.current);

    handleCloseWarning();

    navigate("/campaigns", {
      state: { campaignToSelect },
    });
    sendMessageToExtension({
      message: "change-iframe-window-type",
      data: {
        iframeWindowType: "drawer",
      },
    });
  };

  const loadCampaigns = async (prevCampaigns) => {
    const res = await fetchGetPrepCampaigns();
    const prepCampaigns = res?.campaigns;

    if (!prepCampaigns) {
      sendMessageToExtension({
        message: "close-iframe",
      });
      return;
    }

    const updCampaigns = [...prevCampaigns];

    updCampaigns.forEach((campaign) => {
      const prepCampaignIdx = prepCampaigns.findIndex(
        ({ id, createdAtMs }) =>
          (id && id === campaign.id) ||
          (createdAtMs && createdAtMs === campaign.createdAtMs)
      );
      const prepCampaign = prepCampaigns[prepCampaignIdx];
      if (prepCampaignIdx !== -1) {
        campaign.progress = calcCampaignProgress(prepCampaign);
        campaign.remainingMinutes = calcCampaignRemainingMinutes(prepCampaign);
        campaign.generationDurationMs = prepCampaign.generationDurationMs;
        campaign.id = prepCampaign.id;
        campaign.creationError = prepCampaign.creationError;
        prepCampaigns.splice(prepCampaignIdx, 1);
      } else if (
        !isCampaignPrepared(campaign) &&
        !(isCreatingCampaign(campaign) || isFailedCreation(campaign))
      ) {
        campaign.progress = 100;
        campaign.remainingMinutes = 0;
        if (campaign.generationDurationMs < 0) {
          campaign.generationDurationMs =
            new Date().getTime() + campaign.generationDurationMs;
        }
      }
    });

    updCampaigns.push(
      ...prepCampaigns.map((campaign) => ({
        ...campaign,
        progress: calcCampaignProgress(campaign),
        remainingMinutes: calcCampaignRemainingMinutes(campaign),
      }))
    );

    if (updCampaigns.every(isFailedCreation)) {
      removeDataFromLocalStorage("campaignToMinimize");

      sendMessageToExtension({
        message: "create-campaign-error",
      });

      sendMessageToExtension({
        message: "close-iframe",
      });

      return;
    }

    if (campaignToMinimize) {
      const updMinCampaign = updCampaigns.find(
        (c) =>
          (c.id && c.id === campaignToMinimize.id) ||
          (c.createdAtMs && c.createdAtMs === campaignToMinimize.createdAtMs)
      );
      if (updMinCampaign) {
        saveObjectToLocalStorage("campaignToMinimize", updMinCampaign);
        if (updCampaigns.every(isCampaignPrepared)) {
          setCampaigns([updMinCampaign]);
          return;
        }
      } else {
        removeDataFromLocalStorage("campaignToMinimize");
      }
    }

    setCampaigns(updCampaigns);

    timerRef.current = setTimeout(() => loadCampaigns(updCampaigns), 2000);
  };

  const fetchCreateCampaign = async () => {
    const result = await createCampaign({
      campaign: campaignToMinimize,
    });

    if (result.success !== undefined && !result.success) {
      console.log("create temp campaign error", result.message);

      sendMessageToExtension({
        message: "create-campaign-error",
      });
      return;
    }

    return result.result.campaign;
  };

  useEffect(() => {
    if (
      createdAtMs &&
      campaignToMinimize?.createdAtMs === createdAtMs &&
      !campaignToMinimize.id
    ) {
      const create = async () => {
        const createdCampaign = await fetchCreateCampaign();

        if (!createdCampaign) {
          removeDataFromLocalStorage("campaignToMinimize");

          sendMessageToExtension({
            message: "close-iframe",
          });
          return;
        }

        saveObjectToLocalStorage("campaignToMinimize", createdCampaign);

        const updMinCampaign = {
          ...createdCampaign,
          progress: calcCampaignProgress(createdCampaign),
          remainingMinutes: calcCampaignRemainingMinutes(createdCampaign),
        };
        setCampaigns([updMinCampaign]);
        loadCampaigns([updMinCampaign]);
      };
      create();
      return;
    }

    loadCampaigns(campaigns);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const isLoading = Boolean(campaignToMinimize && !campaigns?.length);
  const isAllPrepared = isLoading
    ? false
    : campaigns?.every(isCampaignPrepared) || false;
  const avgProgress = (function () {
    const campaignsInProgress = campaigns?.filter(
      (c) => getCampaignProgress(c) > 0
    );
    if (!campaignsInProgress?.length) {
      return 0;
    }
    if (isAllPrepared) {
      return 100;
    }
    const prepCampaigns = campaignsInProgress.filter(
      (c) => !isCampaignPrepared(c)
    );
    return Math.round(
      prepCampaigns.reduce((a, c) => a + getCampaignProgress(c), 0) /
        prepCampaigns.length
    );
  })();
  const isSingleCampaign = campaigns?.length === 1;
  const isSinglePrepared = isSingleCampaign && isAllPrepared;

  const displayRemainingMinutes = (campaign) => {
    const { remainingMinutes } = campaign;
    const text = `${
      remainingMinutes !== null ? remainingMinutes : "∞"
    } minutes`;
    return (
      <>
        {isSinglePrepared && <RemainingMinutesSep />}
        <RemainingMinutes $isSingle={isSinglePrepared}>
          <img src="/images/clock-white.svg" alt="clock" />
          {isSinglePrepared ? <span>{text}</span> : text} remaining
        </RemainingMinutes>
      </>
    );
  };

  const displayGenerationMinutes = (campaign) => {
    const { generationDurationMs } = campaign;
    if (!generationDurationMs || generationDurationMs < 0) {
      return null;
    }

    const minutes = Math.round(generationDurationMs / 60000) || 1;
    const text = `${minutes} minute${minutes === 1 ? "" : "s"}`;
    return (
      <>
        {isSinglePrepared && <RemainingMinutesSep />}
        <RemainingMinutes
          $isGenerationMinutes={true}
          $isSingle={isSinglePrepared}
        >
          {!isSinglePrepared && (
            <img src="/images/clock-white.svg" alt="clock" />
          )}
          Generated in{isSinglePrepared ? <br /> : " "}under{" "}
          {isSinglePrepared ? <span>{text}</span> : text}
        </RemainingMinutes>
      </>
    );
  };

  const displayPrepInfo = (campaign, isPrepared) => {
    const isCreating = isCreatingCampaign(campaign);
    if (isCreating || isFailedCreation(campaign)) {
      return (
        !isSinglePrepared && (
          <RemainingMinutes>
            {isCreating ? "Creating..." : "Could not create"}
          </RemainingMinutes>
        )
      );
    }

    if (isPrepared) {
      return displayGenerationMinutes(campaign);
    }

    return displayRemainingMinutes(campaign);
  };

  return (
    <AccountProvider>
      {isShowWarning && (
        <SuggestionPopup
          imageSrc="/images/minimized-campaign-prep-icon.png"
          imageHeight={100}
          biggerHeight
          title="Don’t wait on Reach!"
          description="Feel free to continue your work and track the progress at the bottom right of your screen"
          confirmBtnText="Got it!"
          onConfirm={handleCloseWarning}
          removeCancel
          noOverlay
        />
      )}
      <Container>
        {!isExpanded ? (
          <CircleProgressBlock
            title="Expand"
            onClick={isLoading ? undefined : handleToggleExpand}
            $isLoading={isLoading}
          >
            <ReachLogoBlock $isAllPrepared={isAllPrepared}>
              <img
                src="/images/minimal-logo-white.png"
                alt="reach logo"
                width={isAllPrepared ? 24 : 17}
              />
            </ReachLogoBlock>
            {isAllPrepared ? (
              <CheckIcon src="/images/circle-checked-green.svg" />
            ) : (
              <CircleProgress $value={avgProgress} />
            )}
          </CircleProgressBlock>
        ) : (
          <Details
            onClick={handleMaximize}
            $isAllPrepared={isAllPrepared}
            $isSingle={isSinglePrepared}
            $isCreating={isSingleCampaign && isCreatingCampaign(campaigns[0])}
            $isFailed={isSingleCampaign && isFailedCreation(campaigns[0])}
            $isShowWarning={isShowWarning}
          >
            <CollapseBtn
              title="Collapse"
              onClick={handleToggleExpand}
              $isAllPrepared={isAllPrepared}
            >
              <FontAwesomeIcon icon={faMinus} />
            </CollapseBtn>
            <CircleProgressBlock
              $isExpanded={true}
              $isSingle={isSinglePrepared}
              $isAllPrepared={isAllPrepared}
              $isCreating={isSingleCampaign && isCreatingCampaign(campaigns[0])}
              $isFailed={isSingleCampaign && isFailedCreation(campaigns[0])}
            >
              <ReachLogoBlock $isAllPrepared={isAllPrepared} $isExpanded={true}>
                <img
                  src={`/images/minimal-logo-${
                    isAllPrepared ? "white" : "pink"
                  }.png`}
                  alt="reach logo"
                  width={isAllPrepared ? 24 : 31}
                />
              </ReachLogoBlock>
              {isAllPrepared ? (
                <CheckIcon src="/images/circle-checked-white.svg" />
              ) : (
                <CircleProgress $value={avgProgress} />
              )}
            </CircleProgressBlock>
            {!isAllPrepared && (
              <AvgProgress>
                <span>{avgProgress}</span>%
              </AvgProgress>
            )}
            {isSinglePrepared ? (
              <SingleCampaign
                $isAllPrepared={isAllPrepared}
                $isCreating={isCreatingCampaign(campaigns[0])}
                $isFailed={isFailedCreation(campaigns[0])}
              >
                <div className="sc-name">
                  <span>{campaigns[0].name}</span>{" "}
                  {isCreatingCampaign(campaigns[0])
                    ? "creating..."
                    : isFailedCreation(campaigns[0])
                    ? "could not be created"
                    : isAllPrepared
                    ? "ready to send"
                    : "generating"}
                </div>
                {displayPrepInfo(campaigns[0], isAllPrepared)}
              </SingleCampaign>
            ) : (
              <MultiCampaigns>
                {campaigns.map((campaign, idx) => {
                  const isPrepared = isCampaignPrepared(campaign);
                  return (
                    <MultiCampaign
                      key={idx}
                      onClick={(e) => handleMaximize(e, idx)}
                      $isCreating={isCreatingCampaign(campaign)}
                      $isFailed={isFailedCreation(campaign)}
                    >
                      <MultiCampaignInfo>
                        <MultiCampaignName title={campaign.name}>
                          {campaign.name}
                        </MultiCampaignName>
                        {displayPrepInfo(campaign, isPrepared)}
                      </MultiCampaignInfo>
                      <MultiCampaignProgress
                        $isPrepared={isPrepared}
                        $value={getCampaignProgress(campaign)}
                      >
                        {isPrepared && (
                          <img
                            src="/images/check-white.svg"
                            alt="check"
                            width="12"
                          />
                        )}
                      </MultiCampaignProgress>
                    </MultiCampaign>
                  );
                })}
              </MultiCampaigns>
            )}
          </Details>
        )}
      </Container>
    </AccountProvider>
  );
};

export default MinimizedCampaignPrep;

const ContBoxShadow = "0px 4px 20px 0px #00000033";

const Container = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;

const CircleProgressBlock = styled.div`
  position: relative;
  width: ${({ $isExpanded, $isAllPrepared }) =>
    $isExpanded && !$isAllPrepared ? "114px" : "64px"};
  height: ${({ $isExpanded, $isAllPrepared }) =>
    $isExpanded && !$isAllPrepared ? "114px" : "64px"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: ${({ $isLoading, $isCreating, $isFailed }) =>
    $isLoading || $isCreating ? "wait" : $isFailed ? "not-allowed" : "pointer"};
  ${({ $isExpanded, $isAllPrepared, $isSingle }) =>
    $isExpanded
      ? `margin-top: ${$isAllPrepared ? "36px" : "28px"};
         margin-bottom: ${$isAllPrepared ? ($isSingle ? 0 : "25px") : "13px"};}
        `
      : `box-shadow: ${ContBoxShadow};`};
`;

const ReachLogoBlock = styled.div`
  position: absolute;
  width: ${({ $isAllPrepared }) => ($isAllPrepared ? "100%" : "70%")};
  height: ${({ $isAllPrepared }) => ($isAllPrepared ? "100%" : "70%")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $isExpanded, $isAllPrepared }) =>
    $isExpanded && !$isAllPrepared ? "#f5f5f5" : "#471168"};
  border-radius: 50%;
`;

const CircleProgress = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 94%, transparent 100% 100%),
    conic-gradient(#f95959 ${({ $value }) => $value}%, #e8e8e8 0);
`;

const CheckIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
`;

const CollapseBtn = styled.div`
  position: absolute;
  top: 9px;
  right: 10px;
  width: 14px;
  height: 12px;
  font-size: 14px;
  color: ${({ $isAllPrepared }) => ($isAllPrepared ? "#fff" : "#000")};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms;
  &:hover {
    background: #e7e7e78c;
    box-shadow: 0 0 1px 2px #e7e7e78c;
    ${({ $isAllPrepared }) => ($isAllPrepared ? "color: #6c8a2c;" : "")};
  }
`;

const AvgProgress = styled.div`
  position: absolute;
  top: 126px;
  right: 10px;
  font-size: 17.31px;
  font-weight: 400;
  line-height: 22.97px;
  text-align: center;
  color: #471168;
  span {
    font-weight: 700;
  }
`;

const Details = styled.div`
  height: fit-content;
  width: 224px;
  border-radius: 6px;
  background-color: ${({ $isAllPrepared }) =>
    $isAllPrepared ? "#6c8a2c" : "#fff"};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  box-shadow: ${({ $isAllPrepared, $isShowWarning }) =>
      $isAllPrepared || $isShowWarning
        ? ""
        : "0 0 0 1px #d9d9d9, "}${ContBoxShadow};
  cursor: ${({ $isCreating, $isFailed }) =>
    $isCreating ? "wait" : $isFailed ? "not-allowed" : "pointer"};
`;

const RemainingMinutesSep = styled.div`
  margin: 11px auto 13px;
  padding-top: 3px;
  width: 26px;
  border-radius: 3px;
  background-color: #fff;
  opacity: 0.5;
`;

const RemainingMinutes = styled.div`
  ${({ $isGenerationMinutes, $isSingle }) =>
    $isGenerationMinutes && $isSingle
      ? ""
      : `
          display: flex;
          align-items: flex-start;
          justify-content: ${$isSingle ? "center" : "flex-start"};
          column-gap: ${$isSingle ? "5px" : "2px"};
        `};
  margin: 0 ${({ $isSingle }) => ($isSingle ? "30px" : "0")};
  font-size: ${({ $isSingle }) => ($isSingle ? "13px" : "9px")};
  font-weight: ${({ $isSingle }) => ($isSingle ? "400" : "300")};
  line-height: ${({ $isSingle }) => ($isSingle ? "15.23px" : "10.55px")};
  img {
    width: ${({ $isSingle }) => ($isSingle ? "13px" : "9px")};
  }
  ${({ $isSingle }) =>
    $isSingle
      ? `
          span {
            font-weight: 700;
            white-space: nowrap;
          }
        `
      : ""};
`;

const SingleCampaign = styled.div`
  padding-bottom: 26px;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ $isAllPrepared }) =>
    $isAllPrepared ? "#6c8a2c" : "#471168"};
  border-radius: 0 0 6px 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  span {
    font-weight: 700;
  }
  * {
    text-align: center;
  }
  .sc-name {
    margin: 20px auto 0;
    width: calc(100% - 48px);
    span {
      word-wrap: break-word;
    }
  }
  cursor: ${({ $isCreating, $isFailed }) =>
    $isCreating ? "wait" : $isFailed ? "not-allowed" : "pointer"};
`;

const MultiCampaigns = styled.div`
  flex: 1;
  width: 100%;
  max-height: 140px;
  border-radius: 0 0 6px 6px;
  background-color: #9a50c7;
  overflow-x: hidden;
  overflow-y: auto;
`;

const MultiCampaign = styled.div`
  padding: 10px 17px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 10px;
  color: #fff;
  &:nth-child(3n-2) {
    background-color: #471168;
  }
  &:nth-child(3n-1) {
    background-color: #7932a4;
  }
  &:nth-child(3n) {
    background-color: #9a50c7;
  }
  cursor: ${({ $isCreating, $isFailed }) =>
    $isCreating ? "wait" : $isFailed ? "not-allowed" : "pointer"};
`;

const MultiCampaignInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 3px;
  max-width: calc(100% - 35px - 18px);
`;

const MultiCampaignName = styled.div`
  max-width: 100%;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.72px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const MultiCampaignProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  ${({ $isPrepared, $value }) =>
    $isPrepared
      ? ""
      : `border: 1px solid #fff;
         background: conic-gradient(#fff ${$value}%, transparent 0);
        `}
`;
