import React, { useState } from "react";
import { styled } from "styled-components";
import ConfirmPopup from "../ConfirmPopup";

export default function DoItForMeButton({ onClick }) {
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState(false);

  return (
    <>
      {isConfirmPopupOpened && (
        <ConfirmPopup
          title="Create Email Integration"
          description="Please note that you will need administrative privileges to create the integration"
          onConfirm={() => {
            setIsConfirmPopupOpened(false);
            onClick();
          }}
          onCancel={() => setIsConfirmPopupOpened(false)}
          confirmLabel="Sounds good"
        />
      )}
      <Container onClick={() => setIsConfirmPopupOpened(true)}>
        Auto-configure
        <img src="/images/arrow-right.png" alt="arrow" />
      </Container>
    </>
  );
}

const Container = styled.button`
  height: 32px;
  border: 1px solid #999999;
  border-radius: 6px;
  background-color: transparent;
  color: #5a5a5a;
  width: 129px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 24px;
  cursor: pointer;
  img {
    width: 7px;
  }
`;
