import { styled } from "styled-components";

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ $width }) => $width || "fit-content"};
  margin: 0 0 22px;
`;

export const Label = styled.label`
  margin-bottom: ${({ $isBoolean }) => ($isBoolean ? "10px" : "2px")};
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  color: #000;
  font-weight: 600;
`;

export const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

export const CheckboxLabel = styled.div`
  font-size: 14px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#00000066")};
  font-weight: 600;
  margin-left: 10px;
`;

export const DateSelect = styled.div`
  margin: 0;
  width: 242px;
  > .react-datepicker-wrapper {
    width: 100%;
    > .react-datepicker__input-container {
      > input {
        margin: 0;
        width: 100%;
        height: 38px;
        padding: 10px 28px 10px 10px;
        border-radius: 4px;
        border: 1px solid #cdcdcd;
        box-shadow: 0px 0px 20px 0px #0000000d inset;
        background-color: white;
        color: black;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 19.2px;
        &::placeholder {
          font-size: 12px;
          font-weight: 400;
          font-style: normal;
          color: #00000054;
        }
      }
      .react-datepicker__close-icon {
        padding: 0 13px 0 0;
        &::after {
          font-size: 18px;
          background: transparent;
          color: #adadad;
        }
        &:hover {
          &::after {
            color: #888;
          }
        }
      }
    }
  }
  .react-datepicker-popper {
    z-index: 11;
    .react-datepicker__triangle {
      transform: translate(25px, 0) !important;
    }
    .react-datepicker__month-container {
      font-size: 12px;
      .react-datepicker__current-month {
        font-size: 12px;
      }
      .react-datepicker__day-name,
      .react-datepicker__day {
        margin: 3px;
        width: 21px;
        line-height: 21px;
      }
    }
    .react-datepicker__today-button {
      font-size: 12px;
      border-radius: 0 0 6px 6px;
    }
  }
`;

export const InputTextarea = styled.textarea`
  &,
  &:focus-visible {
    border: 1px solid #cdcdcd;
    outline: none;
    resize: vertical;
    min-height: 70px;
    height: 150px;
    padding: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
    color: #000000;
    box-shadow: 0px 0px 20px 0px #0000000d inset;
    border-radius: 4px;
    margin: 0;
    width: 100%;

    &::placeholder {
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      color: #00000054;
    }
  }
`;

export const SequenceTemplateField = styled.div`
  display: grid;
  grid-template-columns: 1fr 18px;
  column-gap: 12px;
  align-items: start;
  justify-content: start;
  width: 100%;
  img[alt="info"] {
    margin-top: 10px;
  }
`;
