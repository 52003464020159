import React, { useState } from "react";

import { useGetPlansData } from "../../components/Subscription/hooks";
import Loader from "../../components/Loader";
import UpdateBillingDetails from "../../components/Subscription/UpdateBillingDetails";
import { sendMessageToExtension } from "../../utils/postToExtension";
import { useNavigate } from "react-router-dom";
import { changeSubscriptionPlan } from "../../services/subscription";
import ToggleSwitch from "../../components/Subscription/ToggleSwitch";
import Plans from "../../components/Subscription/Plans";
import NoPlansAvailableMessage from "../../components/Subscription/NoPlansAvailableMessage";

import {
  Details,
  Container,
  DetailsDescription,
  DetailsHeader,
  Logo,
  SwitchContainer,
  PlansContainer,
  BillingDetails,
  Wrapper,
} from "./styles";
import {
  DEFAULT_SUBSCRIPTION_PLAN_PERIOD,
  SUBSCRIPTION_PLAN_PERIOD,
} from "../../utils/constants";

function Subscription() {
  const navigate = useNavigate();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(
    DEFAULT_SUBSCRIPTION_PLAN_PERIOD
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isBillingDetailsUpdate, setIsBillingDetailsUpdate] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const { plans, isLoading, errorMessage } = useGetPlansData();
  const isPlansAvailable = plans && plans.length > 0;

  if (isLoading || isSaving) {
    return (
      <>
        <Loader parentSize={true} />
      </>
    );
  }

  if (errorMessage) {
    sendMessageToExtension({
      message: "show-error-message",
      data: {
        message: errorMessage,
      },
    });

    navigate("/register");
  }

  const handleChangeSubscriptionPeriod = () => {
    setSubscriptionPeriod((prevState) =>
      prevState === SUBSCRIPTION_PLAN_PERIOD.MONTH
        ? SUBSCRIPTION_PLAN_PERIOD.YEAR
        : SUBSCRIPTION_PLAN_PERIOD.MONTH
    );
  };

  const handleSelectPlan = async (planId, hasPaymentMethod) => {
    const selPlan = plans.find((p) => p.id === planId);
    if (!selPlan) {
      return;
    }

    setSelectedPlan(planId);

    const isSelectedPlanFree = selPlan.monthlyPrice === 0;

    if (!isSelectedPlanFree && !hasPaymentMethod) {
      setIsBillingDetailsUpdate(true);
      return;
    }

    setIsSaving(true);

    const result = await changeSubscriptionPlan({
      newPlanId: planId,
      newPlanPeriod: subscriptionPeriod,
    });

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsSaving(false);
      return;
    }

    setIsSaving(false);

    navigate("/create-integrations");
  };

  const handleBillingDetailsUpdate = async () => {
    setIsBillingDetailsUpdate(false);

    if (selectedPlan) {
      await handleSelectPlan(selectedPlan, true);
    }
  };

  return (
    <Wrapper>
      <Container $isBillingDetailsUpdate={isBillingDetailsUpdate}>
        <Logo src="/images/logos/full-logo-white.png" alt="Logo" />
        {isBillingDetailsUpdate ? (
          <BillingDetails>
            <UpdateBillingDetails
              selectedPlanName={
                plans.find(({ id }) => selectedPlan === id)?.name || ""
              }
              onReturn={() => {
                setIsBillingDetailsUpdate(false);
                setSelectedPlan(undefined);
              }}
              onSuccess={handleBillingDetailsUpdate}
            ></UpdateBillingDetails>
          </BillingDetails>
        ) : (
          <>
            <Details>
              <DetailsHeader>Select Your Subscription Level</DetailsHeader>
              <DetailsDescription>
                Please select a subscription level. Afraid of commitment? We've
                got you! Sign up for our Free account and see what Reach can do
                for your business without paying a cent!
              </DetailsDescription>
              {isPlansAvailable && (
                <SwitchContainer>
                  <ToggleSwitch
                    leftLabel="Pay Monthly"
                    rightLabel="Pay Annually"
                    position={
                      subscriptionPeriod === SUBSCRIPTION_PLAN_PERIOD.MONTH
                        ? "left"
                        : "right"
                    }
                    onChange={handleChangeSubscriptionPeriod}
                  />
                </SwitchContainer>
              )}
            </Details>
            {isPlansAvailable ? (
              <PlansContainer>
                <Plans
                  plans={plans}
                  subscriptionPeriod={subscriptionPeriod}
                  onSelect={handleSelectPlan}
                />
              </PlansContainer>
            ) : (
              <NoPlansAvailableMessage />
            )}
          </>
        )}
      </Container>
    </Wrapper>
  );
}

export default Subscription;
